import { Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AppContext } from "providers/AppContextProvider";
import A from "components/A";
import cl from "styles/components/MainContainer/NavbarTop.module.scss";

const NavbarTop = () => {
	const navigate = useNavigate();
  const { alert, menuCondition, crumbs } = useContext(AppContext);

	return (
		<nav className={cl.navbarTop}>
			<div className={cl.leftSide}>
				<div className={cl.brand} onClick={() => navigate("/")}>
					<img src={`${process.env.REACT_APP_SERVER_URL}/img/agro_logo.svg`} alt="logo" title="АГРОПЛАН"/>
					<span>АГРОПЛАН</span>
				</div>
				
				<div className={cl.crumbs}>
					{crumbs.map((crumb, index) =>
						<Fragment key={crumb.name}>
							<span className="material-icons">navigate_next</span>
							{index === crumbs.length - 1
								? crumb.name
								: <A href={crumb.url} alert={alert} condition={menuCondition}>{crumb.name}</A>
							}
						</Fragment>
					)}
				</div>
			</div>
		</nav>
	);
};

export default NavbarTop;
