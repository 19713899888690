import {useRef} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from 'react-redux';
import {MapContainer, TileLayer, Polygon, Tooltip, ZoomControl} from "react-leaflet";
import html2canvas from "html2canvas";

import {postMapScreenShot} from 'redux/slices/mapSlice';

import MapPanel from "./MapPanel";
import "leaflet/dist/leaflet.css";

const Map = ({map}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// Область для скриншота
	const toScreenShot = useRef();
	
	// Метод для отправки скриншота карты
  const createAndPostScreenShot = async () => {
		if (toScreenShot.current) {
			const canvas = await html2canvas(toScreenShot.current, {useCORS: true});
			const image = await canvas.toDataURL("image/png", 1.0);

			await dispatch(postMapScreenShot({
				id: map.id,
				body: {thumb: image}
			}));
		}
  };
	const deferredScreenShot = () => {
		setTimeout(() => {
			createAndPostScreenShot();
		}, 5000);
	};
	
	// Перенаправление на страницу поля
	const fieldMove = (id) => {
		navigate("/map/" + map.id + "/fields/" + id);
	}
	
	return (
		<>
			<MapPanel map={map} fields={map.fields !== null ? map.fields : []}/>
			
			<div ref={toScreenShot}>
				<MapContainer
				  center={[map.center_x, map.center_y]}
					zoom={map.zoom}
					scrollWheelZoom={true}
					doubleClickZoom={true}
					preferCanvas={true}
					zoomControl={false}
					attributionControl={false}
					whenReady={deferredScreenShot}
				>
					<TileLayer url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
								  subdomains={["mt0", "mt1", "mt2", "mt3"]}/>
					<ZoomControl position="topright"/>
					
					{map.fields.length && map.fields.map((field) => {
						return field.coords.length && (
							<Polygon
								key={field.name}
								pathOptions={{color: "black"}}
								positions={field.coords}
								eventHandlers={{click: () => fieldMove(field.id)}}
							>
								<Tooltip direction="center" opacity={1} permanent>
									{field.name}
								</Tooltip>
							</Polygon>
						);
					})}
				</MapContainer>
			</div>
		</>
	);
};

export default Map;