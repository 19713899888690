import {useContext, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {fetchSuppliesAsyncAction} from 'redux/slices/supplies/supplies-api-actions';
import {getSupplies} from 'redux/slices/supplies/selectors';

import SuppliesTable from 'components/archive/SuppliesTable';

import {AppContext} from 'providers/AppContextProvider';
import {getUser} from 'helpers/storage';

const Index = () => {
	const dispatch = useDispatch();
	const {alert, setCrumbs} = useContext(AppContext);
	const location = useLocation();

	const LOCATION_REG_EXP = /archive/;
	const pathName = location.pathname;
	const isInArchive = LOCATION_REG_EXP.test(pathName);

	const userLevel = getUser().accessLevel;

	const supplies = useSelector(getSupplies);
	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: 'Архивы', url: '/archive'},
			{name: 'Поступления', url: ''}
		]);
	}, []);

	// Запрос данных с сервера для построения списка Поступлений
	useEffect(() => {
		dispatch(fetchSuppliesAsyncAction(isInArchive));
	}, []);
	
	return (
		<>
			<div className="cabinetMain">
				<SuppliesTable Supplies={supplies} userLevel={userLevel} alert={alert}/>
			</div>
		</>
	);
};

export default Index;
