import {createSlice} from '@reduxjs/toolkit';

import {fetchRealizationAsyncAction} from './realizations-api-actions';

const initialState = {
  realization: []
};

export const realizations = createSlice({
  name: 'realizations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRealizationAsyncAction.fulfilled, (state, action) => {
        state.realization = action.payload.result;
      });
  }
});
