import {createAsyncThunk} from '@reduxjs/toolkit';

import {ApiRoute} from 'constants/endpoints';

export const fetchSuppliesAsyncAction = createAsyncThunk(
  'supplies/fetchSupplies',
  async (isInArchive, {extra: api}) => {
    const queryString = isInArchive ? '?done=1' : '';
    const {data} = await api.get(`${ApiRoute.Business.SUPPLIES}${queryString}`);

    return data;
  }
);

export const fetchSupplyDetailsAsyncAction = createAsyncThunk(
  'fetchSupplyDetails',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Business.SUPPLY}/${id}`);

    return data;
  }
);
