import cl from "styles/pages/[fieldId].module.scss";
import {useEffect, useState} from "react";
import Input from "components/input/InputComponent";
import CustomSearchSelect from "components/custom-search-select/custom-search-select";

const FormSupplies = ({
	documentNumber,
	documentDate,
	setDocumentDate,
	lines,
	onChangePos,
	scrollPlus,
	provided,
	storage,
	setStorage,
	storages,
	employee,
	setEmployee,
	addressList,
	setAllAddress,
	sortFn,
	sortName
}) => {
	// Классы таблиц
	const tableClasses = [cl.mainTable, cl.top0, "table", "table-responsive"];
	
	// Массив идентификаторов поступлений
	const [suppliesId, setSuppliesId] = useState([]);
	
	// Сбор идентификаторов поступлений
	useEffect(() => {
		const sups = Object.assign([], suppliesId);
		sups.length = 0;
		lines.map(ln => {
			sups.push(ln.supply_id);
		});
		setSuppliesId(sups);
	}, [lines]);
	
	return (
		<table className={tableClasses.join(" ")}>
			<thead className={cl.top0 + " theadBordered thead-dark"}>
				<tr>
					<th colSpan="2" className={cl.borderNone}>
						Поступление № {documentNumber}
					</th>
					<th className={cl.borderNone + " " + cl.dateInput}>
						от&nbsp;
						{provided === 0
							? <Input type="text" name="documentDate" autocomplete="off" mask="99.99.9999 99:99"
										placeholder="Дата" value={documentDate}
										setValue={(name, value) => setDocumentDate(value)}/>
							: documentDate
						}
					</th>
					<th>
						{
							lines[0].author && (
								<>
									Автор:&nbsp;
									{lines[0].author}
									<br/>
								</>
							)
						}
						Бригадир:&nbsp;
						<Input
							type="text"
							name="employee"
							value={employee}
							setValue={setEmployee}
							placeholder="Бригадир"
							title={employee}
						/>
						{/* {
							lines[0].provided
								? (
									<span>{employee}</span>
								)
								: (
									<Input
										type="text"
										name="employee"
										value={employee}
										setValue={setEmployee}
										placeholder="Бригадир"
										title={employee}
									/>
								)
						} */}
					</th>
					<th>
						{
							provided === 0
								? (
									<CustomSearchSelect
										inputName='storage'
										defaultValue={storage}
										options={storages}
										onChange={(value) => setStorage('storage', value)}
										placeholder='Склад'
									/>
								)
								: storage
						}
					</th>
					<th>
						{
							provided === 0 && (
								<CustomSearchSelect
									inputName='allAddress'
									defaultValue={''}
									options={storage === "" ? [] : addressList}
									onChange={(value) => setAllAddress('allAddress', value)}
									placeholder='Адрес для всех'
								/>
							)
						}
					</th>
				</tr>
				<tr>
					<th>№</th>
					<th className={sortName === "name" ? "sort sorted" : "sort"}
						 onClick={() => sortFn("name")}>
						<span>Название</span>
					</th>
					<th className={sortName === "article" ? "sort sorted" : "sort"}
						 onClick={() => sortFn("article")}>
						<span>Артикул</span>
					</th>
					<th className={sortName === "b_group" ? "sort sorted" : "sort"}
						 onClick={() => sortFn("b_group")}>
						Группа
					</th>
					<th>Поступило:</th>
					<th>Адрес</th>
				</tr>
			</thead>
			<tbody>
				{lines.map((line, index) =>
					<tr key={index.toString()}>
						<td>{index + 1}</td>
						<td className={cl.name}>
							{line.name}
						</td>
						<td>
							{line.article}
						</td>
						<td>{line.b_group}</td>
						<td className={cl.right}>
							{new Intl.NumberFormat("ru-RU").format(line.userPositions[0].amount)}
						</td>
						<td>
							{
								provided === 0
									? (
										<CustomSearchSelect
											inputName='address'
											defaultValue={line.userPositions[0].address}
											options={storage === "" ? [] : addressList}
											onChange={(value) => onChangePos({index: index, ind: 0}, 'address', value)}
											placeholder='Адрес'
										/>
									)
									: line.userPositions[0].address
							}
						</td>
					</tr>
				)}
				<tr style={{height: "300px"}}/>
				<tr ref={scrollPlus}/>
			</tbody>
		</table>
	);
};

export default FormSupplies;