import React, {
  useRef,
  useEffect,
  useState,
  useContext
} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import html2canvas from 'html2canvas';

import Table from 'components/table/table';
import Preloader from 'components/preloader/SpinPlaceholderComponent';
import FilterInventory from 'components/FilterInventory';
import CommentModal from 'components/comment-modal/comment-modal';
import CheckValuesModal from 'components/check-values-modal/check-values-modal';
import FloatPanel from 'components/float-panel/float-panel';
import Pagination from 'components/pagination/pagination';

import {
  fetchInventory,
  fetchAddresses,
  postInventoryScreenShot,
  setInventoryDataAction
} from 'redux/slices/inventorySlice';
import {fetchGroups} from 'redux/slices/business/businessSlice';
import {fetchGoodsAsyncAction} from 'redux/slices/good/good-api-actions';

import {AppContext} from 'providers/AppContextProvider';

import style from './style.module.scss';

const InventoryEdit = () => {
  const dispatch = useDispatch();

  const {id} = useParams();
  const {pathname} = useLocation();
  const isInArchive = (/archive/).test(pathname);

  const {setCrumbs} = useContext(AppContext);

  const inventory = useSelector((state) => state.inventory).inventory;
  const inventoryItems = inventory.data;
  const addresses = useSelector((state) => state.inventory).addresses.data;
  const goods = useSelector((state) => state.good).goods;
  const groups = useSelector((state) => state.business).groups.data;

  const toScreenShot = useRef();

  // Пагинация
  const [paginationData, setPaginationData] = useState({
    activePage: 1,
    itemsPerPage: 15
  });
  const pagesCount = Math.ceil(inventoryItems.length / paginationData.itemsPerPage);
  const firstItemIndex = (paginationData.activePage - 1) * paginationData.itemsPerPage;
  const lastItemIndex = (paginationData.activePage - 1) * paginationData.itemsPerPage + paginationData.itemsPerPage;
  const displayedItems = inventoryItems.slice(firstItemIndex, lastItemIndex);

  // Активность прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

  // Поле сортировки и направление
  const [sorted, setSorted] = useState("name");
  const [sortDir, setSortDir] = useState(true);

  // Состояния фильтра
  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const [dataBackup, setDataBackup] = useState([]);
  const [isFilterActive, setIsFilterActive] = useState(false);

  const [modalIsActive, setModalIsActive] = useState(false);
  const [checkValuesModalActive, setCheckValuesModalActive] = useState(false);
  const [checkValuesModalData, setCheckValuesModalData] = useState([]);

  // Метод для отправки скриншота (передаётся во FloatPanel и выполняется в момент сохранения данных в стейт)
  const createAndPostScreenShot = async () => {
    const canvas = await html2canvas(toScreenShot.current, {useCORS: true});
    const image = await canvas.toDataURL("image/png", 1.0);

    await dispatch(postInventoryScreenShot({
      id,
      body: {
        thumb: image
      }
    }));
  };

  // Функция сортировки растений
  const sortPlants = (name) => {
    setSortDir(!sortDir);

    const pl = structuredClone(inventoryItems);

    pl.sort((a, b) => {
      if (a[name] === b[name]) return 0;
      else if (!sortDir === true) return a[name] > b[name] ? 1 : -1;
      else return a[name] < b[name] ? 1 : -1;
    });

    dispatch(setInventoryDataAction(pl));
    setSorted(name);
  };

  // Обработчик селекта для выбора количества отображаемых строк на странице
  const handleItemsPerPageSelectChange = (evt) => {
    const value = Number(evt.currentTarget.value);
    const newPagesCount = Math.ceil(inventoryItems.length / value);

    if (paginationData.activePage > newPagesCount) {
      setPaginationData({
        activePage: newPagesCount,
        itemsPerPage: value
      });
    } else {
      setPaginationData((prevState) => ({
        ...prevState,
        itemsPerPage: value
      }));
    }
  };

  const handlePaginationButtonClick = (value) => {
    setPaginationData((prevState) => ({
      ...prevState,
      activePage: value
    }));
  };

  // Запрос данных с сервера
  useEffect(() => {
    dispatch(fetchInventory(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (inventory.provided === 0) {
      if (!addresses.length) {
        dispatch(fetchAddresses());
      }
      if (!groups.length) {
        dispatch(fetchGroups());
      }
      if (!goods.length) {
        dispatch(fetchGoodsAsyncAction());
      }
    }
  }, [inventory.provided]);

  // Запись хлебных крошек
  useEffect(() => {
    if (inventoryItems.length) {
      const crumbsSubString = inventoryItems[0].is_copy > 0 ? ' после проведения в Системе' : ' до проведения в Системе';
      const crumbsString = `№ ${inventoryItems[0].document_number} ${inventoryItems[0].provided ? crumbsSubString : ''}`;
      const crumbs = isInArchive ? [
        {name: "Архивы", url: "/archive"},
        {name: "Инвентаризация", url: isInArchive ? "/archive/inventory" : "/inventory"},
        {
          name: crumbsString,
          url: ""
        }
      ] : [
        {name: "Инвентаризация", url: isInArchive ? "/archive/inventory" : "/inventory"},
        {
          name: crumbsString,
          url: ""
        }
      ];

      setCrumbs(crumbs);
    }
  }, [inventoryItems]);

  return (
    <>
      <title>Редактирование инвентаризации</title>

      <Preloader isActive={isPreloaderActive}/>

      <FloatPanel
        setIsPreloaderActive={setIsPreloaderActive}
        setModalIsActive={setModalIsActive}
        setCheckValuesModalActive={setCheckValuesModalActive}
        setCheckValuesModalData={setCheckValuesModalData}
        setIsFilterOpened={setIsFilterOpened}
        createAndPostScreenShot={createAndPostScreenShot}
      />

      <div className="stickyContainer" ref={toScreenShot}>
        <div className={style.wrapper}>
          <Table
            paginationData={paginationData}
            setPaginationData={setPaginationData}
            displayedItems={displayedItems}
            firstItemIndex={firstItemIndex}
            sortPlants={sortPlants}
          />
          {
            pagesCount > 1 && (
              <select
                className={style.select}
                value={paginationData.itemsPerPage}
                onChange={handleItemsPerPageSelectChange}
              >
                <option value={15}>15</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            )
          }
          <Pagination
            pagesCount={pagesCount}
            activePage={paginationData.activePage}
            handleClick={handlePaginationButtonClick}
          />
        </div>
        <FilterInventory
          isOpen={isFilterOpened}
          data={inventoryItems}
          setData={(filteredItems) => dispatch(setInventoryDataAction(filteredItems))}
          currentSort={sorted}
          setIsFilterActive={setIsFilterActive}
          sortFn={sortPlants}
          onlyOne="name"
          dataBackup={dataBackup}
          setDataBackup={setDataBackup}
          fieldList={addresses}
        />
      </div>

      <CommentModal
        modalIsActive={modalIsActive}
        setModalIsActive={setModalIsActive}
      />
      <CheckValuesModal
        data={checkValuesModalData}
        isActive={checkValuesModalActive}
        setIsActive={setCheckValuesModalActive}
      />
    </>
  );
};

export default InventoryEdit;
