import {useState, useEffect, useContext} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import Indicator from "components/Indicator";
import ButtonComponent from "components/ButtonComponent";
import Preloader from "components/preloader/SpinPlaceholderComponent";

import {fetchInventories, setEmployeeAction} from "redux/slices/inventorySlice";
import {syncGoodsAsyncAction} from "redux/slices/good/good-api-actions";

import {AppContext} from "providers/AppContextProvider";
import {loadingStatus} from "helpers/fetcher";
import {getUser} from "helpers/storage";
import {getDateTimeStringFromDBValue} from 'helpers/utils';

import {AppRoute} from "constants/routes";

import cl from "styles/components/cabinet/UsersTable.module.scss";

const Inventory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
	const {setCrumbs} = useContext(AppContext);

  const {inventories} = useSelector((state) => state.inventory);
	const sortedInventories = structuredClone(inventories.data).sort((currentInventory, nextInventory) => {
		const currentItemDate = new Date(getDateTimeStringFromDBValue(currentInventory.before.date_current));
		const nextItemDate = new Date(getDateTimeStringFromDBValue(nextInventory.before.date_current));

		const currentItemNumber = Number(currentInventory.before.document_number.match(/\d+/)[0]);
		const nextItemNumber = Number(nextInventory.before.document_number.match(/\d+/)[0]);

		if (nextItemDate.getTime() === currentItemDate.getTime()) {
			if (nextItemNumber === currentItemNumber) {
				const currentItemCopyNumber = currentInventory.before.document_number.split(' ')[2] ? Number(currentInventory.before.document_number.split(' ')[2]) : 0;
				const nextItemCopyNumber = nextInventory.before.document_number.split(' ')[2] ? Number(nextInventory.before.document_number.split(' ')[2]) : 0;

				return nextItemCopyNumber - currentItemCopyNumber;
			} else {
				return nextItemNumber - currentItemNumber;
			}
		} else {
			return nextItemDate - currentItemDate;
		}
	});

	// Активность прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

	// Запрос списка инвентаризаций
	const requestForInventories = async () => {
		setIsPreloaderActive(true);
		await dispatch(fetchInventories());
		setIsPreloaderActive(false);
	};

	useEffect(() => {
		if (!inventories.data.length) {
			requestForInventories();
		}
	}, []);

  // Запись хлебных крошек
  useEffect(() => {
    setCrumbs([{name: "Инвентаризация", url: "/inventory"}]);
  }, []);

	// Очистка значения имени бригадира
	useEffect(() => {
		dispatch(setEmployeeAction(''));
	}, []);

	// Запрос синхронизации товаров в БД из БизнесРУ
  // useEffect(() => {
  //   dispatch(syncGoodsAsyncAction());
  // }, []);
  
  return (
    <>
			<Preloader isActive={isPreloaderActive} />

      <title>Инвентаризация</title>
			
      {inventories.status === loadingStatus.LOADING && <div>Loading...</div>}
			<div style={{padding: "16px"}}>
				&nbsp;
				<Indicator type="danger"/> - Инвентаризация создана &nbsp;
				<Indicator type="warning"/> - Инвентаризация редактируется &nbsp;
				<Indicator type="success"/> - До проведения в Системе &nbsp;
				<Indicator type="blued"/> - После проведения в Системе
			</div>
			
			<table className={cl.usersTable}>
				<thead>
					<tr>
						<th colSpan="2">
							{getUser().accessLevel > 1 &&
								<ButtonComponent type="accent" onClick={() => navigate(AppRoute.Inventory.ADD)}>
									Новая инвентаризация
								</ButtonComponent>
							}
						</th>
						<th/>
						<th>
							<ButtonComponent type="white" onClick={() => window.open(AppRoute.Archive.INVENTORY)}>
								Архив
							</ButtonComponent>
						</th>
						<th colSpan="2">
							<ButtonComponent type="white" onClick={() => window.open(AppRoute.FAQ.INVENTORY)}>
								Инструкция
							</ButtonComponent>
						</th>
					</tr>
					<tr>
						<th>№</th>
						<th>Склад</th>
						<th>Дата инвентаризации</th>
						<th>Дата в Системе</th>
						<th>Последнее сохранение</th>
						<th/>
						<th/>
					</tr>
				</thead>
				<tbody>
					{sortedInventories.map((inv, index) =>
						<tr key={index.toString()}>
							{/* Номер документа */}
							<td>
								<span>{inv.before.document_number}</span>
								{inv.after &&
									<><br/><span>{inv.after.document_number}</span></>
								}
							</td>

							<td>
								<span>{inv.before.storage}</span>
							</td>

							{/* Дата инвентаризации */}
							<td>
								<span>{inv.before.date}</span>
								{inv.after &&
									<><br/><span>{inv.after.date}</span></>
								}
							</td>

							{/* Дата в Системе */}
							<td>
								<span>{inv.before.date_current}</span>
								{inv.after &&
									<><br/><span>{inv.after.date_current}</span></>
								}
							</td>

							{/* Последнее сохранение */}
							<td>
								<span>{inv.before.last_edit}</span>
								{inv.after &&
									<><br/><span>{inv.after.last_edit}</span></>
								}
							</td>

							{/* Кнопка просмотра детальной информации */}
							<td>
								<span className={cl.icon + " material-icons"} title="Посмотреть"
                  onClick={() => {
                    navigate(`/inventory/edit/${inv.before.id}`);
                    if(inv.after) window.open(`/inventory/edit/${inv.after.id}`, "_blank");
                  }}
                >
									visibility
								</span>
							</td>

							{/* Индикаторы */}
							<td>
								<Indicator
									type={inv.before.provided === 1 ? "success" : inv.before.saved === 1 ? "warning" : "danger"}/>
								{inv.after &&
									<><br/><Indicator type="blued"/></>
								}
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</>
  );
};

export default Inventory;
