import {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import clsx from 'clsx';

import {resetDocumentAction} from 'redux/slices/documents/documents';

import {getUser} from 'helpers/storage';
import {AppContext} from 'providers/AppContextProvider';

import {AppRoute} from 'constants/routes';
import {
  NEW_POSTING_BUTTON_CAPTION,
  POSTINGS_ARCHIVE_BUTTON_CAPTION,
  POSTING_USER_MANUAL_BUTTON_CAPTION
} from './constants';

import styles from './styles.module.scss';

const PostingsListButtonsBlock = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLevel = getUser().accessLevel;

  const {alert} = useContext(AppContext);

  const handleCreateNewPostingButtonClick = () => {
    // Проверить, есть ли в localStorage данные с ключом 'newPostingDocument'
    const incompletedDocumentAlreadyExists = !!localStorage.getItem('newPostingDocument');

    // Если есть, то предложить (задать вопрос) пользователю, хочет ли он его продолжить
    if (incompletedDocumentAlreadyExists) {
      alert('Ранее вы уже создали, но не сохранили новый документ оприходования. Продолжить работу с ним?', 'default', 0, [
        {
          text: 'Да',
          handler: () => {
            // Очистка стейта
            dispatch(resetDocumentAction([]));

            navigate(AppRoute.Document.POSTINGS_NEW);
            alert("", "default", 1);
          }
        },
        {
          text: 'Нет',
          handler: () => {
            // Очистка localStorage
            localStorage.removeItem('newPostingDocument');

            // Очистка стейта
            dispatch(resetDocumentAction());

            navigate(AppRoute.Document.POSTINGS_NEW);
            alert("", "default", 1);
          }
        }
      ])
    } else {
      // Очистка стейта
      dispatch(resetDocumentAction());
      navigate(AppRoute.Document.POSTINGS_NEW);
    }
  };

  return (
    <div className={styles.wrapper}>
      {/* Кнопка добавления нового документа списания */}
      {
        userLevel > 1 && (
          <div>
            <button
              className={clsx(styles.button, styles.new)}
              onClick={handleCreateNewPostingButtonClick}
            >
              <span>
                {NEW_POSTING_BUTTON_CAPTION}
              </span>
            </button>
          </div>
        )
      }

      <div className={styles['inner-wrapper']}>
        {/* Кнопка перехода в архив списаний */}
        <div>
          <button
            className={clsx(styles.button, styles.regular)}
            onClick={() => window.open(AppRoute.Archive.POSTINGS)}
          >
            <span>
              {POSTINGS_ARCHIVE_BUTTON_CAPTION}
            </span>
          </button>
        </div>
        
        {/* Кнопка открытия раздела о списаниях в руководстве пользователя */}
        <div>
          <button
            className={clsx(styles.button, styles.regular)}
            onClick={() => window.open(AppRoute.UserManual.POSTING)}
          >
            <span>
              {POSTING_USER_MANUAL_BUTTON_CAPTION}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostingsListButtonsBlock;
