import styles from './styles.module.scss';

const PopUp = ({
  children,
  setPopUpVisible = false
}) => {
  const handleCloseButtonClick = () => {
    setPopUpVisible(false);
  };

  return (
    <div className={styles.popup}>
      <div className={styles.anchor}></div>
      <div className={styles.message}>
        {children}
      </div>
      <div className={styles.controls}>
        <button
          onClick={handleCloseButtonClick}
          className={styles['close-button']}
        >
          <span className='material-icons'>
            close
          </span>
        </button>
      </div>
    </div>
  );
};

export default PopUp;
