import {createAsyncThunk} from "@reduxjs/toolkit";

import {ApiRoute} from "constants/endpoints";

export const fetchRealizationAsyncAction = createAsyncThunk(
  'realizations/fetchRealization',
  async (id, {extra: api}) => {
    const {data} = await api.get(`${ApiRoute.Realizations.GET_REALIZATION}/${id}`);

    return data;
  }
);
