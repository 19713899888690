import ArrowButton from 'components/arrow-button/arrow-button';
import PaginationButton from 'components/pagination-button/pagination-button';

import style from './style.module.scss';

const Pagination = ({
  pagesCount,
  activePage,
  handleClick,
}) => {
  if (pagesCount <= 1) {
    return null;
  }

  if (pagesCount > 1 && pagesCount <= 7) {
    return (
      <div className={style.wrapper}>
        <ul className={style.list}>
          {activePage > 1 && (
            <ArrowButton direction="left" handleClick={() => handleClick(activePage - 1)} />
          )}
          {Array(pagesCount)
            .fill(null)
            .map((_item, index) => index + 1)
            .map((page) => (
              <PaginationButton
                key={page}
                pageNumber={page}
                activePage={activePage}
                handleClick={() => handleClick(page)}
              />
            ))}
          {activePage < pagesCount && (
            <ArrowButton direction="right" handleClick={() => handleClick(activePage + 1)} />
          )}
        </ul>
      </div>
    );
  }

  return (
    <div className={style.wrapper}>
      <ul className={style.list}>
        {activePage > 1 && (
          <ArrowButton direction="left" handleClick={() => handleClick(activePage - 1)} />
        )}
        {activePage < 3 && (
          <>
            {Array(pagesCount)
              .fill(null)
              .map((_item, index) => index + 1)
              .slice(0, 3)
              .map((page) => (
                <PaginationButton
                  key={page}
                  pageNumber={page}
                  activePage={activePage}
                  handleClick={() => handleClick(page)}
                />
              ))}
            <li className={style.boundary}>...</li>
            <PaginationButton
              pageNumber={pagesCount}
              activePage={activePage}
              handleClick={() => handleClick(pagesCount)}
            />
          </>
        )}
        {(activePage === 3 || activePage === 4) && (
          <>
            {Array(pagesCount)
              .fill(null)
              .map((_item, index) => index + 1)
              .slice(0, activePage + 1)
              .map((page) => (
                <PaginationButton
                  key={page}
                  pageNumber={page}
                  activePage={activePage}
                  handleClick={() => handleClick(page)}
                />
              ))}
            <li className={style.boundary}>...</li>
            <PaginationButton
              pageNumber={pagesCount}
              activePage={activePage}
              handleClick={() => handleClick(pagesCount)}
            />
          </>
        )}
        {activePage > 4 && activePage <= pagesCount - 4 && (
          <>
            <PaginationButton
              pageNumber={1}
              activePage={activePage}
              handleClick={() => handleClick(1)}
            />
            <li className={style.boundary}>...</li>
            <PaginationButton
              pageNumber={activePage - 1}
              activePage={activePage}
              handleClick={() => handleClick(activePage - 1)}
            />
            <PaginationButton
              pageNumber={activePage}
              activePage={activePage}
              handleClick={() => handleClick(activePage)}
            />
            <PaginationButton
              pageNumber={activePage + 1}
              activePage={activePage}
              handleClick={() => handleClick(activePage + 1)}
            />
            <li className={style.boundary}>...</li>
            <PaginationButton
              pageNumber={pagesCount}
              activePage={activePage}
              handleClick={() => handleClick(pagesCount)}
            />
          </>
        )}
        {activePage >= pagesCount - 3 && activePage <= pagesCount - 1 && (
          <>
            <PaginationButton
              pageNumber={1}
              activePage={activePage}
              handleClick={() => handleClick(1)}
            />
            <li className={style.boundary}>...</li>
            <PaginationButton
              pageNumber={activePage - 1}
              activePage={activePage}
              handleClick={() => handleClick(activePage - 1)}
            />
            {Array(pagesCount)
              .fill(null)
              .map((_item, index) => index + 1)
              .slice(activePage - 1)
              .map((page) => (
                <PaginationButton
                  key={page}
                  pageNumber={page}
                  activePage={activePage}
                  handleClick={() => handleClick(page)}
                />
              ))}
          </>
        )}
        {activePage === pagesCount && (
          <>
            <PaginationButton
              pageNumber={1}
              activePage={activePage}
              handleClick={() => handleClick(1)}
            />
            <li className={style.boundary}>...</li>
            <PaginationButton
              pageNumber={activePage - 2}
              activePage={activePage}
              handleClick={() => handleClick(activePage - 2)}
            />
            <PaginationButton
              pageNumber={activePage - 1}
              activePage={activePage}
              handleClick={() => handleClick(activePage - 1)}
            />
            {Array(pagesCount)
              .fill(null)
              .map((_item, index) => index + 1)
              .slice(activePage - 1)
              .map((page) => (
                <PaginationButton
                  key={page}
                  pageNumber={page}
                  activePage={activePage}
                  handleClick={() => handleClick(page)}
                />
              ))}
          </>
        )}
        {activePage < pagesCount && (
          <ArrowButton direction="right" handleClick={() => handleClick(activePage + 1)} />
        )}
      </ul>
    </div>
  );
};

export default Pagination;
