import React, {useContext} from "react";
import {useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";

import Modal from "components/Modal";

import styles from './styles.module.scss';

import {
  setCommentAction,
  setCommentFilesAction,
  setCommentAsyncAction,
  setCommentFilesAsyncAction,
  setInventoryDataAction
} from "redux/slices/inventorySlice";

import {AppContext} from "providers/AppContextProvider";
import {getUser} from "helpers/storage";

const CommentModal = ({
  modalIsActive,
  setModalIsActive
}) => {
  const dispatch = useDispatch();

  const location = useLocation();
  const {id} = useParams();

  const ARCHIVE_LOCATION_REG_EXP = /archive/;
  const pathName = location.pathname;
  const isInArchive = ARCHIVE_LOCATION_REG_EXP.test(pathName);

  // Проверка уровня пользователя
  const canUserUpdate = getUser().accessLevel > 1;

  const {alert} = useContext(AppContext);

  const inventory = useSelector((state) => state.inventory).inventory;
  const isInventoryProvided = inventory.provided;
  const copyId = inventory.isCopy;
  const inventoryItems = inventory.data;
  const inventoryComment = inventory.comment;
  const inventoryCommentFiles = inventory.commentFiles;

  // Добавление файла к комментарию в модальном окне
  const handleFileInputChange = (evt) => {
    const showSuccessMsg = (filesCount) => {
      const SUCCESS_CANCEL_INVENTORY_MSG = `${filesCount > 1 ? 'Файлы загружены' : 'Файл загружен'}`;
      const ALERT_TYPE = "success";
      const ALERT_DURATION = 2000;

      alert(SUCCESS_CANCEL_INVENTORY_MSG, ALERT_TYPE, ALERT_DURATION);
    };
    const showErrorMsg = () => {
      alert("Ошибка добавления файла", "danger");
    };

    const formData = new FormData();
    const inputFiles = Object.values(evt.currentTarget.files);
    inputFiles.forEach((file, index) => {
      formData.append("file_" + index, file);
    });

    dispatch(setCommentFilesAsyncAction({
      id: copyId !== 0 ? copyId : id,
      formData
    })).then((res) => {
      if (!res.error) {
        const responseFiles = res.payload.files;
        const filesCount = responseFiles.length;
        showSuccessMsg(filesCount);
      } else {
        showErrorMsg();
      }
    });
  };

  // Удаление файла
  const removeFile = (url) => {
    alert("Удалить файл?", "danger", 10000, [
      {
        text: "Да",
        handler: function () {
          axios
            .post("inventory/removeCommentFile", { filename: url })
            .then(() => {
              alert("Файл удален", "success", 2000);
              const copy = structuredClone(inventoryCommentFiles).filter((file) => file.url !== url);
              dispatch(setCommentFilesAction(copy));
            });
        },
      },
      {
        text: "Нет",
        handler: function () {
          alert("", "default", 1);
        },
      },
    ]);
  };

  // Обработчик ввода текста
  const handleModalTextAreaChange= (evt) => {
    const textAreaValue = evt.currentTarget.value;
    dispatch(setCommentAction(textAreaValue));
  };

  // Обработчик кнопки закрытия модального окна
  const handleModalClose = (isActive) => {
    const updatedItems = inventoryItems.map((item) => ({
      ...item,
      comment: inventoryComment
    }));

    dispatch(setInventoryDataAction(updatedItems));
    dispatch(setCommentAsyncAction({
      id,
      comment: inventoryComment
    }));

    setModalIsActive(isActive);
  };

  return (
    <Modal isActive={modalIsActive} setIsActive={handleModalClose}>
      {
        !isInArchive && canUserUpdate ? (
          <>
            <textarea
              value={inventoryComment}
              onChange={handleModalTextAreaChange}
              placeholder="Примечание"
            />
            <input
              type="file"
              name="files[]"
              onChange={handleFileInputChange}
              multiple
            />
          </>
        ) : (
          <div className={styles.commentFrame}>
            {inventory.comment}
          </div>
        )
      }
      {
        inventoryCommentFiles.map((file) => (
          <div className="fileIconGroup">
            {
              !isInArchive && canUserUpdate && (
                <span
                  className="material-icons remove"
                  title="Удалить"
                  onClick={() => removeFile(file.url)}
                >
                  cancel
                </span>
              )
            }
            <span
              className="material-icons file"
              onClick={() =>
                window.open(
                  process.env.REACT_APP_SERVER_URL + file.url,
                  "_blank"
                )
              }
            >
              description
            </span>
            <span
              className="name"
              onClick={() =>
                window.open(
                  process.env.REACT_APP_SERVER_URL + file.url,
                  "_blank"
                )
              }
            >
              {file.name}
            </span>
          </div>
        ))
      }
    </Modal>
  );
};

export default CommentModal;
