import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

import ButtonComponent from "components/ButtonComponent";
import InputComponent from "components/input/InputComponent";

import {syncGoodsAsyncAction} from "redux/slices/good/good-api-actions";
import {signInAsyncAction} from "redux/slices/auth-process/auth-process-api-actions";

import {AppRoute} from "constants/routes";

import cl from "styles/pages/signup.module.scss";

const Login = () => {
  // Контейнер для сообщения внизу формы
  const [formMessage, setFormMessage] = useState("");
  // Данные формы
  const [credentials, setCredentials] = useState({
    email: {value: "", isError: false},
    password: {value: "", isError: false},
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Метод изменения данных в форме
  const changeHandler = (name, data) => {
    const f = structuredClone(credentials);
    f[name].value = data;
    f[name].isError = false;
    setCredentials(f);
  };

  // Перенаправление на регистрацию
  const goToReg = () => {
    navigate(AppRoute.Auth.SIGN_UP);
  };

  // Переход в личный кабинет
  const login = () => {
    const copy = structuredClone(credentials);
    const setError = (name) => {
      copy[name].isError = true;
      setCredentials(copy);
    };

    if (credentials.email.value.indexOf("@") === -1) {
      setError("email");
      return;
    }
    if (credentials.password.value.length < 6) {
      setError("password");
      return;
    }

    dispatch(signInAsyncAction(credentials));
  };

  // Вход по нажатию Enter
  const handleEnter = (e) => {
    if (e.key === "Enter") login();
  };

  // Запрос синхронизации товаров в БД из БизнесРУ
  useEffect(() => {
    dispatch(syncGoodsAsyncAction());
  }, []);

  return (
    <>
      <title>Войти</title>

      <div className={cl.main + " container"}>
        <form>
          <InputComponent
            type="email"
            label="Email"
            name="email"
            placeholder="введите email"
            value={credentials.email.value}
            setValue={changeHandler}
            required={true}
            isError={credentials.email.isError}
          />
          <InputComponent
            type="password"
            label="Пароль"
            name="password"
            placeholder="введите пароль"
            value={credentials.password.value}
            setValue={changeHandler}
            required={true}
            isError={credentials.password.isError}
            onKeyDown={handleEnter}
          />

          {
            formMessage.length > 1 && (
              <div>
                <p>{formMessage}</p>
              </div>
            )
          }

          <div className="row">
            <div className="col-sm-6">
              <ButtonComponent type="default" onClick={goToReg}>
                Регистрация
              </ButtonComponent>
            </div>
            <div className="col-sm-6">
              <ButtonComponent type="accent" onClick={login}>
                Войти
              </ButtonComponent>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
