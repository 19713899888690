import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";

import cl from "styles/components/MapPanel.module.scss";

import ButtonComponent from "components/ButtonComponent";
import MapPanelEditFieldItem from 'components/map-panel-edit-field-item/map-panel-edit-field-item';

const MapPanel = ({map, fields, setFields, alert, allowEdit = false}) => {
	const navigate = useNavigate();

	// Локальные состояния с данными карты
	const [mapName, setMapName] = useState(map.name);
	const [panelFields, setPanelFields] = useState(fields);
	const [search, setSearch] = useState("");
	const [storages, setStorages] = useState(map.storages || []);
	const [storagesAllowed, setStoragesAllowed] = useState(map.storages_allowed || []);
	const [storageFiltered, setStorageFiltered] = useState(false);
	
	// Обновление локального состояния при изменении глобального
	useEffect(() => {
		if (search === "" && !storageFiltered) {
			setPanelFields(fields);
		}
	}, [fields, search, storageFiltered]);
	
	// Изменение названия карты
	const editName = (e) => {
		setMapName(e.target.value);
		axios.post("map/edit/" + map.id, {
			mapName: e.target.value
		});
	}
	
	// Поиск по названию поля
	const doSearch = (evt) => {
		const searchInputValue = evt.currentTarget.value.trim();

		setSearch(searchInputValue);

		if (searchInputValue !== "") {
			const filteredFields = fields.filter((field) => field.name.toLowerCase().includes(searchInputValue.toLowerCase()))
			setPanelFields(filteredFields);
		}
	}
	
	// Открепление склада от карты
	const removeStorage = (index) => {
		axios.post("map/removeMapStorage/" + map.id, {
			storage: storages[index].name
		}).then(() => {
			const allowedCopy = Object.assign([], storagesAllowed);
			allowedCopy.push(storages[index])
			setStoragesAllowed(allowedCopy);
			
			const copy = Object.assign([], storages);
			copy.splice(index, 1);
			setStorages(copy);
		}).catch(() => {
			alert("Ошибка открепления склада", "danger");
		});
	}
	
	// Добавление склада к карте
	const setStorage = (name, value) => {
		axios.post("map/setMapStorage/" + map.id, {
			storage: value
		}).then(() => {
			const copy = Object.assign([], storages);
			copy.push({name: value, value: value});
			setStorages(copy);
			setStoragesAllowed(storagesAllowed.filter(allowed => allowed.name !== value));
		}).catch(() => {
			alert("Ошибка прикрепления склада", "danger");
		});
	}
	
	// Удаление карты
	const deleteMap = () => {
		alert("Удалить карту?", "danger", 10000, [
			{
				text: "Да",
				handler: function(){
					axios.delete("map/delete/" + map.id).then(() => {
						alert("Успешно удалено", "success");
						navigate("/");
					}).catch(() => {
						alert("Ошибка: проблемы с подключением", "danger");
					});
				}
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "default", 1);
				}
			}
		]);
	}
	
	// Фильтрация полей по названию склада
	const filterByStorage = (storage) => {
		setPanelFields(fields.filter(field => field.storage === storage));
		setStorageFiltered(true);
	}
	
	return (
		<div className={cl.mapPanelContainer}>
			<div className={cl.mapPanel}>
				<div className={cl.mapName}>
					{allowEdit
						? <input
								type="text"
								value={mapName}
								onChange={editName}
								placeholder="Название карты"
								className={cl.setName}
							/>
						: mapName
					}
				</div>
				
				<div className={cl.mapStorages}>
					Доступные склады:<br/>
					{storages.map((storage, index) =>
						<div>
							<span onClick={() => filterByStorage(storage.name)}>{storage.name}</span>
							{allowEdit &&
								<span
									className={"material-icons " + cl.remove}
									title="Открепить"
									onClick={() => removeStorage(index)}
								>
									close
								</span>
							}
						</div>
					)}
					{/* {storagesAllowed.length > 0 && allowEdit &&
						<CustomSelect options={storagesAllowed} name="storagesAllowed" currentValue=""
										  changeHandler={setStorage} placeholder="Добавить склад" eraseValue={true}/>
					} */}
				</div>
				
				<div className={cl.mapName}>
					<input type="search" value={search} onChange={doSearch} placeholder="Поиск поля"/>
				</div>
				
				{
					panelFields.map((field, index) =>
						<MapPanelEditFieldItem
							field={field}
							index={index}
							storages={storages}
							fields={fields}
							setFields={setFields}
							panelFields={panelFields}
							setPanelFields={setPanelFields}
							allowEdit={allowEdit}
						/>
					)
				}
				
				{
					allowEdit && (
						<div className={cl.dangerZone}>
							<ButtonComponent type="danger" onClick={deleteMap}>Удалить карту</ButtonComponent>
						</div>
					)
				}
			</div>
		</div>
	);
};

export default MapPanel;