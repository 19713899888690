import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';

import fetcher, {loadingStatus} from 'helpers/fetcher';
import {ApiRoute, Inventory, Map} from 'constants/endpoints';
import {redirectToRoute} from 'redux/actions';

// TODO: это дубль экшена fetchAddresses
// нужно переписать на запрос с параметром типа ?storage=storage
// и оставить унифицированный экшен
const fetchAddresses = createAsyncThunk('inventory/getAllAddresses', async () => {
  const response = await fetcher(Map.GET_ALL_ADDRESSES);
  return response.json();
});

// TODO: это дубль экшена fetchMapFields
// нужно переписать на запрос с параметром типа ?storage=storage
// и оставить унифицированный экшен
const fetchFields = createAsyncThunk('inventory/getAllFields', async () => {
  const response = await fetcher(Map.GET_ALL_FIELDS);
  return response.json();
});

// ============================== АСИНХРОННЫЕ ДЕЙСТВИЯ ЧЕРЕЗ middleware ==============================

// Запрос списка инвентаризаций
const fetchInventories = createAsyncThunk(
  'inventory/all',
  async (_args, {dispatch, extra: api}) => {
    const {data} = await api.get(Inventory.ALL);
    return data;
  }
);

// Запрос списка товаров конкретной инвентаризации по её id
const fetchInventory = createAsyncThunk(
  'inventory/getItems',
  async (id, {dispatch, extra: api}) => {
    const {data} = await api.get(`${Inventory.ITEMS}/${id}`);
    return data;
  }
);

// Первичная отправка Exel файла инвентаризации на сервер
const postInventoryFile = createAsyncThunk(
  'inventory/postFile',
  async (file, {dispatch, extra: api}) => {
    const formData = new FormData();
    formData.append("act", file);

    const {data} = await api.post(Inventory.POST_FILE, formData)
      .then((res) => {
        dispatch(fetchInventories());
        return res;
      })
      .then((res) => {
        dispatch(redirectToRoute(`/inventory/edit/${res.data.id}`));
        return res;
      })
      .catch(() => {
        dispatch(redirectToRoute('/inventory'));
      });

    return data;
  }
);

// Редактирование Exel файла инвентаризации
const editFile = createAsyncThunk(
  'inventory/editFile',
  async (arg, {dispatch, extra: api}) => {
    const {data} = await api.post(Inventory.EDIT_FILE, arg);
    return data;
  }
);

// Сохранение данных инвентаризации
const postInventoryItemsAsyncAction = createAsyncThunk(
  'inventory/save',
  async (args, {dispatch, extra: api}) => {
    const id = args.id;
    const comment = args.comment;
    const inventory = args.inventory;

    const {data} = await api.post(Inventory.SAVE, {comment, inventory})
      .then((res) => {
        dispatch(fetchInventory(id));
        return res;
      });

    return data;
  }
);

// Сохранение примечания
const setCommentAsyncAction = createAsyncThunk(
  'inventory/setComment',
  async (args, {dispatch, extra: api}) => {
    const id = args.id;
    const comment = args.comment;

    await api.post(`${Inventory.SET_COMMENT}/${id}`, {comment});
  }
);

// Сохранение файлов из модального окна примечания
const setCommentFilesAsyncAction = createAsyncThunk(
  'inventory/setCommentFile',
  async (args, {dispatch, extra: api}) => {
    const id = args.id;
    const formData = args.formData;

    const {data} = await api.post(`${Inventory.SET_COMMENT_FILE}/${id}`, formData);

    return data;
  }
);

// Отправка скриншота инвентаризации
const postInventoryScreenShot = createAsyncThunk(
  'inventory/editInventoryThumb',
  async (arg, {dispatch, extra: api}) => {
    const {data} = await api.post(`${Inventory.EDIT_THUMB}/${arg.id}`, arg.body);
    return data;
  }
);

// Создание файлов для Бизнес.ру
const editFilesBru = createAsyncThunk(
  'inventory/editFilesBru',
  async (arg, {dispatch, extra: api}) => {
    const {data} = await api.post(Inventory.EDIT_FILES_BRU, arg);
    return data;
  }
);

// Проведение первичной инвентаризации
const provideInventoryAsyncAction = createAsyncThunk(
  'inventory/provide',
  async (args, {dispatch, extra: api}) => {
    const id = args.id;
    const showSuccessMsg = args.showSuccessMsg;
    const {data} = await api.get(`${Inventory.PROVIDE}/${id}`)
      .then((res) => {
        dispatch(fetchInventories());
        return res;
      })
      .then((res) => {
        dispatch(redirectToRoute('/inventory'));
        showSuccessMsg();
        return res;
      });

    return data;
  }
);

// Отмена инвентаризации
const cancelInventoryAsyncAction = createAsyncThunk(
  'inventory/cancel',
  async (args, {dispatch, extra: api}) => {
    const id = args.id;
    const showSuccessMsg = args.showSuccessMsg;
    const {data} = await api.get(`${Inventory.PROVIDE}/${id}?deny=1`)
      .then((res) => {
        dispatch(fetchInventories());
        return res;
      })
      .then((res) => {
        dispatch(redirectToRoute('/inventory'));
        showSuccessMsg();
        return res;
      })
      .catch((res) => {
        const axiosResponseMessage = res.message;
        toast.error(axiosResponseMessage);
      });

    return data;
  }
);

// Удаление инвентаризации
const removeInventory = createAsyncThunk(
  'inventory/remove',
  async (id, {dispatch, extra: api}) => {
    const {data} = await api.delete(`${Inventory.REMOVE}/${id}`);

    return data;
  }
);

// Загрузка архивов при переходе в раздел "Архивы"
const fetchArchiveInventories = createAsyncThunk(
  'inventory/archive',
  async (_args, {dispatch, extra: api}) => {
    const {data} = await api.get(Inventory.ARCHIVES);
    return data;
  }
);

// Копирование инвентаризации
const copyInventoryAsyncAction = createAsyncThunk(
  'inventory/copy',
  async (args, {dispatch, extra: api}) => {
    const id = args.id;
    const showSuccessMsg = args.showSuccessMsg;

    const {data} = await api.get(`${Inventory.COPY}/${id}`)
      .then((res) => {
        dispatch(fetchInventories());
        return res;
      })
      .then((res) => {
        dispatch(redirectToRoute('/inventory'));
        showSuccessMsg();
        return res;
      });

    return data;
  }
);

const setInventoryNewGoodInitialAmountsAsyncAction = createAsyncThunk(
  'inventory/setInventoryNewGoodInitialAmounts',
  async (body, {extra: api}) => {
    const {data} = await api.post(ApiRoute.Fields.SET_INITIAL_AMOUNTS, body);

    return {data, plantName: body.name};
  }
);

const initialState = {
  inventories: {
    status: loadingStatus.IDLE,
    data: [],
    archive: [],
    error: null,
  },
  inventory: {
    status: loadingStatus.IDLE,
    id: null,
    storage: '',
    provided: null,
    isCopy: null,
    employee: '',
    comment: '',
    commentFiles: [],
    filename: '',
    data: [],
  },
  addresses: {
    status: loadingStatus.IDLE,
    data: [],
  },
  fields: {
    status: loadingStatus.IDLE,
    data: [],
  },
};

export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setInventoryDataAction: (state, action) => {
      state.inventory.data = action.payload;
    },
    setCommentAction: (state, action) => {
      state.inventory.comment = action.payload;
    },
    setCommentFilesAction: (state, action) => {
      state.inventory.commentFiles = action.payload;
    },
    setEmployeeAction: (state, action) => {
      state.inventory.employee = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchInventories.pending, (state) => {
        state.inventories.status = loadingStatus.LOADING;
      })
      .addCase(fetchInventories.fulfilled, (state, action) => {
        state.inventories.status = loadingStatus.SUCCEEDED;
        state.inventories.data = action.payload.result;
      })
      .addCase(fetchInventories.rejected, (state, action) => {
        state.inventories.status = loadingStatus.FAILED;
        state.inventories.error = `${action.error.name}: ${action.error.message}`;
      })
      .addCase(fetchInventory.pending, (state) => {
        state.inventory.status = loadingStatus.LOADING;
      })
      .addCase(fetchInventory.fulfilled, (state, action) => {
        if (action.payload.result.length) {
          state.inventory.status = loadingStatus.SUCCEEDED;
          state.inventory.data = action.payload.result;
          state.inventory.id = action.payload.result[0].inventory_id;
          state.inventory.storage = action.payload.result[0].storage;
          state.inventory.provided = action.payload.result[0].provided;
          state.inventory.isCopy = action.payload.result[0].is_copy;
          state.inventory.filename  = action.payload.result[0].filename;
          state.inventory.comment  = action.payload.result[0].comment;
          state.inventory.commentFiles  = action.payload.result[0].files;
          state.inventory.employee  = action.payload.result[0].employee;
        }
      })
      .addCase(fetchAddresses.pending, (state) => {
        state.addresses.status = loadingStatus.LOADING;
      })
      .addCase(fetchAddresses.fulfilled, (state, action) => {
        state.addresses.status = loadingStatus.SUCCEEDED;
        state.addresses.data = action.payload.result;
      })
      .addCase(fetchFields.pending, (state) => {
        state.addresses.status = loadingStatus.LOADING;
      })
      .addCase(fetchFields.fulfilled, (state, action) => {
        state.addresses.status = loadingStatus.SUCCEEDED;
        // const fieldList = action.payload.result.filter((field) => field.storage === state.inventory.data[0].storage);
        // console.log('/map/getAllFields', fieldList);
        // state.addresses.data = fieldList;
      })
      .addCase(fetchArchiveInventories.pending, (state) => {
        state.inventories.status = loadingStatus.LOADING;
      })
      .addCase(fetchArchiveInventories.fulfilled, (state, action) => {
        state.inventories.status = loadingStatus.SUCCEEDED;
        state.inventories.archive = action.payload.result;
      })
      .addCase(setCommentFilesAsyncAction.fulfilled, (state, action) => {
        const files = action.payload.files;
        state.inventory.commentFiles = [...state.inventory.commentFiles, ...files];
      })
      .addCase(setInventoryNewGoodInitialAmountsAsyncAction.fulfilled, (state, action) => {
        const plantName = action.payload.plantName;
        const amounts = action.payload.data.amounts;
        const inventoryItems = [...state.inventory.data];
        const selectedItemIndex = inventoryItems.findIndex((item) => item.name === plantName);

        const emptyDatas = [
          {
            address_uchet: '',
            amount_uchet: '',
            address_fact: '',
            amount_fact: '',
            spread: 0
          }
        ];
        const updatedInventoryDatas = amounts.length ? amounts.map((amountsItem) => ({
          address_uchet: amountsItem.address,
          amount_uchet: amountsItem.amount,
          address_fact: amountsItem.address,
          amount_fact: '',
          spread: -amountsItem.amount
        })) : emptyDatas;

        inventoryItems[selectedItemIndex].datas = updatedInventoryDatas;
        inventoryItems[selectedItemIndex].total_uchet = amounts.reduce((res, item) => res + Number(item.amount), 0);
        state.inventory.data = inventoryItems;
      });
  }
});

const setInventoryDataAction = inventorySlice.actions.setInventoryDataAction;
const setCommentAction = inventorySlice.actions.setCommentAction;
const setCommentFilesAction = inventorySlice.actions.setCommentFilesAction;
const setEmployeeAction = inventorySlice.actions.setEmployeeAction;

export {
  fetchInventories,
  fetchInventory,
  fetchAddresses,
  fetchFields,
  editFile,
  postInventoryItemsAsyncAction,
  postInventoryScreenShot,
  postInventoryFile,
  provideInventoryAsyncAction,
  cancelInventoryAsyncAction,
  removeInventory,
  editFilesBru,
  setInventoryDataAction,
  setCommentAction,
  setCommentFilesAction,
  setEmployeeAction,
  fetchArchiveInventories,
  copyInventoryAsyncAction,
  setInventoryNewGoodInitialAmountsAsyncAction,
  setCommentAsyncAction,
  setCommentFilesAsyncAction
};
