import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";

const fetchField = createAsyncThunk("fields/getField/id", async ({ id, q = "" }) => {
  const response = await fetcher(`/fields/getField/${id}${q}`);
  return response.json();
});

const fetchFieldHead = createAsyncThunk("fields/getFieldHead/id", async ({ id, q = "" }) => {
  const response = await fetcher(`/fields/getFieldHead/${id}${q}`);
  return response.json();
});

const fetchFieldForms = createAsyncThunk("fields/getForms?q", async (q = "") => {
  const response = await fetcher(`/fields/getForms${q}`);
  return response.json();
});

const fetchFieldForm = createAsyncThunk("fields/getForm/id", async (id) => {
  const response = await fetcher(`/fields/getForm/${id}`);
  return response.json();
});

const postUpdateGoodsStock = (id) => {
  return fetcher(`/fields/updateFieldsAmountByGoods/${id}`, {
    method: "POST",
    // body: JSON.stringify(data),
  });
};

const initialState = {
  field: {
    status: loadingStatus.IDLE,
    data: [],
    error: null,
  },
  fieldHead: {
    status: loadingStatus.IDLE,
    data: {},
    error: null,
  },
  addressList: [],
  fieldForms: {
    status: loadingStatus.IDLE,
    data: [],
  },
  fieldForm: {
    status: loadingStatus.IDLE,
    data: {},
  },
};

export const fieldSlice = createSlice({
  name: "field",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchField.pending, (state) => {
        state.field.status = loadingStatus.LOADING;
      })
      .addCase(fetchField.fulfilled, (state, action) => {
        state.field.status = loadingStatus.SUCCEEDED;
        state.field.data = action.payload.result;
      })
      .addCase(fetchField.rejected, (state, action) => {
        state.field.status = loadingStatus.FAILED;
        state.field.error = `${action.error.name}: ${action.error.message}`;
      })

      .addCase(fetchFieldHead.pending, (state) => {
        state.fieldHead.status = loadingStatus.LOADING;
      })
      .addCase(fetchFieldHead.fulfilled, (state, action) => {
        state.fieldHead.status = loadingStatus.SUCCEEDED;
        state.fieldHead.data = action.payload.result;

        // Список адресов для фильтра
        const header = action.payload.result;
        const addressList = [];
        for (let i = 1; i <= header.rows; i++) {
          addressList.push({
            name: header.name + "." + i,
            value: header.name + "." + i,
          });
        }
        addressList.push({ name: header.name + ".-", value: header.name + ".-" });

        state.addressList = addressList;
      })
      .addCase(fetchFieldHead.rejected, (state, action) => {
        state.fieldHead.status = loadingStatus.FAILED;
        state.fieldHead.error = `${action.error.name}: ${action.error.message}`;
      })

      .addCase(fetchFieldForms.pending, (state) => {
        state.fieldForms.status = loadingStatus.LOADING;
      })
      .addCase(fetchFieldForms.fulfilled, (state, action) => {
        state.fieldForms.status = loadingStatus.SUCCEEDED;
        state.fieldForms.data = action.payload.result;
      })

      .addCase(fetchFieldForm.pending, (state) => {
        state.fieldForm.status = loadingStatus.LOADING;
      })
      .addCase(fetchFieldForm.fulfilled, (state, action) => {
        state.fieldForm.status = loadingStatus.SUCCEEDED;
        state.fieldForm.data = action.payload.result;
      })
  }
});

export {
  fetchField,
  fetchFieldHead,
  fetchFieldForms,
  fetchFieldForm,
  postUpdateGoodsStock,
};
