import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import html2canvas from "html2canvas";
import {cloneDeep} from "lodash";

import FormPostings from "./FormPostings";
import FormCharges from "./FormCharges";
import FormShiftings from "./FormShiftings";
import FormSupplies from "./FormSupplies";
import Modal from "../Modal";
import Preloader from "components/preloader/SpinPlaceholderComponent";

import {fetchGoodsAsyncAction} from "redux/slices/good/good-api-actions";
import {postUpdateGoodsStock} from "redux/slices/fieldSlice";

import {loadingStatus} from "helpers/fetcher";

import cl from "styles/pages/[fieldId].module.scss";

const Form = ({
  alert,
  setMenuCondition,
  groups,
  type,
  form = [],
  storages,
  AddressList = [],
  userLevel,
}) => {
  const navigate = useNavigate();
  const router = {
    pathname: window.location.pathname,
    push: (p) => navigate(p),
    query: {type: (new URLSearchParams(window.location.search)).get("type") },
  };

  const dispatch = useDispatch();
  const goods = useSelector((state) => state.good).goods;

  // Надписи в зависимости от типа формы
  const typeNames = {
    Оприходование: "Акт оприходования",
    Списание: "Акт списания",
    Перемещение: "Перемещение",
    Поступление: "Поступление",
  };

  // Соответствие ссылок для FAQ
  const faqButtons = {
    Оприходование: "posting",
    Списание: "charge",
    Перемещение: "shifting",
    Поступление: "supply",
  };

  // Объект пустой строки
  const emptyLine = {
    b_group: "", // Название группы в Бизнес.ру
    b_group_id: 0, // Идентификатор группы в Бизнес.ру
    article: "", // Артикул
    name: "", // Наименование
    good_id: 0, // id товара в Бизнес.ру
    amounts: [{ amount: "", address: "" }], // Остатки на складах
    totalAmount: "", // Общий остаток на складах
    userPositions: [{ amount: "", address: "" }], // Пользовательский ввод позиции
    isError: false, // Флаг ошибки в строке
  };

  // Объект для текущей даты
  const date = new Date();

  const toScreenShot = useRef();

  // Параметры документа
  const [documentNumber, setDocumentNumber] = useState(form.document_number ? form.document_number : "");
  const [documentDate, setDocumentDate] = useState(form.document_date ? form.document_date : "");
  const [storage, setFormStorage] = useState(form.storage ? form.storage : "");
  const [employee, setFormEmployee] = useState(form.employee ? form.employee : "");
  const [addressList, setAddressList] = useState([]);
  const [comment, setComment] = useState(form.comment ? form.comment : "");

  // Массив строк таблицы с начальной пустой строкой
  const [lines, setLines] = useState(
    form.form
      ? JSON.parse(JSON.stringify(form.form))
      : [JSON.parse(JSON.stringify(emptyLine))]
  );

  // Асинхронные данные из Бизнес.ру
  const [plantsNames, setPlantsNames] = useState([]);
  const [plantsArticles, setPlantsArticles] = useState([]);

  // Поле сортировки и направление
  const [sorted, setSorted] = useState("name");
  const [sortDir, setSortDir] = useState(true);

  // Модальное окно
  const [modalIsActive, setModalIsActive] = useState(false);

  // Файлы в примечании
  const [files, setFiles] = useState(form.files ? form.files : []);

  // Активность прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

  // Интервал автосохранения
  const autoSaveInterval = 300000;

  useEffect(() => {
    // Установка даты документа
    if (!form.document_date) {
      setDocumentDate(
        String(date.getDate()).padStart(2, "0") +
          "." +
          String(date.getMonth() + 1).padStart(2, "0") +
          "." +
          date.getFullYear() +
          " " +
          String(date.getHours()).padStart(2, "0") +
          ":" +
          String(date.getMinutes()).padStart(2, "0")
      );
    }

    // Установка склада в массив данных
    if (type !== "Поступление") {
      const copy = Object.assign([], lines);
      copy[0].storage = storage;
      setLines(copy);
    }
  }, []);

  // Вывод уведомления при переходе по ссылке, если есть несохраненные данные
  useEffect(() => {
    if (
      (lines.length === 1 && lines[0].name === "") ||
      JSON.stringify(lines) === JSON.stringify(form.form) ||
      router.pathname.includes("archive") ||
      form.provided === 1
    ) {
      setMenuCondition({});
      return;
    }

    // Установка условия с обязательной очисткой после перехода по ссылке
    setMenuCondition({
      text: "Есть несохраненные данные. Сохранить?",
      type: "danger",
      duration: 10000,
      buttons: [
        {
          text: "Да",
          handler: function (href) {
            save(true).then((saved) => {
              if (saved) router.push(href);
            });
          },
        },
        {
          text: "Нет",
          handler: function (href) {
            alert("", "danger", 1);
            router.push(href).then(() => {
              setMenuCondition({});
            });
          },
        },
      ],
    });

    // Автосохранение
    for (let i = 1; i < 1000; i++) clearInterval(i);
    setInterval(() => {
      if (form.id > 0 && form.provided === 0) save(false, false);
    }, autoSaveInterval);
  }, []);

  useEffect(() => {
    if (form.form) setLines(JSON.parse(JSON.stringify(form.form)));
  }, [form.form])

  const handleUpdateGoodsStock = async () => {
    const response = await postUpdateGoodsStock(form.id);
    if (response.ok) {
      window.location.reload();
    }
  };

  // Скролл вниз при раскрытии списка с растениями внизу страницы
  const scrollPlus = useRef(null);
  const scrollCustomSelect = (index) => {
    if (lines.length - 1 - index < 6)
      scrollPlus.current.scrollIntoView({ behavior: "smooth" });
  };

  // Изменение наименований
  const onChangeName = (index, name, value) => {
    if ((name === "amounts" || name === "totalAmount") && lines[index].name === "")
      return;

    if (storage === "") {
      alert("Выберите склад!", "danger");
      return;
    }

    const copy = Object.assign([], lines);

    // Проверка дублирующей позиции
    let error = false;
    if (name === "name" || name === "article") {

      copy.forEach((cp) => {
        if (cp[name] === value) error = true;
      });
    }

    if (error) {
      alert("Такой товар уже есть в списке!", "danger");
      copy.splice(index, 1);
   } else {
      copy[index][name] = value;
    }

    setLines(copy);
  };

  // Изменение позиций
  const onChangePos = (index, name, value) => {
    if (storage === "") {
      alert("Выберите склад!", "danger");
      return;
    }

    const copy = Object.assign([], lines);
    copy[index.index].userPositions[index.ind][name] = value;
    if (
      name === "amount" &&
      copy[index.index].amounts[index.ind].address !== ""
    )
      copy[index.index].userPositions[index.ind].address =
        copy[index.index].amounts[index.ind].address;

    if (name === "amount_current") {
      copy[index.index].amounts[index.ind]["amount_after"] = value;
      copy[index.index].userPositions[index.ind]["amount_after"] = value;
      if (value > 0)
        copy[index.index].userPositions[index.ind]["address_current"] =
          copy[index.index].amounts[index.ind]["address"];
      else copy[index.index].userPositions[index.ind]["address_current"] = "";
    }
    if (name === "amount_after")
      copy[index.index].amounts[index.ind]["amount_after"] =
        copy[index.index].amounts[index.ind]["amount"] === ""
          ? value
          : parseInt(copy[index.index].amounts[index.ind]["amount"]) +
            parseInt(value);
    if (name === "address_after")
      copy[index.index].amounts[index.ind]["address_after"] = value;

    // Изменение второго общего кол-ва
    if (name === "amount_current" || name === "amount_after") {
      copy[index.index].totalAmount_after = 0;
      copy[index.index].amounts.forEach((am) => {
        copy[index.index].totalAmount_after += parseInt(am.amount_after);
      });
    }

    setLines(copy);
  };

  // Добавление строки
  const addLine = (index = -1, firstDeleted = false) => {
    const copy = !firstDeleted ? Object.assign([], lines) : [];


    // Копирование или вставка новой строки
    if (index > -1) copy.push(JSON.parse(JSON.stringify(copy[index])));
    else {
      copy.push(JSON.parse(JSON.stringify(emptyLine)));
      copy[0].userPositions.forEach((pos, ind) => {
        if (ind > 0)
          copy[copy.length - 1].userPositions.push(
            JSON.parse(JSON.stringify(emptyLine.userPositions[0]))
          );
      });
      if (storage) copy[copy.length - 1].storage = storage;
      if (copy[0].toStorage)
        copy[copy.length - 1].toStorage = copy[0].toStorage;
    }

    setLines(copy);
    scrollCustomSelect(copy.length - 1);
  };

  // Удаление строки
  const deleteLine = (index) => {
    const copy = Object.assign([], lines);
    copy.splice(index, 1);

    // Если удалена последняя строка, автоматическое добавление новой
    if (copy.length === 0) addLine(-1, true);
    else setLines(copy);
  };

  // Сохранение данных
  const save = async (fromMenu = false, allowValidation = true) => {
    alert("", "default", 6000, [], true);

    // Проверка склада
    if (storage === "") {
      alert("Не указан склад", "danger");
      return false;
    }

    // Проверка бригадира
    if (employee === "") {
      alert("Укажите ответственного бригадира", "danger");
      return false;
    }

    // Валидация формы
    let formError = false;
    let errorNotified = false;
    const copy = Object.assign([], lines);
    if (allowValidation) {
      copy.forEach((c, i) => {
        let error = false;

        // Проверка пустых полей
        if (c.name === "") {
          formError = true;
          error = true;
          errorNotified = true;
          alert("Не указано наименование", "danger");
        }
        if (!c.totalAmount) {
          formError = true;
          error = true;
          errorNotified = true;
          alert(`"${c.name}" нет в журналах системы`, "danger");
        }
        if (c.article === "") {
          formError = true;
          error = true;
          errorNotified = true;
          alert("Не указан артикул", "danger");
        }
        if (c.group === "") {
          formError = true;
          error = true;
          errorNotified = true;
          alert("Не указана товарная группа", "danger");
        }

        // Проверка даты документа
        if (documentDate === "") {
          formError = true;
          errorNotified = true;
          alert("Введите дату документа", "danger");
        }

        // Проверка корректного расхода
        if (type === "Списание" || type === "Перемещение") {
          c.userPositions.forEach((up, ind) => {
            if (
              parseInt(up.amount) > parseInt(c.amounts[ind]?.amount) ||
              parseInt(up.amount_current) > parseInt(c.amounts[ind]?.amount)
            ) {
              formError = true;
              error = true;
              errorNotified = true;
              alert("Расход больше остатка", "danger");
              return false;
            }
          });
        }

        // Проверка адресов прихода
        if (type === "Оприходование" || type === "Поступление") {
          c.userPositions.forEach((up) => {
            if (up.address.trim() === "" && parseInt(up.amount) > 0) {
              formError = true;
              error = true;
              errorNotified = true;
              alert("Введите адрес прихода", "danger");
              return false;
            }
          });
        }

        if (error) {
          lines[i].isError = true;
          setTimeout(() => {
            lines[i].isError = false;
          }, 5000);
        }
      });
    }

    if (formError) {
      if (!errorNotified) alert("Некорректно заполнены поля формы", "danger");
      return false;
    }

    const url =
      type === "Поступление"
        ? "businessRu/editSupply/" + form.supply_id
        : parseInt(form.id) > 0
        ? "fields/editForm/" + form.id
        : "fields/saveForm";
    return axios
      .post(process.env.REACT_APP_SERVER_ENTRYPOINT + "/" + url, {
        type: type,
        form: copy,
        document: {
          number: documentNumber,
          date: documentDate,
        },
        storage: storage,
        employee: employee,
        comment: comment,
      })
      .then((response) => {
        if (!response.data.message) alert("Успешно сохранено", "success");
        else alert(response.data.message, "default");
        setMenuCondition({});
        screenShot(response.data.id).then(() => {
          if (!form.id && !fromMenu && type !== "Поступление")
            navigate("/form/" + response.data.id + "?type=" + type);
        });

        return true;
      })
      .catch(() => {
        alert("Ошибка сохранения", "danger");
        return false;
      });
  };

  // Фильтрация наименований в зависимости от группы
  const filterNames = (groupId) => {
    if (groupId === 0) return plantsNames;

    const filtered = plantsNames.filter((name) => {
      return name.b_group_id === groupId;
    });
    return filtered.sort((a, b) => {
      if (a.name === b.name) return 0;
      else return a.name > b.name ? 1 : -1;
    });
  };

  // Фильтрация артикулов в зависимости от группы
  const filterArticles = (groupId) => {
    if (groupId === 0) return plantsArticles;

    return plantsArticles.filter((article) => {
      return article.b_group_id === groupId;
    });
  };

  const filterSelectComponentOptions = (items, item, selectComponentName) => {
    const itemsNames = structuredClone(items).map((itm) => itm.name);
    // Отсеиваем те товары, которые уже есть в инвентаризации
    const filteredPlants = goods.filter((plant) => !itemsNames.includes(plant.name));
    const filteredArticles = filteredPlants.map((plant) => ({
      id: plant.b_group_id,
      name: plant.article,
      value: plant.article
    }));

    // Если в новой строке группа уже выбрана, то фильтруем список для селекта ещё и по группе
    if (item.b_group && groups.data.length) {
      const groupId = groups.data.find((gr) => gr.name === item.b_group).id;
      const filteredByGroupPlants = filteredPlants.filter((plant) => plant.b_group_id === groupId);
      const filteredByGroupArticles = filteredByGroupPlants.map((plant) => ({
        id: plant.b_group_id,
        name: plant.article,
        value: plant.article
      }));

      switch(selectComponentName) {
        case 'name':
          return filteredByGroupPlants;
        case 'article':
          return filteredByGroupArticles;
        default:
          return;
      };
    }

    switch(selectComponentName) {
      case 'name':
        return filteredPlants;
      case 'article':
        return filteredArticles;
      default:
        return;
    };
  };

  // Метод для отправки скриншота @deprecated
  const screenShot = async (id) => {
    return true;
    const canvas = await html2canvas(toScreenShot.current, {
      useCORS: true,
    }).then((canv) => {
      const image = canv.toDataURL("image/png", 1.0);
      axios.post(
        process.env.REACT_APP_SERVER_ENTRYPOINT + "/fields/editFormThumb/" + id,
        {thumb: image }
      );
    });
  };

  // Установка склада для формы
  const setStorage = (name, value) => {
    const copy = cloneDeep(lines);
    copy.forEach((cp, ind) => {
      copy[ind].storage = value;
    });

    // Запрос остатков
    if (!router.pathname.includes("supplies")) {
      copy.forEach((cp, ind) => {
        if (cp.name) {
          axios
          .post(
            process.env.REACT_APP_SERVER_ENTRYPOINT + "/fields/checkAmount",
            {name: cp.name, storage: value }
          )
          .then((response) => {
            if (response.data.amounts.length > 0) {
              copy[ind].amounts = response.data.amounts;
              copy[ind].userPositions = response.data.positions;
              copy[ind].totalAmount = response.data.total_amount;
           } else {
              copy[ind].amounts = [{ amount: "", address: "" }];
              copy[ind].userPositions = [{ amount: "", address: "" }];
              copy[ind].totalAmount = 0;
            }

            setLines(copy);
          });
        }
      });
   } else setLines(copy);

    setFormStorage(value);
    setAddressList(AddressList.filter((adr) => adr.storage === value));
  };

  // Запись ответственного бригадира
  const setEmployee = (name, value) => {
    const copy = Object.assign([], lines);
    copy[0].employee = value;
    setLines(copy);
    setFormEmployee(value);
  };

  // Проведение формы
  const provide = (isCorrection = false, isCorrectionHistory = false) => {
    setIsPreloaderActive(true);
    save().then((saved) => {
      if (!saved) {
        setIsPreloaderActive(false);
        return false;
      }

      if (type === "Поступление") {
        axios
          .post(
            process.env.REACT_APP_SERVER_ENTRYPOINT + "/fields/processForm",
            {
              storage: storage,
              type: "Поступление",
              form: lines,
              document: {
                number: documentNumber,
                date: documentDate,
              },
            }
          )
          .then(() => {
            alert("Поступление проведено", "success");
            router.push("/supplies");
          })
          .catch(() => {
            setIsPreloaderActive(false);
            alert("Ошибка проведения", "danger");
          });
     } else {
        let url = "fields/provideForm/" + form.id;
        if (form.provided === 1 && !isCorrection) url += "?done=1";
        if (form.provided === 1 && isCorrection) {
          url += "?correction=1";
          if (isCorrectionHistory) url += "&correction_history=1";
        }
        axios
          .get(process.env.REACT_APP_SERVER_ENTRYPOINT + "/" + url)
          .then((response) => {
            // Дефолтные значения для алерта
            let alertMessage =
              router.query.type +
              (isCorrection ? " (коррекция)" : "") +
              " проведено";
            let alertType = "success";
            let alertDuration = 6000;

            // Проверка сообщений в ответе
            if (response.data.form.message.trim() !== "") {
              alertMessage += "\r\n" + response.data.form.message;
              alertType = "default";
              alertDuration = 15000;
            }

            // Вывод алерта
            alert(alertMessage, alertType, alertDuration);

            router.push("/form?type=" + type);
          })
          .catch((error) => {
            console.log(error);
            setIsPreloaderActive(false);
            alert("Ошибка проведения", "danger");
          });
      }
    });
  };

  // Удаление формы
  const deleteForm = () => {
    axios
      .get(
        process.env.REACT_APP_SERVER_ENTRYPOINT +
          "/fields/deleteForm/" +
          form.id
      )
      .then(() => {
        alert(router.query.type + " удалено", "success");
        router.push("/form?type=" + type);
      })
      .catch(() => {
        alert("Ошибка удаления", "danger");
      });
  };

  // Отмена проведения в Системе
  const deny = () => {
    axios
      .get(process.env.REACT_APP_SERVER_ENTRYPOINT + "/fields/provideForm/" + form.id + "?deny=1")
      .then(() => {
        alert(router.query.type + " отменено", "success");
        router.push("/form?type=" + type);
      })
      .catch(() => {
        alert("Ошибка отмены", "danger");
      });
  };

  // Копирование формы
  const copyForm = () => {
    alert("Скопировать " + router.query.type + "?", "default", 10000, [
      {
        text: "Да",
        handler: function () {
          axios
            .get(
              process.env.REACT_APP_SERVER_ENTRYPOINT +
                "/fields/copyForm/" +
                form.id
            )
            .then((response) => {
              alert(router.query.type + " скопировано", "success");
              router.push("/form/" + response.data.id + "/?type=" + type);
            })
            .catch((error) => {
              console.log(error);
              alert("Ошибка копирования", "danger");
            });
        },
      },
      {
        text: "Нет",
        handler: function () {
          alert("", "default", 1);
        },
      },
    ]);
  };

  // Запись адреса для всех строк
  const setAllAddress = (name, value) => {
    const copy = Object.assign([], lines);
    copy.forEach((cp, ind) => {
      copy[ind].userPositions.forEach((up, i) => {
        copy[ind].userPositions[i].address = value;
      });
    });
    setLines(copy);
  };

  // Скачивание файла с формой
  const print = () => {
    save(false, false).then((saved) => {
      if (!saved) return;

      const url =
        "fields/" +
        (form.supply_id > 0
          ? "printFormSupply/" + form.supply_id
          : "printForm/" + form.id);
      axios
        .get(process.env.REACT_APP_SERVER_ENTRYPOINT + "/" + url)
        .then((response) => {
          router.push("/FILES/" + response.data.filename).then(() => {
            if (type === "Поступление")
              router.push("/supplies/" + form.supply_id);
            else router.push("/form/" + form.id + "?type=" + type);
          });
        })
        .catch(() => {
          alert("Ошибка скачивания", "danger");
        });
    });
  };

  // Проверка склада
  const storageChecker = () => {
    if (storage === "") alert("Выберите склад!", "danger");
  };

  // Функция сортировки
  const sortFn = (name) => {
    setSortDir(!sortDir);

    const pl = Object.assign([], lines);

    pl.sort((a, b) => {
      if (a[name] === b[name]) return 0;
      else if (!sortDir === true) return a[name] > b[name] ? 1 : -1;
      else return a[name] < b[name] ? 1 : -1;
    });

    setLines(pl);
    setSorted(name);
  };

  // Добавление файла к форме
  const attachFile = (e) => {
    // Сборка FormData
    const formData = new FormData();
    Object.values(e.target.files).forEach((file, index) => {
      formData.append("file_" + index, file);
    });

    axios
      .post(
        process.env.REACT_APP_SERVER_ENTRYPOINT +
          "/fields/setFormFile/" +
          form.id,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        alert(
          response.data.files.length > 1 ? "Файлы загружены" : "Файл загружен",
          "success"
        );
        const fl = [...files, response.data.files];
        setFiles(fl[0]);
      })
      .catch(() => {
        alert("Ошибка добавления файла", "danger");
      });
  };

  // Удаление файла
  const removeFile = (url) => {
    alert("Удалить файл?", "danger", 10000, [
      {
        text: "Да",
        handler: function () {
          axios
            .post(
              process.env.REACT_APP_SERVER_ENTRYPOINT +
                "/fields/removeFormFile",
              {filename: url }
            )
            .then(() => {
              alert("Файл удален", "success");
              const copy = Object.assign([], files);
              copy.forEach((cp, ind) => {
                if (cp.url === url) copy.splice(ind, 1);
              });
              setFiles(copy);
            });
        },
      },
      {
        text: "Нет",
        handler: function () {
          alert("", "default", 1);
        },
      },
    ]);
  };

  useEffect(() => {
    if (!goods.length) {
      dispatch(fetchGoodsAsyncAction());
    }
  }, []);

  useEffect(() => {
    setAddressList(
      storage === "" && AddressList.length
        ? AddressList
        : AddressList.filter((adr) => adr.storage === storage)
    );
  }, [storage, AddressList]);

  return (
    <>
      <div className={cl.floatPanel + " " + cl.controlsPanel}>
        {(form.done === 0 || (!form.id && !form.supply_id)) && userLevel > 1 && (
          <>
            {type !== "Поступление" && form.id > 0 && form.provided === 0 && (
              <span
                className="roundIcon dangerIcon material-icons"
                onClick={() =>
                  alert("Удалить?", "default", 10000, [
                    {
                      text: "Да",
                      handler: function () {
                        deleteForm();
                      },
                    },
                    {
                      text: "Нет",
                      handler: function () {
                        alert("", "default", 1);
                      },
                    },
                  ])
                }
                title="Удалить"
              >
                delete_sweep
              </span>
            )}

            {(type === "Поступление" || form.id > 0) && form.provided === 0 && (
              <span
                className="roundIcon material-icons"
                onClick={() =>
                  alert("Провести в Системе?", "default", 10000, [
                    {
                      text: "Да",
                      handler: function () {
                        provide();
                      },
                    },
                    {
                      text: "Нет",
                      handler: function () {
                        alert("", "default", 1);
                      },
                    },
                  ])
                }
                title="Провести в Системе"
              >
                checklist
              </span>
            )}

            {form.provided === 1 && (
              <>
                <span
                  className="roundIcon material-icons dangerIcon"
                  onClick={() =>
                    alert("Отменить проведение?", "default", 10000, [
                      {
                        text: "Да",
                        handler: function () {
                          deny();
                        },
                      },
                      {
                        text: "Нет",
                        handler: function () {
                          alert("", "default", 1);
                        },
                      },
                    ])
                  }
                  title="Отменить проведение"
                >
                  undo
                </span>
                <span
                  className="roundIcon material-icons successIcon"
                  onClick={() =>
                    alert("Провести в Базе?", "default", 10000, [
                      {
                        text: "Да",
                        handler: function () {
                          provide();
                        },
                      },
                      {
                        text: "Нет",
                        handler: function () {
                          alert("", "default", 1);
                        },
                      },
                    ])
                  }
                  title="Провести в Базе"
                >
                  done
                </span>
              </>
            )}

            {type === "Списание" && form.id > 0 && form.provided === 0 && (
              <span
                className="roundIcon material-icons"
                onClick={() =>
                  alert("Обновить остатки?", "default", 10000, [
                    {
                      text: "Да",
                      handler: function () {
                        handleUpdateGoodsStock();
                      },
                    },
                    {
                      text: "Нет",
                      handler: function () {
                        alert("", "default", 1);
                      },
                    },
                  ])
                }
                title="Обновить остатки"
              >
                refresh
              </span>
            )}

            {form.id > 0 && (
              <span
                className="roundIcon material-icons"
                onClick={copyForm}
                title="Скопировать"
              >
                copy
              </span>
            )}

            {form.provided === 0 && (
              <span
                className="roundIcon material-icons"
                onClick={() =>
                  alert("Сохранить?", "default", 10000, [
                    {
                      text: "Да",
                      handler: function () {
                        save(false, false);
                      },
                    },
                    {
                      text: "Нет",
                      handler: function () {
                        alert("", "default", 1);
                      },
                    },
                  ])
                }
                title="Сохранить"
              >
                save
              </span>
            )}

            {form.provided === 1 && type !== "Поступление" && (
              <span
                className="roundIcon material-icons"
                onClick={() =>
                  alert("Сделать коррекцией?", "default", 10000, [
                    {
                      text: "С историей",
                      handler: function () {
                        provide(true, true);
                      },
                    },
                    {
                      text: "Без Истории",
                      handler: function () {
                        provide(true);
                      },
                    },
                    {
                      text: "Нет",
                      handler: function () {
                        alert("", "default", 1);
                      },
                    },
                  ])
                }
                title="Записать коррекцию"
              >
                inventory
              </span>
            )}
          </>
        )}

        {(form.id > 0 || type === "Поступление") && (
          <span
            className="roundIcon material-icons"
            onClick={print}
            title="Скачать"
          >
            download
          </span>
        )}

        <span
          className="roundIcon material-icons"
          onClick={() => setModalIsActive(true)}
          title="Примечание"
        >
          maps_ugc
        </span>

        <span
          className="roundIcon material-icons"
          onClick={() =>
            window.open(
              typeof faqButtons[router.query.type] !== "undefined"
                ? "/faq/" + faqButtons[router.query.type]
                : "/faq/supply",
              "_blank"
            )
          }
          title="Инструкция"
        >
          quiz
        </span>
      </div>

      <div ref={toScreenShot} className="stickyContainer">
        {type === "Оприходование" && (
          <FormPostings
            groups={groups}
            documentNumber={documentNumber}
            lines={lines}
            emptyLine={emptyLine}
            addLine={addLine}
            documentDate={documentDate}
            filterArticles={filterSelectComponentOptions}
            filterNames={filterSelectComponentOptions}
            onChangeName={onChangeName}
            onChangePos={onChangePos}
            setLines={setLines}
            setDocumentNumber={setDocumentNumber}
            scrollPlus={scrollPlus}
            typeNames={typeNames}
            provided={form.provided}
            storage={storage}
            setStorage={setStorage}
            storages={storages}
            employee={employee}
            setEmployee={setEmployee}
            addressList={addressList}
            setDocumentDate={setDocumentDate}
            setAllAddress={setAllAddress}
            storageChecker={storageChecker}
            sortFn={sortFn}
            sortName={sorted}
          />
        )}
        {type === "Списание" && (
          <FormCharges
            groups={groups}
            documentNumber={documentNumber}
            lines={lines}
            addLine={addLine}
            deleteLine={deleteLine}
            documentDate={documentDate}
            filterArticles={filterSelectComponentOptions}
            filterNames={filterSelectComponentOptions}
            onChangeName={onChangeName}
            onChangePos={onChangePos}
            setDocumentNumber={setDocumentNumber}
            scrollPlus={scrollPlus}
            typeNames={typeNames}
            provided={form.provided}
            storage={storage}
            setStorage={setStorage}
            storages={storages}
            employee={employee}
            setEmployee={setEmployee}
            setDocumentDate={setDocumentDate}
            storageChecker={storageChecker}
            sortFn={sortFn}
            sortName={sorted}
          />
        )}
        {type === "Перемещение" && (
          <FormShiftings
            alert={alert}
            groups={groups}
            documentNumber={documentNumber}
            lines={lines}
            setLines={setLines}
            addLine={addLine}
            deleteLine={deleteLine}
            documentDate={documentDate}
            filterArticles={filterArticles}
            filterNames={filterNames}
            onChangeName={onChangeName}
            onChangePos={onChangePos}
            setDocumentNumber={setDocumentNumber}
            emptyLine={emptyLine}
            scrollPlus={scrollPlus}
            typeNames={typeNames}
            provided={form.provided}
            storage={storage}
            setStorage={setStorage}
            storages={storages}
            employee={employee}
            setEmployee={setEmployee}
            addressList={addressList}
            addressListAll={AddressList}
            setDocumentDate={setDocumentDate}
            storageChecker={storageChecker}
            sortFn={sortFn}
            sortName={sorted}
          />
        )}
        {type === "Поступление" && lines.length && (
          <FormSupplies
            groups={groups}
            documentNumber={documentNumber}
            lines={lines}
            documentDate={documentDate}
            filterArticles={filterArticles}
            filterNames={filterNames}
            onChangeName={onChangeName}
            onChangePos={onChangePos}
            scrollPlus={scrollPlus}
            provided={form.provided}
            storage={storage}
            setStorage={setStorage}
            storages={storages}
            employee={employee}
            setEmployee={setEmployee}
            addressList={addressList}
            setDocumentDate={setDocumentDate}
            setAllAddress={setAllAddress}
            storageChecker={storageChecker}
            sortFn={sortFn}
            sortName={sorted}
          />
        )}
      </div>

      <Modal isActive={modalIsActive} setIsActive={setModalIsActive}>
        {(form.done === 0 || !form.id) && userLevel > 1 ? (
          <>
            <textarea
              placeholder="Примечание"
              onChange={(e) => setComment(e.target.value)}
            >
              {comment}
            </textarea>
            <input type="file" name="files[]" onChange={attachFile} multiple />
          </>
        ) : (
          <p>{comment}</p>
        )}
        {files.map((file) => (
          <div className="fileIconGroup">
            {(form.done === 0 || !form.id) && (
              <span
                className="material-icons remove"
                title="Удалить"
                onClick={() => removeFile(file.url)}
              >
                cancel
              </span>
            )}
            <span
              className="material-icons file"
              onClick={() =>
                file.ext === "pdf"
                  ? window.open(file.url, "_blank")
                  : router.push(file.url).then(() => router.push(router.asPath))
              }
            >
              description
            </span>
            <span
              className="name"
              onClick={() =>
                file.ext === "pdf"
                  ? window.open(file.url, "_blank")
                  : router.push(file.url).then(() => router.push(router.asPath))
              }
            >
              {file.name}
            </span>
          </div>
        ))}
      </Modal>

      <Preloader isActive={isPreloaderActive} />
    </>
  );
};

export default Form;
