
import {useEffect, useContext} from "react";

import HistoryTable from "components/archive/HistoryTable";

import {AppContext} from "providers/AppContextProvider";

const Index = ({children}) => {
	const {setCrumbs} = useContext(AppContext);
	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: "Архивы", url: "/archive"},
			{name: "История", url: ""}
		]);
	}, []);
	
	return (
		<>

			<title>Архив | истории</title>

			{children}

			<div className="cabinetMain">
				<HistoryTable />
			</div>
		</>
	);
};

export default Index;
