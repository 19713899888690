import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";
import { doc } from "constants/endpoints";

const fetchRealizations = createAsyncThunk("realizations/getRealizations", async () => {
  const response = await fetcher(doc.REALIZATIONS);
  return response.json();
});

const fetchFactories = createAsyncThunk("factory/all", async () => {
  const response = await fetcher(doc.FACTORIES);
  return response.json();
});

const fetchFactory = createAsyncThunk("factory/getFactory", async (id) => {
  const response = await fetcher(`${doc.FACTORY}/${id}`);
  return response.json();
});

const initialState = {
  realizations: {
    status: loadingStatus.IDLE,
    data: [],
    error: null,
  },
  factories: {
    status: loadingStatus.IDLE,
    data: [],
    error: null,
  },
  factory: {
    status: loadingStatus.IDLE,
    data: {},
    error: null,
  },
};

export const docSlice = createSlice({
  name: "doc",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchRealizations.pending, (state) => {
        state.realizations.status = loadingStatus.LOADING;
      })
      .addCase(fetchRealizations.fulfilled, (state, action) => {
        state.realizations.status = loadingStatus.SUCCEEDED;
        state.realizations.data = action.payload.result;
      })
      .addCase(fetchRealizations.rejected, (state, action) => {
        state.realizations.status = loadingStatus.FAILED;
        state.realizations.error = `${action.error.name}: ${action.error.message}`;
      })

      .addCase(fetchFactories.pending, (state) => {
        state.factories.status = loadingStatus.LOADING;
      })
      .addCase(fetchFactories.fulfilled, (state, action) => {
        state.factories.status = loadingStatus.SUCCEEDED;
        state.factories.data = action.payload.result;
      })
      .addCase(fetchFactories.rejected, (state, action) => {
        state.factories.status = loadingStatus.FAILED;
        state.factories.error = `${action.error.name}: ${action.error.message}`;
      })

      .addCase(fetchFactory.pending, (state) => {
        state.factory.status = loadingStatus.LOADING;
      })
      .addCase(fetchFactory.fulfilled, (state, action) => {
        state.factory.status = loadingStatus.SUCCEEDED;
        state.factory.data = action.payload.result;
      })
      .addCase(fetchFactory.rejected, (state, action) => {
        state.factory.status = loadingStatus.FAILED;
        state.factory.error = `${action.error.name}: ${action.error.message}`;
      })
  }
});

export {
  fetchRealizations,
  fetchFactories,
  fetchFactory,
};
