import {useState} from 'react';

import DocumentsListItem from 'components/documents-list-item/documents-list-item';
import Preloader from 'components/preloader/SpinPlaceholderComponent';

import styles from './styles.module.scss';

const DocumentsListItems = ({documentsListItems}) => {
  // Активность прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

  // const sortedByNumberDocuments = structuredClone(documentsListItems).sort((a, b) => Number(b.document_number.match(/\d+/)[0]) - Number(a.document_number.match(/\d+/)[0]));

  const sortedByDateDocuments = structuredClone(documentsListItems)
    .sort((currentDocument, nextDocument) => {
      const currentItemDate = new Date(
        ...currentDocument.document_date.split(' ').slice(0, -1).join('').split('.').reverse() // дата
      );
      const nextItemDate = new Date(
        ...nextDocument.document_date.split(' ').slice(0, -1).join('').split('.').reverse() // дата
      );

      const currentItemNumber = (/\d+/).test(currentDocument.document_number) ? Number(currentDocument.document_number.match(/\d+/)[0]) : 0;
		  const nextItemNumber = (/\d+/).test(nextDocument.document_number) ? Number(nextDocument.document_number.match(/\d+/)[0]) : 0;

      const currentItemCopyNumber = currentDocument.document_number.split(' ')[2] ? Number(currentDocument.document_number.split(' ')[2]) : 0;
      const nextItemCopyNumber = nextDocument.document_number.split(' ')[2] ? Number(nextDocument.document_number.split(' ')[2]) : 0;

      if (nextItemDate.getTime() === currentItemDate.getTime()) {
        if (nextItemNumber === currentItemNumber) {
          return nextItemCopyNumber - currentItemCopyNumber;
        } else {
          return nextItemNumber - currentItemNumber;
        }
      } else {
        return nextItemDate - currentItemDate;
      }
    });

  return (
    <>
      <Preloader isActive={isPreloaderActive}/>

      <table className={styles.table}>
        <thead>
          <tr>
            <th>Системный №</th>
            <th>Тип</th>
            <th>Дата в Системе</th>
            <th>Склад</th>
            <th/>
            <th/>
          </tr>
        </thead>
        <tbody>
          {
            sortedByDateDocuments.map((document, index) => (
              <DocumentsListItem
                key={index}
                document={document}
                setIsPreloaderActive={setIsPreloaderActive}
              />
            ))
          }
        </tbody>
      </table>
    </>
  );
};

export default DocumentsListItems;
