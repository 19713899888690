import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";
import { Map } from "constants/endpoints";

const fetchMaps = createAsyncThunk("maps", async () => {
  const response = await fetcher(Map.GET_ALL_MAPS);
  return response.json();
});

const fetchMap = createAsyncThunk("maps/id", async (id) => {
  const response = await fetcher(`${Map.GET_MAP}/${id}`);
  return response.json();
});

const fetchMapFields = createAsyncThunk("map/getAllFields", async () => {
  const response = await fetcher(Map.GET_ALL_FIELDS);
  return response.json();
});

const fetchMapAddresses = createAsyncThunk("map/getAllAddresses", async () => {
  const response = await fetcher(Map.GET_ALL_ADDRESSES);
  return response.json();
});

const saveMap = async (data) => {
  const response = await fetcher(Map.ADD, {
    method: "POST",
    body: JSON.stringify(data),
  });
  return response;
}

// Отправка скриншота карты
const postMapScreenShot = createAsyncThunk(
  'map/editThumb',
  async (arg, {dispatch, extra: api}) => {
    const {data} = await api.post(`${Map.EDIT_THUMB}/${arg.id}`, arg.body);
    return data;
  },
);

// const deletePlayer = async (id) => await fetcher(`${players.ALL}/${id}`, { method: "DELETE" });

// const requestLogs = async id => await fetcher(`${players.DEVICE}/${id}/logs/request`, { method: "POST" });

const initialState = {
  maps: {
    status: loadingStatus.IDLE,
    data: [],
    error: null,
  },
  map: {
    status: loadingStatus.IDLE,
    data: {},
  },
  mapFields: {
    status: loadingStatus.IDLE,
    data: [],
  },
  mapAddresses: {
    status: loadingStatus.IDLE,
    data: [],
  }
};

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMaps.pending, (state) => { state.maps.status = loadingStatus.LOADING; })
      .addCase(fetchMaps.fulfilled, (state, action) => {
        state.maps.status = loadingStatus.SUCCEEDED;
        state.maps.data = action.payload.result;
      })
      .addCase(fetchMaps.rejected, (state, action) => {
        state.maps.status = loadingStatus.FAILED;
        state.maps.error = `${action.error.name}: ${action.error.message}`;
      })

      .addCase(fetchMap.pending, (state) => { state.map.status = loadingStatus.LOADING; })
      .addCase(fetchMap.fulfilled, (state, action) => {
        state.map.status = loadingStatus.SUCCEEDED;
        const map = action.payload.result;
        // Сортировка полей карты по складам
        map.fields = map.fields.sort((a, b) => {
          if(a.storage === b.storage) return 0;
          else return a.storage > b.storage ? 1 : -1;
        });
        state.map.data = map;
      })

      .addCase(fetchMapFields.pending, (state) => { state.mapFields.status = loadingStatus.LOADING; })
      .addCase(fetchMapFields.fulfilled, (state, action) => {
        state.mapFields.status = loadingStatus.SUCCEEDED;
        state.mapFields.data = action.payload.result;
      })

      .addCase(fetchMapAddresses.pending, (state) => { state.mapAddresses.status = loadingStatus.LOADING; })
      .addCase(fetchMapAddresses.fulfilled, (state, action) => {
        state.mapAddresses.status = loadingStatus.SUCCEEDED;
        state.mapAddresses.data = action.payload.result;
      })
  }
});

// export const { postAdded, postUpdated, reactionAdded } = playerSlice.actions;
export {
  fetchMaps,
  fetchMap,
  fetchMapFields,
  fetchMapAddresses,
  saveMap,
  postMapScreenShot
};
