import { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { AppContext } from "providers/AppContextProvider";
import SpinPlaceholderComponent from "components/preloader/SpinPlaceholderComponent";
import MapEditComponent from "components/map/MapEdit";
import { fetchMap, fetchMapFields } from "redux/slices/mapSlice";
import { loadingStatus } from "helpers/fetcher";

const Mid = ({ children }) => {
	const { alert, setCrumbs } = useContext(AppContext);
	const dispatch = useDispatch();
	const { map, mapFields } = useSelector((state) => state.map);
  const { id } = useParams();

	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: map.data.name, url: "/map/" + id},
			{name: "Редактирование", url: ""}
		]);
	}, [map.data, id]);

	useEffect(() => { dispatch(fetchMap(id)); }, [id]);

	useEffect(() => {
    if (map.status === loadingStatus.SUCCEEDED) {
      dispatch(fetchMapFields());
    }
	}, [map.status]);


	return (
		<>
			<title>Редактирование {map.name}</title>
			
			{children}

			{[loadingStatus.IDLE, loadingStatus.LOADING].includes(map.status) ?
        <SpinPlaceholderComponent isActive isSmall /> :
        <MapEditComponent alert={alert} map={map} allFields={mapFields}/>
      }
		</>
	);
};

export default Mid;