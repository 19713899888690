import {createSlice} from '@reduxjs/toolkit';

import {fetchSuppliesAsyncAction, fetchSupplyDetailsAsyncAction} from './supplies-api-actions';

const initialState = {
  supplies: [],
  supply: {
    supply_id: '',
    author: '',
    comment: '',
    document_date: '',
    document_number: '',
    done: undefined,
    employee: '',
    provided: '',
    storage: '',
    form: [],
  },
  commentFiles: []
};

export const supplies = createSlice({
  name: 'supplies',
  initialState,
  reducers: {
    setSupplyCommentAction: (state, action) => {
      state.supply.comment = action.payload;
    },
    setCommentFilesAction: (state, action) => {
      state.commentFiles = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSuppliesAsyncAction.fulfilled, (state, action) => {
        state.supplies = action.payload.result;
      })
      .addCase(fetchSupplyDetailsAsyncAction.fulfilled, (state, action) => {
        state.supply = action.payload.result;
      });
  }
});

export const {
  setSupplyCommentAction,
  setCommentFilesAction
} = supplies.actions;
