import {getToken, removeToken} from "./storage";
import {AppRoute} from "constants/routes";

const loadingStatus = {
  IDLE: "idle",
  LOADING: "loading",
  SUCCEEDED: "succeeded",
  FAILED: "failed",
};

const getHeaders = () => {
  const accessToken = getToken();
  return accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
};

const SERVER_URL = process.env.REACT_APP_SERVER_ENTRYPOINT;

const fetcher = async (endpoint, options = {}) => {
  try {
    const response = await fetch(`${SERVER_URL}${endpoint}`, {
      headers: getHeaders(),
      ...options,
    });

    if (response.status === 401) {
      removeToken();
      window.location = AppRoute.Auth.SIGN_IN;
    }

    return response;
  } catch (e) {
    alert(`Fatal error from fetcher: ${e.toString()}`);
  }
  return false;
};

export default fetcher;

export {
  SERVER_URL,
  getHeaders,
  loadingStatus,
};
