import {createSlice} from '@reduxjs/toolkit';

import {signInAsyncAction, validateTokenAsyncAction} from './auth-process-api-actions';

import {AuthStatus} from 'constants/auth-status';

const initialState = {
  authorizationStatus: AuthStatus.UNKNOWN
};

export const authProcess = createSlice({
  name: 'authProcess',
  initialState,
  reducers: {
    setAuthStatus: (state, action) => {
      state.authorizationStatus = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(signInAsyncAction.fulfilled, (state) => {
        state.authorizationStatus = AuthStatus.AUTH;
      })
      .addCase(signInAsyncAction.rejected, (state) => {
        state.authorizationStatus = AuthStatus.NO_AUTH;
      })
      .addCase(validateTokenAsyncAction.fulfilled, (state) => {
        state.authorizationStatus = AuthStatus.AUTH;
      })
      .addCase(validateTokenAsyncAction.rejected, (state) => {
        state.authorizationStatus = AuthStatus.NO_AUTH;
      });
  }
});

export const {
  setAuthStatus
} = authProcess.actions;
