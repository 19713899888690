import Modal from "components/Modal";

import style from './styles.module.scss';

const CheckValuesModal = ({
  data,
  isActive,
  setIsActive
}) => {
  const handleCopyEmptyLinesButtonClick = () => {
    navigator.clipboard.writeText(Object.keys(data).join(' '));
  };

  const CopyEmptyRowsListButton = () => (
    <span
      className="roundIcon material-icons"
      onClick={handleCopyEmptyLinesButtonClick}
      title="Скопировать строки"
    >
      copy
    </span>
  );

  return (
    <Modal isActive={isActive} setIsActive={setIsActive}>
      <div className={style.wrapper}>
        <h3 className={style.title}>
          {'Не заполнены следующие строки: '}
          <CopyEmptyRowsListButton />
        </h3>
        <table className={style.table}>
          <tr className={style.row}>
            <td className={style.cell}>Номер строки</td>
            <td className={style.cell}>Наименование</td>
            <td className={style.cell}>Адрес</td>
            <td className={style.cell}>Кол-во</td>
          </tr>
          {
            Object.keys(data).map((itemNumber) => (
              <tr className={style.row}>
                <td className={style.cell}>
                  {`${itemNumber} `}
                </td>
                <td className={style.cell}>
                  {
                    data[itemNumber].goodNotSelected
                      ? (<span>Не выбрано наименование</span>)
                      : data[itemNumber].item.name
                  }
                </td>
                <td className={style.cell}>
                  {
                    data[itemNumber].addressNotSelected && (
                      <span>Не выбран</span>
                    )
                  }
                  {
                    data[itemNumber].addressNotInList && (
                      <span>Неверный</span>
                    )
                  }
                </td>
                <td  className={style.cell}>
                  {
                    data[itemNumber].amountNotInserted && (
                      <span>Не введено</span>
                    )
                  }
                  {
                    data[itemNumber].amountNotNumeric && (
                      <span>Не число</span>
                    )
                  }
                  {
                    data[itemNumber].negativeAmountValue && (
                      <span>Должно быть больше 0</span>
                    )
                  }
                </td>
              </tr>
            ))
          }
        </table>
      </div>
    </Modal>
  );
};

export default CheckValuesModal;
