import {useContext, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import axios from "axios";

import cl from "styles/components/MapPanel.module.scss";

import CustomSelect from "../CustomSelect";

import {AppContext} from 'providers/AppContextProvider';

const MapPanelEditFieldItem = ({
  field,
  index,
  storages,
  fields,
  setFields,
  panelFields,
  setPanelFields,
  allowEdit
}) => {
  const navigate = useNavigate();

  const {alert} = useContext(AppContext);

  const [isFieldBeingEdited, setIsFieldBeingEdited] = useState(false);

  const mapData = useSelector((state) => state.map).map.data;

  // Изменение наименования поля
	const editFieldName = (index, value) => {
		const copiedPanelFields = structuredClone(panelFields);
		copiedPanelFields[index].name = value;
    setPanelFields(copiedPanelFields);

    const copiedFields = structuredClone(fields);
    const fieldIndex = fields.findIndex((field) => field.id === panelFields[index].id);
		copiedFields[fieldIndex].name = value;
    setFields(copiedFields);
	}

  const handleFieldNameInputChange = (evt) => {
    editFieldName(index, evt.currentTarget.value);
  };

  // Добавление склада к полю
	const setFieldStorage = (index, _name, value) => {
		axios.post("fields/setFieldStorage/" + panelFields[index].field_id, {
			storage: value
		}).then(() => {
			const copy = structuredClone(panelFields);
			copy[index].storage = value;
			setPanelFields(copy);
		}).catch(() => {
			alert("Ошибка прикрепления склада", "danger");
		});
	}

  // Отправка данных отредактированного поля на сервер
  const sendFieldsData = (copiedFields) => {
    axios.put("/fields/updateFieldName/" + panelFields[index].field_id, {
			name: panelFields[index].name
		}).then(() => {
			axios.post("/map/setFields/" + mapData.id, {
				fields: copiedFields
			});
		}).catch(error => {
			if(error.response.data) {
        alert(error.response.data.message, "danger");
      } else {
        alert("Ошибка: проблемы с подключением", "danger");
      }

			// copiedFields[fields.indexOf(panelFields[index])].isEditing = true;
		});
  };

  const setFieldBeingEditedStatus = (isBeingEdited) => {
    setIsFieldBeingEdited(isBeingEdited);

    const copiedFields = structuredClone(fields);

    const fieldIndex = fields.findIndex((field) => field.id === panelFields[index].id);

    copiedFields[fieldIndex] = {
			...copiedFields[fieldIndex],
      isEditing: isBeingEdited,
		};
		setFields(copiedFields);

    return copiedFields;
  };

  const handleEditFieldDataButtonClick = () => {
    setFieldBeingEditedStatus(true);
  };

  // Установка флага редактирования поля и отправка новых данных по полю
	const handleSaveFieldDataButtonClick = () => {
    const copiedFields = setFieldBeingEditedStatus(false);

    sendFieldsData(copiedFields);
	}

  // Удаление поля
	const removeField = (index) => {
		alert(panelFields[index].count > 0 ? "На поле есть данные - удалить?" : "Удалить поле?", "danger", 10000, [
			{
				text: "Да",
				handler: function(){
					const copy = structuredClone(panelFields);
					const fieldId = copy[index].field_id;
					copy.splice(index, 1);
					setFields(copy);
					setFields(fields.filter(field => field.field_id !== fieldId));
          setPanelFields(fields.filter(field => field.field_id !== fieldId));
					
					axios.post("map/setFields/" + mapData.id, {
						fields: fields.filter(field => field.field_id !== fieldId)
					}).then(() => {
						axios.delete("map/deleteField/" + fieldId).then(() => {
							alert("Успешно удалено", "success");
						});
					}).catch(() => {
						alert("Ошибка: проблемы с подключением", "danger");
					});
				}
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "default", 1);
				}
			}
		]);
	}

  return (
    <div>
      {
        (index === 0 || panelFields[index - 1].storage !== field.storage) && (
          <div className={cl.mapName}>
            {field.storage}
          </div>
        )
      }
      <div className={cl.field}>
        <div className={cl.fieldInfo}>
          <div className={cl.info}>
            <span className={cl.fieldName}>
              {
                isFieldBeingEdited
                  ? (
                      <input
                        type="text" value={field.name}
                        onChange={handleFieldNameInputChange}
                        placeholder="Название поля"
                      />
                    )
                  : field.name
              }
              {
                !allowEdit && (
                  <span
                    className="material-icons"
                    title="Открыть"
                    onClick={() => navigate("/map/" + mapData.id + "/fields/" + field.id)}
                  >
                    launch
                  </span>
                )
              }
            </span>
            <span>Длина: {field.length}м</span>
            {
              allowEdit && (
                <>
                  <span>Склад: {field.storage}</span>
                  {isFieldBeingEdited &&
                    <CustomSelect
                      options={storages.filter(st => st.name !== field.storage)}
                      name="storages"
                      currentValue=""
                      currentIndex={index}
                      changeHandler={setFieldStorage}
                      placeholder="Изменить склад"
                      eraseValue={true}
                    />
                  }
                </>
              )
            }
          </div>
        </div>
        
        <div className={cl.controls}>
          {
            allowEdit && (
              <>
                <span
                  className="material-icons roundIcon dangerIcon"
                  title="Удалить"
                  onClick={() => removeField(index)}
                >
                  close
                </span>
                {
                  isFieldBeingEdited
                    ? (
                      <span
                        className="material-icons roundIcon successIcon"
                        title="Сохранить изменения"
                        onClick={handleSaveFieldDataButtonClick}
                      >
                        done
                      </span>
                    )
                    : (
                      <span
                        className="material-icons roundIcon"
                        title="Редактировать"
                        onClick={handleEditFieldDataButtonClick}
                      >
                        edit
                      </span>
                    )
                }
              </>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default MapPanelEditFieldItem;
