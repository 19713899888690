import {useContext, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {
  fetchDocumentsAsyncAction,
  fetchDocumentsNumbersAsyncAction
} from 'redux/slices/documents/documents-api-actions';
import {getDocumentsList} from 'redux/slices/documents/selectors';

import ListLegend from 'components/list-legend/list-legend';
import PostingsListButtonsBlock from 'components/postings-list-buttons-block/postings-list-buttons-block';
import DocumentsListItems from 'components/documents-list-items/documents-list-items';

import {DocumentTypeNameMap, DocumentType} from 'constants/document-type';
import {AppRoute} from 'constants/routes';

import {AppContext} from 'providers/AppContextProvider';
import {getUser} from 'helpers/storage';

import styles from './styles.module.scss';

const PostingsList = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const LOCATION_REG_EXP = /archive/;
  const pathName = location.pathname;
  const isInArchive = LOCATION_REG_EXP.test(pathName);

  // Проверка уровня пользователя
  const canUserUpdate = getUser().accessLevel > 1;

  const {setCrumbs} = useContext(AppContext);

  const documentsListItems = useSelector(getDocumentsList);

  // Запрос списка документов оприходования для отрисовки в основном списке документов
  useEffect(() => {
    dispatch(fetchDocumentsAsyncAction({
      isInArchive,
      documentType: DocumentTypeNameMap[DocumentType.POSTING]
    }));
  }, [dispatch, isInArchive]);

  // Запрос списка номеров документов оприходования для генерации номера нового документа
  // Запрос учитывает все номера, существующие в БД - и основные, и архивные
  useEffect(() => {
    if (!isInArchive && canUserUpdate) {
      dispatch(fetchDocumentsNumbersAsyncAction(DocumentTypeNameMap[DocumentType.POSTING]));
    }
  }, []);

  // Запись хлебных крошек
  useEffect(() => {
    const crumbs = isInArchive ? [
      {name: 'Архивы', url: AppRoute.Archive.ALL},
      {name: DocumentTypeNameMap[DocumentType.POSTING], url: AppRoute.Archive.POSTINGS}
    ] : [
      {name: DocumentTypeNameMap[DocumentType.POSTING], url: AppRoute.Document.POSTINGS}
    ];

    setCrumbs(crumbs);
  }, [isInArchive]);

  return (
    <>
      <div className={styles.wrapper}>
        <ListLegend documentType={DocumentTypeNameMap[DocumentType.POSTING]}/>
        {
          !isInArchive && (
            <PostingsListButtonsBlock/>
          )
        }
        <DocumentsListItems documentsListItems={documentsListItems}/>
      </div>
    </>
  );
};

export default PostingsList;
