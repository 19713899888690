
import {useEffect} from "react";
import cl from "styles/pages/index.module.scss";
import Card from "components/CardComponent";

const Index = ({setCrumbs, userLevel}) => {
	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([{name: "Инструкции", url: ""}]);
	}, []);
	
	return (
		<>
			
				<title>Инструкции</title>
			
			
			<div className={cl.cardContainer + " container"}>
				<div className="row">
					{userLevel() > 1 &&
						<>
							<h2 className="faq">Движения товаров</h2>
							
							<div className="col-sm-4">
								<Card name="Инвентаризация" image="/img/inventory/inventory-icon.svg" buttons={[
									{
										text: "Читать",
										handler: function(){
											window.open("/faq/inventory", "_blank");
										}
									}
								]}/>
							</div>
							
							<div className="col-sm-4">
								<Card name="Оприходование" image="/img/forms/form-icon.svg" buttons={[
									{
										text: "Читать",
										handler: function(){
											window.open("/faq/posting", "_blank");
										}
									}
								]}/>
							</div>
							
							<div className="col-sm-4">
								<Card name="Отгрузка" image="/img/realizations/realization-icon.svg" buttons={[
									{
										text: "Читать",
										handler: function(){
											window.open("/faq/realization", "_blank");
										}
									}
								]}/>
							</div>
							
							<div className="col-sm-4">
								<Card name="Перемещение" image="/img/forms/shifting-icon.svg" buttons={[
									{
										text: "Читать",
										handler: function(){
											window.open("/faq/shifting", "_blank");
										}
									}
								]}/>
							</div>
							
							<div className="col-sm-4">
								<Card name="Поступление" image="/img/supplies/supply-icon.svg" buttons={[
									{
										text: "Читать",
										handler: function(){
											window.open("/faq/supply", "_blank");
										}
									}
								]}/>
							</div>
							
							<div className="col-sm-4">
								<Card name="Производство" image="/img/factory/factory-icon.svg" buttons={[
									{
										text: "Читать",
										handler: function(){
											window.open("/faq/factory", "_blank");
										}
									}
								]}/>
							</div>
							
							<div className="col-sm-4">
								<Card name="Списание" image="/img/forms/charge-icon.svg" buttons={[
									{
										text: "Читать",
										handler: function(){
											window.open("/faq/charge", "_blank");
										}
									}
								]}/>
							</div>
						</>
					}
					
					<h2 className="faq">Навигация</h2>
					
					<div className="col-sm-4">
						<Card name="Навигация" image="/img/faq/navigation-icon.svg" buttons={[
							{
								text: "Читать",
								handler: function(){
									window.open("/faq/navigation", "_blank");
								}
							}
						]}/>
					</div>
					
					<div className="col-sm-4">
						<Card name="Личный кабинет" image="/img/faq/cabinet-icon.svg" buttons={[
							{
								text: "Читать",
								handler: function(){
									window.open("/faq/cabinet", "_blank");
								}
							}
						]}/>
					</div>
					
					{userLevel() > 2 &&
						<div className="col-sm-4">
							<Card name="Система (для админов)" image="/img/faq/admin-icon.svg" buttons={[
								{
									text: "Читать",
									handler: function(){
										window.open("/faq/admin", "_blank");
									}
								}
							]}/>
						</div>
					}
					
					<h2 className="faq">Журналы</h2>
					
					<div className="col-sm-4" style={{marginTop: "2rem"}}>
						<Card name="Журнал поля" image="/img/faq/field-icon.svg" buttons={[
							{
								text: "Читать",
								handler: function(){
									window.open("/faq/field", "_blank");
								}
							}
						]}/>
					</div>
					
					<div className="col-sm-4" style={{marginTop: "2rem"}}>
						<Card name="Сводный журнал" image="/img/faq/journalAmount-icon.svg" buttons={[
							{
								text: "Читать",
								handler: function(){
									window.open("/faq/journalAmount", "_blank");
								}
							}
						]}/>
					</div>
					
					<h2 className="faq">Отчеты</h2>
					
					<div className="col-sm-4" style={{marginTop: "2rem"}}>
						<Card name="Архивы" image="/img/inventory/inventory-icon2.svg" buttons={[
							{
								text: "Читать",
								handler: function(){
									window.open("/faq/archive", "_blank");
								}
							}
						]}/>
					</div>
					
					<div className="col-sm-4" style={{marginTop: "2rem"}}>
						<Card name="История" image="/img/history/history-icon.svg" buttons={[
							{
								text: "Читать",
								handler: function(){
									window.open("/faq/history", "_blank");
								}
							}
						]}/>
					</div>
					
					<div className="col-sm-4" style={{marginTop: "2rem"}}>
						<Card name="Отчет Расхождения" image="/img/faq/journalSpread-icon.svg" buttons={[
							{
								text: "Читать",
								handler: function(){
									window.open("/faq/journalSpread", "_blank");
								}
							}
						]}/>
					</div>
				</div>
			</div>
		</>
	);
};

export default Index;