import {createAsyncThunk} from '@reduxjs/toolkit';
import {User} from 'constants/endpoints';
import {saveToken} from 'helpers/storage';

export const validateTokenAsyncAction = createAsyncThunk(
  'auth/validateToken',
  async (_args, {extra: api}) => {
    await api.get(User.CHECK_AUTH);
  }
);

export const signInAsyncAction = createAsyncThunk(
  'auth/getToken',
  async (credentials, {extra: api}) => {
    const {data} = await api.post(User.LOGIN, credentials);
    saveToken(data.access_token);

    return data;
  }
);

export const signUpAsyncAction = createAsyncThunk(
  'auth/register',
  async (registerData, {extra: api}) => {
    const {data} = await api.post(User.REGISTER, registerData);

    return data;
  }
);
