const AddNewLineButton = ({title, addLine}) => {
  return (
    <tr className="adding">
      <td className="iconed">
        <span
          className="roundIcon material-icons"
          onClick={addLine}
          title={title}
        >
          add
        </span>
      </td>
    </tr>
  );
};

export default AddNewLineButton;
