import {Fragment, useContext} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {toast} from 'react-toastify';

import CustomSearchSelect from 'components/custom-search-select/custom-search-select';

import {setNewDocumentInitialAmountsAsyncAction} from 'redux/slices/documents/documents-api-actions';
import {setDocumentItemsAction} from 'redux/slices/documents/documents';
import {getDocument, getProvidedStatus} from 'redux/slices/documents/selectors';

import {AppContext} from 'providers/AppContextProvider';
import {getUser} from 'helpers/storage';
import {sortStringValues} from 'helpers/utils';

import styles from './styles.module.scss';

const PostingsTableRow = ({
  line,
  lineIndex,
  goods,
  groups,
  addresses,
  storageChecker,
  deleteLine
}) => {
  const dispatch = useDispatch();

  const {alert} = useContext(AppContext);

  // Проверка уровня пользователя
  const canUserUpdate = getUser().accessLevel > 1;

  const document = useSelector(getDocument);
  const provided = !!useSelector(getProvidedStatus);
  const storage = document.storage;
  const documentItems = document.documentItems;
  const itemsNames = structuredClone(documentItems).map((itm) => itm.name);

  // Получить список наименований для селекта для вновь добавленной строки (в самом низу)
  const filterSelectComponentOptions = (selectComponentName) => {
    // Отсеиваем те товары, которые уже есть в инвентаризации
    const filteredPlants = goods
      .filter((plant) => !itemsNames.includes(plant.name))
      .sort((a, b) => {
        const res = sortStringValues(a.value, b.value);
        return res;
      });
    const filteredArticles = filteredPlants.map((plant) => ({
      id: plant.b_group_id,
      name: plant.article,
      value: plant.article
    }));

    // Если в новой строке группа уже выбрана, то фильтруем список для селекта ещё и по группе
    if (line.b_group && groups.length) {
      const groupId = groups.find((gr) => gr.name === line.b_group).id;
      const filteredByGroupPlants = filteredPlants
        .filter((plant) => plant.b_group_id === groupId)
        .sort((a, b) => {
          const res = sortStringValues(a.value, b.value);
          return res;
        });
      const filteredByGroupArticles = filteredByGroupPlants.map((plant) => ({
        id: plant.b_group_id,
        name: plant.article,
        value: plant.article
      }));

      switch(selectComponentName) {
        case 'name':
          return filteredByGroupPlants;
        case 'article':
          return filteredByGroupArticles;
        default:
          return;
      };
    }

    switch(selectComponentName) {
      case 'name':
        return filteredPlants;
      case 'article':
        return filteredArticles;
      default:
        return;
    };
  };

  // Метод изменения наименования и артикула (и группы, если не выбрана)
  const changeValue = (index, selectElementName, value) => {
    const copiedLines = structuredClone(documentItems);

    copiedLines[index][selectElementName] = value;

    if (selectElementName === 'name') {
      const plant = goods.find((plant) => plant.name === value);
      const plantArticle = plant.article;
      const businessGroupName = groups.find((group) => group.id === plant.b_group_id).name;
      const plantBruGoodId = plant.good_id;

      copiedLines[index].article = plantArticle;
      copiedLines[index].b_group = businessGroupName;
      copiedLines[index].good_id = plantBruGoodId;
    }
    if (selectElementName === 'article') {
      const plant = goods.find((plant) => plant.article === value);
      const plantName = plant.name;
      const businessGroupName = groups.find((group) => group.id === plant.b_group_id).name;
      const plantBruGoodId = plant.good_id;

      copiedLines[index].name = plantName;
      copiedLines[index].b_group = businessGroupName;
      copiedLines[index].good_id = plantBruGoodId;
    }

    dispatch(setDocumentItemsAction(copiedLines));

    // Если товар выбран (наименование определено), то запрашиваем данные с полей
    if (copiedLines[index].name) {
      dispatch(setNewDocumentInitialAmountsAsyncAction({
        name: copiedLines[index].name,
        storage
      }));
    }
  };

  // Обработчик выбора адреса
  const handleAddressChange = (positionIndex, address) => {
    const copiedLines = structuredClone(documentItems);

    copiedLines[lineIndex].userPositions[positionIndex].address = address;

    dispatch(setDocumentItemsAction(copiedLines));
  };

  // Изменение позиций
  const handlePositionDataInputChange = (indexes, value) => {
    if (documentItems[indexes.lineIndex].name) {
      const copy = structuredClone(documentItems);

      copy[indexes.lineIndex].userPositions[indexes.positionIndex].amount = value;

      dispatch(setDocumentItemsAction(copy));
    } else {
      toast.error('Выберите наименование');
    }
  };

  // Удаление подстроки (поле/площадка)
  const deleteSubstring = (positionIndex) => {
    const copiedLines = structuredClone(documentItems);

    copiedLines[lineIndex].userPositions.splice(positionIndex, 1);
    // copiedLines[lineIndex].amounts.splice(positionIndex, 1);

    dispatch(setDocumentItemsAction(copiedLines));
  };

  // Обработчик удаления подстроки (поле/площадка)
  const handleDeleteSubstringButtonClick = (positionIndex) => {
    const handler = () => {
      deleteSubstring(positionIndex);
    };

    alert('Удалить площадку?', 'danger', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  //
  const addSubString = (lineIndex) => {
    const copiedLines = structuredClone(documentItems);

    copiedLines[lineIndex].userPositions.push({
      amount: '',
      address: ''
    });
    copiedLines[lineIndex].amounts.push({
      amount: '',
      address: ''
    });

    dispatch(setDocumentItemsAction(copiedLines));
  };

  return (
    <Fragment>
      {
        line.userPositions.map((position, positionIndex, userPositions) => (
          <tr key={positionIndex} className={line.isError ? styles.error : ''}>
            {
              positionIndex === 0 && (
                <>
                  {/* Ячейка № п/п */}
                  <td rowSpan={userPositions.length}>{lineIndex + 1} ({line.good_id})</td>

                  {/* Ячейка "Наименование" */}
                  <td
                    rowSpan={userPositions.length}
                    className={styles.name}
                    onClick={storageChecker}
                  >
                    {
                      !provided && !line.name ? (
                        <CustomSearchSelect
                          inputName='name'
                          defaultValue={line.name}
                          options={filterSelectComponentOptions('name')}
                          onChange={(value) => changeValue(lineIndex, 'name', value)}
                          placeholder='Наименование'
                        />
                      ) : (
                        line.name
                      )
                    }
                  </td>

                  {/* Ячейка "Артикул" */}
                  <td rowSpan={userPositions.length} onClick={storageChecker}>
                    {
                      !provided && !line.article ? (
                        <CustomSearchSelect
                          inputName='article'
                          defaultValue={line.article}
                          options={filterSelectComponentOptions('article')}
                          onChange={(value) => changeValue(lineIndex, 'article', value)}
                          placeholder='Артикул'
                        />
                      ) : (
                        line.article
                      )
                    }
                  </td>

                  {/* Ячейка "Группа" */}
                  <td rowSpan={userPositions.length} onClick={storageChecker}>
                    {
                      !provided && !line.name ? (
                        <CustomSearchSelect
                          inputName='b_group'
                          defaultValue={line.b_group}
                          options={groups}
                          onChange={(value) => changeValue(lineIndex, 'b_group', value)}
                          placeholder='Группа'
                        />
                      ) : (
                        line.b_group
                      )
                    }
                  </td>

                  {/* Ячейка общего кол-ва товара на выбранном складе "Всего" */}
                  <td rowSpan={userPositions.length} className={styles.right}>
                    {new Intl.NumberFormat('ru-RU').format(line.totalAmount)}
                  </td>
                </>
              )
            }

            {/* Кол-во и адрес из БД - реальное кол-во на полях/площадках */}
            <td className={styles.right}>
              {new Intl.NumberFormat('ru-RU').format(line.amounts[positionIndex].amount)}
            </td>
            <td className={styles.address}>{line.amounts[positionIndex].address}</td>

            {/* Ячейка ввода оприходуемого кол-ва */}
            <td className={styles.right}>
              {
                !provided && canUserUpdate ? (
                  <input
                    type='text'
                    name='amount'
                    placeholder='Кол-во'
                    value={position.amount}
                    onChange={(evt) => handlePositionDataInputChange({lineIndex, positionIndex}, evt.currentTarget.value)}
                    autoComplete='off'
                  />
                ) : (
                  new Intl.NumberFormat('ru-RU').format(
                    position.amount
                  )
                )
              }
            </td>

            {/* Ячейка выбора адреса прихода */}
            <td>
              <div className={styles.subLine}>
                {
                  !provided ? (
                    <CustomSearchSelect
                      inputName='address'
                      defaultValue={position.address}
                      options={addresses}
                      onChange={(value) => handleAddressChange(positionIndex, value)}
                      placeholder='Адрес'
                      executeChangeHandlerOnTypingValue={true}
                    />
                  ) : (
                    position.address
                  )
                }
                <div className={styles.buttonsWrapper}>
                  {
                    positionIndex === userPositions.length - 1 && !provided && (
                      <td>
                        <span
                          className='material-icons'
                          onClick={() => addSubString(lineIndex)}
                          title='Добавить адрес'
                        >
                          add
                        </span>
                      </td>
                    )
                  }
                  {
                    userPositions.length > 1 && !provided && (
                      <span
                        className='material-icons'
                        onClick={() => handleDeleteSubstringButtonClick(positionIndex)}
                        title='Удалить площадку'
                      >
                        delete_outline
                      </span>
                    )
                  }
                </div>
              </div>
            </td>
  
            {/* Кнопка удаления товара из документа оприходования */}
            {
              positionIndex === 0 && !provided && (
                <td className='iconed' rowSpan={userPositions.length}>
                  <span
                    className='roundIcon material-icons'
                    onClick={() => deleteLine(lineIndex)}
                    title='Удалить строку'
                  >
                    delete_outline
                  </span>
                </td>
              )
            }
          </tr>
        ))
      }
    </Fragment>
  );
};

export default PostingsTableRow;
