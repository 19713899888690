import clsx from 'clsx';

import style from './style.module.scss';

const PaginationButton = ({
  pageNumber,
  activePage,
  handleClick
}) => {
  const handleButtonClick = () => {
    handleClick(pageNumber);
  };

  return (
    <li
      className={clsx(style.page, {
        [style.page_current]: activePage === pageNumber,
      })}
    >
      <button
        onClick={handleButtonClick}
        className={style['page-button']}
        type="button"
      >
        {pageNumber}
      </button>
    </li>
  );
};

export default PaginationButton;
