import {useEffect, useState, useContext} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useDispatch} from "react-redux";

import {signUpAsyncAction} from "redux/slices/auth-process/auth-process-api-actions";

import InputComponent from 'components/input/InputComponent';
import ButtonComponent from "components/ButtonComponent";

import {AppContext} from 'providers/AppContextProvider';

import {AppRoute} from 'constants/routes';

import cl from 'styles/pages/signup.module.scss';

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {alert, setCrumbs} = useContext(AppContext);

  const searchParams = useSearchParams();
  const cabinet = searchParams[0].get('cabinet');

  // Контейнер для сообщения внизу формы
	const [formMessage, setFormMessage] = useState("");
	
	// Данные всей формы
	const [form, setForm] = useState({
		last_name: {value: "", isError: false},
		first_name: {value: "", isError: false},
		second_name: {value: "", isError: false},
		email: {value: "", isError: false},
		password: {value: "", isError: false},
		repeatPassword: {value: "", isError: false}
	});
	
	// Метод изменения данных в форме
	const changeHandler = (name, data) => {
		const f = Object.assign({}, form);
		f[name].value = data;
		f[name].isError = false;
		setForm(f);
	};
	
	// Кнопка назад
	const goBack = () => {
    if (cabinet === 'add') {
      navigate(AppRoute.Cabinet.USERS);
    } else {
      navigate(AppRoute.Auth.SIGN_IN);
    }
	};
	
	// Отправка данных
	const sendData = () => {
		const copy = Object.assign({}, form);
		const setError = (name) => {
			copy[name].isError = true;
			setForm(copy);
		}
		
		// Проверка ввода
		if(form.last_name.value.length < 3){
			setError("last_name");
			alert("Введите 3 и более символов", "danger");
			return;
		}
		if(form.first_name.value.length < 3){
			setError("first_name");
			alert("Введите 3 и более символов", "danger");
			return;
		}
		if(form.second_name.value.length > 0 && form.second_name.value.length < 3){
			setError("second_name");
			alert("Введите 3 и более символов", "danger");
			return;
		}
		if(form.email.value.indexOf("@") === -1){
			setError("email");
			alert("Введите корректный email", "danger");
			return;
		}
		if(form.password.value.length < 6){
			setError("password");
			alert("Введите 6 и более символов пароля", "danger");
			return;
		}
		if(form.password.value !== form.repeatPassword.value){
			setError("password");
			setError("repeatPassword");
			alert("Пароли не совпадают", "danger");
			return;
		}

    dispatch(signUpAsyncAction(form))
      .then(() => {
        if (cabinet) {
          navigate(AppRoute.Cabinet.USERS);
        } else {
          navigate(AppRoute.Auth.SIGN_IN);
        }
      });
	};

  // Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
      {name: "Личный кабинет", url: "/cabinet"},
      {name: "Сотрудники", url: "/cabinet/users"},
      {name: "Добавить пользователя", url: ""}
    ]);
	}, []);

  return (
    <>
			<title>Регистрация</title>

			<div className={cl.main + " container"}>
				<form>
					<InputComponent type="text" label="Фамилия" name="last_name" placeholder="введите вашу фамилию"
							 value={form.last_name.value} setValue={changeHandler} required={true}
							 isError={form.last_name.isError}/>
					<InputComponent type="text" label="Имя" name="first_name" placeholder="введите ваше имя"
							 value={form.first_name.value} setValue={changeHandler} required={true}
							 isError={form.first_name.isError}/>
					<InputComponent type="text" label="Отчество" name="second_name" placeholder="введите ваше отчество"
							 value={form.second_name.value} setValue={changeHandler} required={false}
							 isError={form.second_name.isError}/>
					<InputComponent type="email" label="Email" name="email" placeholder="введите ваш email"
							 value={form.email.value} setValue={changeHandler} required={true}
							 isError={form.email.isError}/>
					<InputComponent type="password" label="Пароль" name="password" placeholder="пароль для входа (мин. 6 символов)"
							 value={form.password.value} setValue={changeHandler} required={true}
							 isError={form.password.isError}/>
					<InputComponent type="password" label="Подтверждение" name="repeatPassword" placeholder="пароль еще раз"
							 value={form.repeatPassword.value} setValue={changeHandler} required={true}
							 isError={form.repeatPassword.isError}/>

					{formMessage.length > 1 && (<div><p>{formMessage}</p></div>)}

					<div className="row">
						<div className="col-sm-6">
							<ButtonComponent type="default" onClick={goBack}>Назад</ButtonComponent>
						</div>
						<div className="col-sm-6">
							<ButtonComponent type="accent" onClick={sendData}>Продолжить</ButtonComponent>
						</div>
					</div>
				</form>
			</div>
		</>
  );
};

export default SignUp;
