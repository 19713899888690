
import {useContext, useEffect, useState} from 'react';

import ArchiveInventoryTable from 'components/archive/archive-inventory-table';
import Preloader from 'components/preloader/SpinPlaceholderComponent';

import {AppContext} from 'providers/AppContextProvider';

const Index = () => {
	const {setCrumbs} = useContext(AppContext);

	// Активность прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: 'Архивы', url: '/archive'},
			{name: 'Инвентаризация', url: ''}
		]);
	}, []);
	
	return (
		<>
			<div className='cabinetMain'>
				<Preloader isActive={isPreloaderActive}/>
				<ArchiveInventoryTable
					setIsPreloaderActive={setIsPreloaderActive}
				/>
			</div>
		</>
	);
};

export default Index;
