import Modal from 'components/Modal';

import style from './styles.module.scss';

const DocumentsCheckValuesModal = ({
  data,
  isActive,
  setIsActive
}) => {
  const handleCopyEmptyLinesButtonClick = () => {
    navigator.clipboard.writeText(Object.keys(data).join(' '));
  };

  const CopyEmptyRowsListButton = () => (
    <span
      className="roundIcon material-icons"
      onClick={handleCopyEmptyLinesButtonClick}
      title="Скопировать строки"
    >
      copy
    </span>
  );

  return (
    <Modal isActive={isActive} setIsActive={setIsActive}>
      <div className={style.wrapper}>
        <h3 className={style.title}>
          {'Некорректно заполненные строки: '}
          <CopyEmptyRowsListButton />
        </h3>
        <table className={style.table}>
          <tr className={style.row}>
            <td className={style.cell}>Номер строки</td>
            <td className={style.cell}>Наименование</td>
            <td className={style.cell}>Вид ошибки</td>
          </tr>
          {
            Object.keys(data).map((itemNumber) => (
              <tr className={style.row}>
                <td className={style.cell}>
                  {`${itemNumber} `}
                </td>
                <td className={style.cell}>
                  {data[itemNumber].line.name}
                </td>
                <td className={style.cell}>
                  {
                    data[itemNumber].allSubLinesEmpty && (
                      <>
                        <span>Укажите кол-во хотя бы для одного из адресов</span><br/>
                      </>
                    )
                  }
                  {
                    !data[itemNumber].allSubLinesEmpty &&
                    data[itemNumber].subLines && Object.keys(data[itemNumber].subLines).map((address) => (
                      <tr>
                        <td className={style.cell}>{address}</td>
                        <td className={style.cell}>
                          {
                            data[itemNumber].subLines[address].valueNotNumeric && (
                              <>
                                <span>Указано нечисловое значение</span><br/>
                              </>
                            )
                          }
                          {
                            data[itemNumber].subLines[address].spreadNegative && (
                              <>
                                <span>Указано больше, чем есть на площадке</span><br/>
                              </>
                            )
                          }
                          {/* {
                            data[itemNumber].subLines[address].addressNotSelected && (
                              <>
                                <span>Адрес не выбран</span><br/>
                              </>
                            )
                          } */}
                        </td>
                      </tr>
                    ))
                  }
                </td>
              </tr>
            ))
          }
        </table>
      </div>
    </Modal>
  );
};

export default DocumentsCheckValuesModal;
