import {createAsyncThunk} from '@reduxjs/toolkit';

import {Good} from 'constants/endpoints';

export const syncGoodsAsyncAction = createAsyncThunk(
  'good/syncGoods',
  async (_args, {dispatch, extra: api}) => {
    await api.get(Good.SYNC);
  }
);

export const fetchGoodsAsyncAction = createAsyncThunk(
  'good/fetchGoods',
  async (_args, {dispatch, extra: api}) => {
    const {data} = await api.get(Good.ALL);
    return data;
  }
);
