import cl from "styles/components/Filter.module.scss";
import {useEffect, useState} from "react";
import CustomSelect from "./CustomSelect";

const FilterInventory = ({
									 isOpen = false,
									 data,
									 setData,
									 dataBackup,
									 setDataBackup,
									 setIsFilterActive,
									 sortFn = false,
									 maps = [],
									 fieldList = false,
									 onlyOne = ""
								 }) => {
	const classes = [cl.filterContainer];
	if(isOpen) classes.push(cl.open);
	
	// Состояние значений фильтра
	const [filters, setFilters] = useState([]);
	
	// Бэкап данных
	useEffect(() => {
		let copy = Object.assign([], data);
		setDataBackup(copy);
	}, []);
	
	// Изменение данных в бэкапе при изменении снаружи
	useEffect(() => {
		if(!filters.length) return;
		
		const copy = Object.assign([], dataBackup);
		copy.map((cp, ind) => {
			data.map(dt => {
				if(dt.name === cp.name){
					dt.datas.map((datas_dt, ind_dt) => {
						copy[ind].datas.map((cp_dt, cp_ind) => {
							if(datas_dt.address_uchet === cp_dt.address_uchet)
								copy[ind].datas[cp_ind] = datas_dt;
						});
					});
					copy[ind].total_fact = dt.total_fact;
					return false;
				}
			});
		});
		setDataBackup(copy);
	}, [data]);
	
	// Запись отфильтрованных значений в основной массив
	useEffect(() => {
		// Если нет фильтров, возврат старых значений
		if(!filters.length && dataBackup.length > 0){
			setData(dataBackup);
			setIsFilterActive(true);
			return;
		}
		
		// Если нет начальных данных, полезут ошибки
		if(!dataBackup.length) return;
		
		// Фильтрация
		let newData = Object.assign([], JSON.parse(JSON.stringify(dataBackup)));
		filters.forEach(item => {
			// Выполнение сортировки
			if(sortFn !== false && (item.obj === "row" || item.obj === "name")) {
				sortFn(item.obj);
			}
			
			// Выполнение фильтрации
			const filtered = newData.filter(dt => {
				// Фильтрация строковых значений
				if(item.obj === "field_with_map" || item.obj === "row" || item.obj === "b_group" || item.obj === "name" || item.obj === "article" || item.obj === "storage"){
					if(dt[item.obj] === null) return "";
					dt[item.obj] = dt[item.obj].toString();
					return dt[item.obj].toLowerCase().includes(item.value.toLowerCase());
				} else if(item.obj === "map_id"){ // Фильтрация селектов
					return dt[item.obj] === item.value;
				} else if(item.obj === "field"){ // Фильтрация по площадке
					dt.datas = dt.datas.filter(pos => {
						return pos.address_uchet.includes(item.value + ".") || pos.address_fact.includes(item.value + ".");
					});
					return dt.datas.length > 0;
				} else{ // Фильтрация даты
					if(item.obj === "date_after") return new Date(dt.date).valueOf() >= new Date(dateFormat(item.value)).valueOf();
					if(item.obj === "date_before") return new Date(dt.date).valueOf() <= new Date(dateFormat(item.value)).valueOf();
				}
			});
			
			// Запись отфильтрованных данных
			setData(filtered);
			newData = filtered;
			setIsFilterActive(true);
		});
	}, [filters]);
	
	// Установка значений фильтра
	const setFilter = (index = 0, obj, value) => {
		if(!value.toString().length || value === "__.__.____ __:__"){
			// Удаление строки с фильтром, если значение не задано
			const delFilter = filters.filter(item => item.obj !== obj);
			setFilters(delFilter);
		} else{
			// При каждом изменении значения фильтра - удаление строки и добавление нового значения
			const addFilter = filters.filter(item => item.obj !== obj);
			addFilter.push({obj: obj, value: value});
			setFilters(addFilter);
		}
	}
	
	// Сброс фильтра
	const eraseFilter = () => {
		setFilters([]);
	}
	
	// Форматирование даты
	const dateFormat = (dt) => {
		if(dt == null || dt === "") return "";
		
		dt = dt.split(" ");
		dt[0] = dt[0].split(".");
		if(dt[0][0] === "0000") return "";
		else return dt[0][2] + "-" + dt[0][1] + "-" + dt[0][0] + " " + dt[1];
	}
	
	return (
		<div className={classes.join(" ")}>
			<span className={cl.heading}>Фильтры</span>
			<span className={cl.filterOff + " material-icons"} onClick={eraseFilter}
					title="Сбросить фильтр">filter_alt_off</span>
			
			{maps.length > 0 &&
				<CustomSelect options={maps} name="map_id" changeHandler={setFilter} currentIndex="0" placeholder="Карта"
								  currentValue={filters.find(fl => fl.obj === "map_id") !== undefined ? filters.find(fl => fl.obj === "map_id").value : ""}/>
			}
			{fieldList.length > 0 &&
				<CustomSelect options={fieldList} name="field" changeHandler={setFilter} currentIndex="0"
								  placeholder="Площадка / поле" multiple={false} className="select-search fixed-dropdown"
								  currentValue={filters.find(fl => fl.obj === "field") !== undefined ? filters.find(fl => fl.obj === "field").value : ""}/>
			}
			{(onlyOne === "b_group" || onlyOne === "") &&
				<input type="text" onChange={(e) => setFilter(0, "b_group", e.target.value)}
						 value={filters.find(fl => fl.obj === "b_group") !== undefined ? filters.find(fl => fl.obj === "b_group").value : ""}
						 placeholder="Группа"/>
			}
			{(onlyOne === "article" || onlyOne === "") &&
				<input type="text" onChange={(e) => setFilter(0, "article", e.target.value)}
						 value={filters.find(fl => fl.obj === "article") !== undefined ? filters.find(fl => fl.obj === "article").value : ""}
						 placeholder="Артикул"/>
			}
			{(onlyOne === "name" || onlyOne === "") &&
				<input type="text" onChange={(e) => setFilter(0, "name", e.target.value)}
						 value={filters.find(fl => fl.obj === "name") !== undefined ? filters.find(fl => fl.obj === "name").value : ""}
						 placeholder="Наименование"/>
			}
		</div>
	);
};

export default FilterInventory;