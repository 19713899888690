import {createAsyncThunk} from '@reduxjs/toolkit';

import {ApiRoute} from 'constants/endpoints';

export const fetchCurrentNamesAsyncAction = createAsyncThunk(
  'history/getCurrentNames',
  async (_args, {dispatch, extra: api}) => {
    const {data} = await api.get(ApiRoute.History.CURRENT_NAMES);
    return data;
  }
);

export const fetchArchiveNamesAsyncAction = createAsyncThunk(
  'history/getArchiveNames',
  async (id, {dispatch, extra: api}) => {
    const {data} = await api.get(`${ApiRoute.History.ARCHIVE_NAMES}/${id}`);
    return data;
  }
);

export const addHistoryAsyncAction = createAsyncThunk(
  'history/addHistory',
  async (body, {extra: api}) => {
    await api.post(ApiRoute.History.ADD_DATA, body);
  }
);
