import React from 'react';
import ReactDOM from 'react-dom/client';

import {store} from 'redux/store';
import {validateTokenAsyncAction} from 'redux/slices/auth-process/auth-process-api-actions';

import App from './App';

import {getToken} from 'helpers/storage';

import "bootstrap/dist/css/bootstrap.min.css";
import "styles/globals.scss";

const token = getToken();

if (token) {
  await store.dispatch(validateTokenAsyncAction());
}

const root = ReactDOM.createRoot(
  document.getElementById('root')
);

root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);
