// Объект пустой строки документа (Списание, Оприходование, Перемещение)
export const emptyLine = {
  name: '', // Наименование
  article: '', // Артикул
  b_group: '', // Название группы в Бизнес.ру
  b_group_id: 0, // Идентификатор группы в Бизнес.ру
  good_id: 0, // id товара в Бизнес.ру
  amounts: [{amount: '', address: ''}], // Остатки на складах
  totalAmount: '', // Общий остаток на складах
  userPositions: [{amount: '', address: ''}], // Пользовательский ввод позиции
  isError: false // Флаг ошибки в строке
};
