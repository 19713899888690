import {useContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import CardComponent from "components/CardComponent";

import {fetchMaps} from "redux/slices/mapSlice";

import {AppContext} from "providers/AppContextProvider";
import {loadingStatus} from "helpers/fetcher";
import {getUser} from "helpers/storage";

import cl from "styles/pages/index.module.scss";

const Maps = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {maps} = useSelector((state) => state.map);

  const { setCrumbs } = useContext(AppContext);

  // Запись хлебных крошек
  useEffect(() => {
    setCrumbs([]);
  }, []);

  useEffect(() => {
    dispatch(fetchMaps());
  }, []);
  
  return (
    <>
      <title>Карты</title>

      <div className={cl.cardContainer + " container"}>
        {maps.status === loadingStatus.LOADING && <div>Loading...</div>}
        <div className="row">
          {maps.data.map((map) => (
            <div
              className="col-sm-3"
              style={{ marginTop: "2rem" }}
              key={map.id.toString()}
            >
              <CardComponent
                name={map.name}
                image={map.thumb}
                buttons={[
                  {
                    text: "Открыть",
                    handler: function () {
                      navigate("/map/" + map.id);
                    },
                  },
                  getUser().accessLevel > 2 && {
                    text: "Редактировать",
                    handler: function () {
                      navigate("/map/edit/" + map.id);
                    },
                  },
                ]}
              />
            </div>
          ))}
          {getUser().accessLevel > 2 && (
            <div className="col-sm-3" style={{ marginTop: "2rem" }}>
              <CardComponent
                name="Новая карта"
                image={`${process.env.REACT_APP_SERVER_URL}/img/maps/map-add.svg`}
                buttons={[
                  {
                    text: <span className="material-icons">add</span>,
                    handler: function () {
                      navigate("/map/add");
                    },
                  },
                ]}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Maps;
