import { createContext, useState } from "react";

import { getUserSetting, setUserSetting } from "helpers/storage";
import AlertComponent from "components/AlertComponent";

export const AppContext = createContext(null);

const AppContextProvider = ({ children, value }) => {
  const [sidenavIsMinified, setSidenavIsMinified] = useState(() => getUserSetting("sidenav-is-close"));
  // TODO: отрефакторить
  // Условие для перехода по ссылке в меню, если необходимо предупреждение
  const [menuCondition, setMenuCondition] = useState({});
  // Хлебные крошки
  const [crumbs, setCrumbsArray] = useState([]);
  // Метод добавления хлебной крошки к остальным
  const setCrumbs = (crumb) => {
    setCrumbsArray(crumb);
  };
  // Работа с уведомлениями
  const [alertText, setAlertText] = useState("");
  const [alertType, setAlertType] = useState("");
  const [alertIsActive, setAlertIsActive] = useState(false);
  const [alertButtons, setAlertButtons] = useState([]);
  const [alertInProcess, setAlertInProcess] = useState(false);
  const [tm, setTm] = useState(null);
  const callAlert = (
    alertText,
    alertType,
    hideDuration = 6000,
    buttons = [],
    inProcess = false
  ) => {
    if (tm !== null) clearTimeout(tm);
    // Вызов окна уведомления
    setAlertText(alertText);
    setAlertType(alertType);
    setAlertIsActive(true);
    setAlertButtons(buttons);
    setAlertInProcess(inProcess);

    // Скрытие уведомления
    if (hideDuration) {
      const timeout = setTimeout(() => {
        setAlertIsActive(false);
        setAlertText("");
        setAlertType("");
      }, hideDuration);
      setTm(timeout);
    }
  };

  const handleToggleSidenavIsMinified = () => {
    const st = !sidenavIsMinified;
    setUserSetting("sidenav-is-close", st);
    setSidenavIsMinified(st);
  };

  return (
    <AppContext.Provider
      value={{
        alert: callAlert,
        crumbs: crumbs,
        setCrumbs: setCrumbs,
        alertIsActive: alertIsActive,
        setAlertIsActive: setAlertIsActive,
        menuCondition: menuCondition,
        setMenuCondition: setMenuCondition,
        alertType: alertType,
        buttons: alertButtons,
        inProcess: alertInProcess,
        alertText: alertText,
        sidenavIsMinified,
        handleToggleSidenavIsMinified,
      }}
    >
      {children}

      <AlertComponent
        alertType={alertType}
        alertIsActive={alertIsActive}
        setAlertIsActive={setAlertIsActive}
        buttons={alertButtons}
      >
        {alertText}
      </AlertComponent>
    </AppContext.Provider>
  );
};

export default AppContextProvider;
