import {createSlice} from '@reduxjs/toolkit';

import {fetchArchiveNamesAsyncAction, fetchCurrentNamesAsyncAction} from './history-api-actions';

import {loadingStatus} from 'helpers/fetcher';

const initialState = {
  currentNames: {
    status: loadingStatus.IDLE,
    data: [],
    error: null,
  },
  archiveNames: {
    status: loadingStatus.IDLE,
    data: [],
    dates: '',
    error: null,
  }
};

export const history = createSlice({
  name: 'history',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCurrentNamesAsyncAction.pending, (state) => {
        state.currentNames.status = loadingStatus.LOADING;
      })
      .addCase(fetchCurrentNamesAsyncAction.fulfilled, (state, action) => {
        state.currentNames.status = loadingStatus.SUCCEEDED;
        state.currentNames.data = action.payload.result;
      })
      .addCase(fetchCurrentNamesAsyncAction.rejected, (state, action) => {
        state.currentNames.status = loadingStatus.FAILED;
        state.currentNames.error = `${action.error.name}: ${action.error.message}`;
      })
      .addCase(fetchArchiveNamesAsyncAction.pending, (state) => {
        state.archiveNames.status = loadingStatus.LOADING;
      })
      .addCase(fetchArchiveNamesAsyncAction.fulfilled, (state, action) => {
        state.archiveNames.status = loadingStatus.SUCCEEDED;
        state.archiveNames.data = action.payload.result;
        state.archiveNames.dates = action.payload.dates;
      })
      .addCase(fetchArchiveNamesAsyncAction.rejected, (state, action) => {
        state.archiveNames.status = loadingStatus.FAILED;
        state.archiveNames.error = `${action.error.name}: ${action.error.message}`;
      });
  }
});
