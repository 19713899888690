
import {useEffect, useState, useContext} from "react";
import axios from "axios";
import UsersTable from "components/cabinet/UsersTable";

import {AppContext} from "providers/AppContextProvider";

const Users = ({children}) => {
	const {alert, setCrumbs} = useContext(AppContext);

	const [users, setUsers] = useState([]);
	
	useEffect(() => {
		axios.get("user/all").then(response => {
			setUsers(response.data.result);
		});
	}, []);
	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: "Личный кабинет", url: "/cabinet"},
			{name: "Сотрудники", url: "/cabinet/users"}
		]);
	}, []);
	
	return (
		<>
			{children}

			<title>Личный кабинет | пользователи</title>

			<div className="cabinetMain">
				<UsersTable users={users} setUsers={setUsers} alert={alert}/>
			</div>
		</>
	);
};

export default Users;