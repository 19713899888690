import {createSlice} from '@reduxjs/toolkit';

import {fetchGoodsAsyncAction} from './good-api-actions';

const initialState = {
  goods: [],
  articles: []
};

export const goodSlice = createSlice({
  name: 'good',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchGoodsAsyncAction.fulfilled, (state, action) => {
        state.goods = action.payload.result.goods;
        state.articles = action.payload.result.articles;
      });
  }
});
