import {useState, useEffect, useContext} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import clsx from 'clsx';

import Input from 'components/input/InputComponent';
import CustomSearchSelect from 'components/custom-search-select/custom-search-select';
import PostingsTableRow from 'components/postings-table-row/postings-table-row';
import AddNewLineButton from 'components/add-new-line-button/add-new-line-button';
import Modal from 'components/Modal';
import DocumentsFloatPanel from 'components/documents-float-panel/documents-float-panel';
import Preloader from "components/preloader/SpinPlaceholderComponent";
import DocumentsCheckValuesModal from 'components/documents-check-values-modal/documents-check-values-modal';

import {fetchGroups, fetchStores} from 'redux/slices/business/businessSlice';
import {fetchGoodsAsyncAction} from 'redux/slices/good/good-api-actions';
import {getGoods} from 'redux/slices/good/selectors';
import {getGroups} from 'redux/slices/business/selectors';
import {
  postCommentFilesAsyncAction,
  deleteCommentFileAsyncAction,
  fetchDocumentAsyncAction,
  updateDocumentAsyncAction,
  fetchStorageAddressesAsyncAction
} from 'redux/slices/documents/documents-api-actions';
import {
  setDocumentItemsAction,
  setDocumentAction,
  setDocumentStorageAction,
  setDocumentEmployeeAction,
  setDocumentDateAction,
  setDocumentNumberAction,
  setDocumentCommentAction,
  setCommentFilesAction,
  resetDocumentAction
} from 'redux/slices/documents/documents';
import {
  getDocument,
  getDocumentsNumbers,
  getCommentFiles,
  getDocumentAuthor,
  getDoneStatus,
  getIsCorrectionStatus,
  getProvidedStatus,
  getStorageAddresses
} from 'redux/slices/documents/selectors';

import {AppContext} from 'providers/AppContextProvider';
import {getUser, saveNewPostingDocumentIntoLocalStorage} from 'helpers/storage';
import {generateDate, sortStringValues} from 'helpers/utils';

import {AppRoute} from 'constants/routes';
import {DocumentType, DocumentTypeNameMap} from 'constants/document-type';
import {emptyLine} from 'constants/document-empty-line';

import PopUp from 'components/popup/popup';

import styles from './styles.module.scss';

const PostingsNew = () => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const ARCHIVE_LOCATION_REG_EXP = /archive/;
  const pathName = location.pathname;
  const isInArchive = ARCHIVE_LOCATION_REG_EXP.test(pathName);

  // Проверка уровня пользователя
  const canUserUpdate = getUser().accessLevel > 1;

  // Классы таблицы
  const tableClasses = [styles.mainTable, styles.top0, "table", "table-responsive"];

  const {alert, setCrumbs} = useContext(AppContext);

  // Модальное окно примечания
  const [commentModalActive, setCommentModalActive] = useState(false);

  // Модальное окно со списком некорректно заполненных строк
  const [checkValuesModalData, setCheckValuesModalData] = useState([]);
  const [isCheckValuesModalActive, setIsCheckValuesModalActive] = useState(false);

  // Активность прелоадера
  const [isPreloaderActive, setIsPreloaderActive] = useState(false);

  // Состояние видимости подсказки номера документа (всплывает, если такой номер уже есть в списке)
  const [documentNumberPopUpVisible, setDocumentNumberPopUpVisible] = useState(false);

  // Тип и порядок сортировки
  const [sortData, setSortData] = useState({
    type: 'name',
    order: 'ASC'
  });

  const postingDocument = useSelector(getDocument);
  const provided = useSelector(getProvidedStatus);
  const done = useSelector(getDoneStatus);
  const correction = useSelector(getIsCorrectionStatus);
  const documentNumber = postingDocument.documentNumber;
  const documentDate = postingDocument.documentDate;
  const documentItems = postingDocument.documentItems;
  const storage = postingDocument.storage;
  const employee = postingDocument.employee;
  const comment = postingDocument.comment;
  const commentFiles = useSelector(getCommentFiles);
  const author = useSelector(getDocumentAuthor);
  const documentsNumbers = useSelector(getDocumentsNumbers);
  const itemsNames = structuredClone(documentItems).map((item) => item.name).filter((item) => !!item);

  const goods = useSelector(getGoods);
  const groupsIdsFromGoods = goods.map((good) => good.b_group_id);
  const groups = useSelector(getGroups).filter((group) => groupsIdsFromGoods.includes(group.id));
  const storages = useSelector((state) => state.business).stores.data;

  const allAddresses = useSelector(getStorageAddresses);
  const addresses = allAddresses.filter((address) => address.storage === storage).sort((a, b) => {
    const ADDRESS_REG_EXP = /^\d+/;
    const prevValue = a.value;
    const nextValue = b.value;

    if (ADDRESS_REG_EXP.test(prevValue) && ADDRESS_REG_EXP.test(nextValue)) {
      return prevValue.match(ADDRESS_REG_EXP)[0] - nextValue.match(ADDRESS_REG_EXP)[0];
    }
    if (ADDRESS_REG_EXP.test(prevValue) && !ADDRESS_REG_EXP.test(nextValue)) {
      return -1;
    }
    if (!ADDRESS_REG_EXP.test(prevValue) && ADDRESS_REG_EXP.test(nextValue)) {
      return 1;
    }
    
    return 0;
  });

  // Проверка склада
  const storageChecker = () => {
    if (storage === "") {
      alert("Выберите склад!", "danger")
    };
  };

  // Функция сортировки
  const handleSortElementClick = (name) => {
    setSortData((prevState) => ({
      type: name,
      order: prevState.type === name ? prevState.order === 'ASC' ? 'DESC' : 'ASC' : 'ASC'
    }));
  };

  // Установка товаров при изменении сортировки (sortFn)
  useEffect(() => {
    const sortedItems = structuredClone(documentItems).sort((a, b) => {
      const sortOrder = sortData.order === 'ASC'
        ? sortData.type === 'article'
            ? Number(a[sortData.type]) - Number(b[sortData.type])
            : sortStringValues(a[sortData.type], b[sortData.type])
        : sortData.type === 'article'
            ? Number(b[sortData.type]) - Number(a[sortData.type])
            : -sortStringValues(a[sortData.type], b[sortData.type]);

      return sortOrder;
    });

    dispatch(setDocumentItemsAction(sortedItems));
  }, [itemsNames.length, sortData]);

  // Добавление строки
  const addLine = (index = -1, firstDeleted = false) => {
    const copy = structuredClone(documentItems);
    
    copy.push(emptyLine)

    dispatch(setDocumentItemsAction(copy));
  };

  // Удаление строки
  const deleteLine = (index) => {
    // Если удалена последняя строка, то автоматически вставляем новую пустую
    if (documentItems.length === 1) {
      dispatch(setDocumentItemsAction([emptyLine]));
    } else {
      const copy = structuredClone(documentItems);

      copy.splice(index, 1);
      dispatch(setDocumentItemsAction(copy));
    }
  };

  // Обработчик кнопки удаления строки
  const handleDeleteLineButtonClick = (index) => {
    const handler = () => {
      deleteLine(index);
    };

    alert('Удалить товар из списка?', 'danger', 0, [
      {
        text: 'Да',
        handler,
        needToCloseImmediately: true
      },
      {
        text: 'Нет',
        handler: () => alert('', 'default', 1)
      }
    ]);
  };

  // Выбор и установка склада
  const handleStorageInputChange = (selectedStorage) => {
    // Если документ новый и строк пока нет, то добавляем одну пустую строку в момент выбора склада
    if (!documentItems.length) {
      dispatch(setDocumentStorageAction(selectedStorage));
      dispatch(setDocumentItemsAction([emptyLine]));
    }

    // Если склад уже был выбран и данные уже есть по крайней мере в одной строке,
    // то задать вопрос "Данные будут потеряны. Вы уверены, что хотите изменить склад?"
    if (storage && storage !== selectedStorage && documentItems.length && documentItems[0].name) {
      alert('Введённые данные будут потеряны. Вы уверены, что хотите изменить склад?', 'danger', 0, [
        {
          text: 'Да',
          handler: () => {
            dispatch(setDocumentStorageAction(selectedStorage));
            dispatch(setDocumentItemsAction([emptyLine]));
            alert('', 'default', 1);
          },
          needToCloseImmediately: true
        },
        {
          text: 'Нет',
          handler: () => {
            alert('', 'default', 1);
          }
        }
      ]);
    }

    // Если склад уже был выбран и пока есть только одна пустая строка, то изменить склад
    if (storage && documentItems.length === 1 && !documentItems[0].name) {
      dispatch(setDocumentStorageAction(selectedStorage));
    }

    // Для предотвращения изменения внутреннего значения CustomSearchSelect,
    // если пользователь нажал на кнопку "Нет" при изменении склада возвращается true
    // и проверяется в обработчике компонента CustomSearchSelect
    return true;
  };

  // Обработчик поля ввода имени бригадира
  const handleEmployeeInputChange = (employee) => {
    dispatch(setDocumentEmployeeAction(employee));
  };

  // Обработчик поля ввода номера документа
  const handleDocumentNumberInputChange = (name, value) => {
    const existingDocumentsNumbers = documentsNumbers.map((docNumber) => docNumber.match(/\d+/)[0]);
    const newDocumentNumberAlreadyExists = existingDocumentsNumbers.includes(value);

    // Проверка, есть ли уже такой номер документа в списке существующих
    // и показ уведомления, если есть
    if (newDocumentNumberAlreadyExists) {
      setDocumentNumberPopUpVisible(true);
    } else {
      setDocumentNumberPopUpVisible(false);
    }

    dispatch(setDocumentNumberAction(value));
  };

  // Обработчик ввода текста примечания
  const handleModalTextAreaChange = (evt) => {
    const textAreaValue = evt.currentTarget.value;
    dispatch(setDocumentCommentAction(textAreaValue));
  };

  // Отправка файлов примечания на сервер
  const sendCommentFiles = async (id, files) => {
    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`file_${index}`, file);
    });

    await dispatch(postCommentFilesAsyncAction({
      id,
      files: formData
    }));
  };

  // Обработчик добавления файла к примечанию в модальном окне
  const handleFileInputChange = async (evt) => {
    const inputFiles = Object.values(evt.currentTarget.files);
    const updatedFiles = [...commentFiles, ...inputFiles];

    dispatch(setCommentFilesAction(updatedFiles));
  };

  // Удаление файла из модального окна примечания
  const removeFile = async ({url, index}) => {
    // Если это новый документ, то удаляем из стейта
    if (index) {
      const updatedCommentFiles = commentFiles.filter((_file, fileIndex) => fileIndex !== index);
      dispatch(setCommentFilesAction(updatedCommentFiles));
    }
    // Если редактируется существующий, то сразу удаляем на сервере
    if (url) {
      await dispatch(deleteCommentFileAsyncAction({filename: url}));
      dispatch(fetchDocumentAsyncAction(id));
    }
  };

  // Запрос детальной информации документа
  const requestForDocumentDetails = async () => {
    const notProvided = provided !== undefined && provided === 0;
    const notDone = done !== undefined && done === 0;

    setIsPreloaderActive(true);
    // if (notProvided && notDone && canUserUpdate) {
    //   await dispatch(refreshDocumentAmountsAsyncAction({
    //     id,
    //     documentType: DocumentType.POSTING
    //   }));
    // }
    await dispatch(fetchDocumentAsyncAction(id));
    setIsPreloaderActive(false);
  };

  // Генерация номера нового документа
  const generateDocumentNumber = () => {
    const existingDocumentsNumbers = documentsNumbers
      .filter((docNumber) => (/\d+/).test(String(docNumber)))
      .map((docNumber) => Number(String(docNumber).match(/\d+/)[0]))
      .sort((a, b) => a - b);
    const newNumber = [0, ...existingDocumentsNumbers]
      .find((docNumber) => !existingDocumentsNumbers.includes(docNumber + 1)) + 1;
    // const newNumber = existingDocumentsNumbers.length ? Math.max(...existingDocumentsNumbers) + 1 : 1;

    return newNumber;
  };

  // Обработчик кнопки закрытия модального окна примечания
  const handleCommentModalCloseButtonClick = async () => {
    const newFiles = commentFiles.filter((file) => file instanceof File);

    if (id && canUserUpdate) {
      await dispatch(updateDocumentAsyncAction({
        id,
        body: {
          type: DocumentTypeNameMap[DocumentType.POSTING],
          form: documentItems,
          document: {
            number: documentNumber,
            date: documentDate
          },
          storage,
          employee,
          comment
        }
      }));
      if (newFiles.length) {
        await sendCommentFiles(id, newFiles);
      }
      dispatch(fetchDocumentAsyncAction(id));
    }

    setCommentModalActive(false);
  };

  // Обработчик селекта выбора одного адреса для всех элементов документа оприходования
  const changeAddressForAllItems = (address) => {
    const updatedItems = documentItems.map((docItem) => {
      const userPositions = docItem.userPositions.map((position) => ({
        ...position,
        address 
      }));

      return {
        ...docItem,
        userPositions
      };
    });

    dispatch(setDocumentItemsAction(updatedItems));
  };

  // Очистка стейта после отмонтирования компонента
  useEffect(() => {
    return () => {
      dispatch(resetDocumentAction());
    };
  }, []);

  // Запрос данных конкретного документа с сервера
  useEffect(() => {
    if (id) {
      requestForDocumentDetails();
    }
    dispatch(fetchStores());
  }, [id, provided, done]);

  // Запрос всех имеющихся в Системе товаров
  // (для формирования выпадающих списков выбора наименования, артикула и группы)
  useEffect(() => {
    if (!goods.length) {
      dispatch(fetchGoodsAsyncAction());
    }
    if (!groups.length) {
      dispatch(fetchGroups());
    }
  }, []);

  // Запрос адресов по конкретному выбранному складу
  useEffect(() => {
    if (storage) {
      dispatch(fetchStorageAddressesAsyncAction(storage));
    }
  }, [storage]);

  // Установка номера для нового документа
  useEffect(() => {
    if (!id) {
      if (!documentNumber) {
        const newDocumentNumber = generateDocumentNumber();
        dispatch(setDocumentNumberAction(newDocumentNumber));
      }
    }
  }, [documentsNumbers]);

  // Установка даты для нового документа
  useEffect(() => {
    if (!id) {
      if (!documentDate) {
        const newDocumentDate = generateDate();
        dispatch(setDocumentDateAction(newDocumentDate));
      }
    }
  }, []);

  // Формирование данных для хлебных крошек
  const getCrumbs = () => {
    const isCorrection = Number(correction) === 1;
    const crumbs = {
      archive: {
        title: 'Архивы',
        url: AppRoute.Archive.ALL
      },
      postings: {
        title: 'Оприходование',
        url: isInArchive ? AppRoute.Archive.POSTINGS : AppRoute.Document.POSTINGS
      },
      corrections: {
        title: 'Коррекции',
        url: AppRoute.Archive.CORRECTIONS
      },
      new: {
        title: 'Новый документ',
        url: ''
      }
    };
    const pathNameItems = pathName.split('/').filter((item) => item !== '' && item.match(/\D/));
    const compiledCrumbs = pathNameItems.map((item) => ({
      name: crumbs[item].title,
      url: crumbs[item].url
    }));

    if (id) {
      compiledCrumbs.push({name: id ? `№ ${documentNumber}${isCorrection ? ' (Коррекция)' : ''}` : 'Новый документ', url: ''})
    }

    return compiledCrumbs;
  };

  // Запись хлебных крошек
  useEffect(() => {
    setCrumbs([
      ...getCrumbs()
    ]);
  }, [documentNumber, isInArchive, correction]);

  // Если уже есть начатый документ в localStorage, то продолжить его заполнение
  useEffect(() => {
    const incompletedPostingDocument = JSON.parse(localStorage.getItem('newPostingDocument'));
    if (incompletedPostingDocument) {
      dispatch(setDocumentAction(incompletedPostingDocument));
    }
  }, []);

  // Сохранение нового документа в localStorage
  useEffect(() => {
    if (!id && (documentItems.length || storage || employee || comment)) {
      saveNewPostingDocumentIntoLocalStorage(postingDocument);
    }
  }, [postingDocument]);

  // Защита от открытия архивного документа с помощью адресной строки в основном списке и наоборот
  // Если пользователь намеренно вводит в адресной строке "/charges/<id архивного документа>"
  useEffect(() => {
    if (id) {
      const isDone = done === 1;
      const notDone = done !== undefined && done === 0;
      // const isCorrection = Number(correction) === 1;

      if (isDone) {
        // if (isCorrection) {
        //   navigate(`${AppRoute.Archive.CORRECTIONS}/${id}`);
        // } else {
        //   navigate(`${AppRoute.Archive.POSTINGS}/${id}`);
        // }
        navigate(`${AppRoute.Archive.POSTINGS}/${id}`);
      }
      if (notDone) {
        navigate(`${AppRoute.Document.POSTINGS}/${id}`);
      }
    }
  }, [correction, done, id, isInArchive]);

  return (
    <div>
      <Preloader isActive={isPreloaderActive}/>

      <DocumentsFloatPanel
        sendCommentFiles={sendCommentFiles}
        setModalActive={setCommentModalActive}
        setIsPreloaderActive={setIsPreloaderActive}
        setCheckValuesModalData={setCheckValuesModalData}
        setIsCheckValuesModalActive={setIsCheckValuesModalActive}
      />

      <table className={tableClasses.join(" ")}>
        <thead className={styles.top0 + " theadBordered thead-dark"}>
          <tr>
            <th colSpan="2" className={styles.borderNone}>
              <span>Акт оприходования № </span>
              {
                !provided && canUserUpdate ? (
                  <>
                    <Input
                      type="text"
                      name="document-number"
                      autocomplete="off"
                      placeholder="Номер документа"
                      value={documentNumber}
                      setValue={handleDocumentNumberInputChange}
                    />
                    {
                      documentNumberPopUpVisible && (
                        <PopUp setPopUpVisible={setDocumentNumberPopUpVisible}>
                          <span>
                            Документ с таким номером уже существует
                          </span>
                        </PopUp>
                      )
                    }
                  </>
                ) : documentNumber
              }
            </th>
            <th className={styles.borderNone}>
              <span>от </span>
              {
                !provided && canUserUpdate ? (
                  <Input
                    type="text"
                    name="documentDate"
                    autocomplete="off"
                    mask="99.99.9999"
                    placeholder="Дата"
                    value={documentDate}
                    setValue={(name, value) => dispatch(setDocumentDateAction(value))}
                  />
                ) : documentDate.split(' ').slice(0, -1).join(' ')
              }
            </th>
            <th>
              {
                id && (
                  <>
                    <span>Автор:&nbsp;{author}</span>
                    <br/>
                  </>
                )
              }
              Бригадир:&nbsp;
              {
                !provided && canUserUpdate ? (
                  <Input
                    type="text"
                    name="employee"
                    value={employee}
                    setValue={(name, value) => handleEmployeeInputChange(value)}
                    placeholder="Бригадир"
                    title={employee}
                  />
                ) : employee
              }
            </th>
            <th colSpan="3">Остатки по складам:</th>
            <th>
              {
                !provided && canUserUpdate ? (
                  <CustomSearchSelect
                    inputName='storage'
                    defaultValue={storage}
                    options={storages}
                    onChange={handleStorageInputChange}
                    placeholder='Склад'
                  />
                ) : storage
              }
            </th>
            <th>
              {
                !provided && (
                  <CustomSearchSelect
                    inputName='allAddress'
                    defaultValue={''}
                    options={addresses}
                    onChange={changeAddressForAllItems}
                    placeholder='Адрес для всех'
                    clearInputAfterOptionSelection={true}
                  />
                )
              }
            </th>
          </tr>
          <tr>
            <th rowSpan="2">№</th>
            <th
              rowSpan="2"
              className={clsx('sort', {
                'sorted': sortData.type === 'name',
                'rotated': sortData.type === 'name' && sortData.order === 'ASC'
              })}
              onClick={() => handleSortElementClick('name')}
            >
              <span>Название</span>
            </th>
            <th
              rowSpan="2"
              className={clsx('sort', {
                'sorted': sortData.type === 'article',
                'rotated': sortData.type === 'article' && sortData.order === 'ASC'
              })}
              onClick={() => handleSortElementClick('article')}
            >
              <span>Артикул</span>
            </th>
            <th
              rowSpan="2"
              className={clsx('sort', {
                'sorted': sortData.type === 'b_group',
                'rotated': sortData.type === 'b_group' && sortData.order === 'ASC'
              })}
              onClick={() => handleSortElementClick('b_group')}
            >
              Группа
            </th>
            <th rowSpan="2">Всего:</th>
            <th colSpan="2">В том числе:</th>
            <th rowSpan="2">Приход</th>
            <th rowSpan="2">Адрес прихода</th>
          </tr>
          <tr>
            <th>Кол-во</th>
            <th>Адрес</th>
          </tr>
        </thead>
        <tbody>
          {
            storage && (
              <>
                {/* Строки таблицы */}
                {
                  documentItems.map((line, lineIndex) => (
                    <PostingsTableRow
                      key={lineIndex}
                      line={line}
                      lineIndex={lineIndex}
                      goods={goods}
                      groups={groups}
                      addresses={addresses}
                      storageChecker={storageChecker}
                      deleteLine={handleDeleteLineButtonClick}
                    />
                  ))
                }
                
                {
                  !provided && canUserUpdate && (
                    <AddNewLineButton title='Добавить строку' addLine={addLine}/>
                  )
                }
                <tr style={{height: "300px"}}/>
              </>
            )
          }
        </tbody>
      </table>

      <Modal isActive={commentModalActive} setIsActive={handleCommentModalCloseButtonClick}>
        {
          !isInArchive && canUserUpdate ? (
            <>
              <textarea
                value={comment}
                onChange={handleModalTextAreaChange}
                placeholder="Примечание"
              />
              <input
                type="file"
                name="files[]"
                onChange={handleFileInputChange}
                multiple
              />
            </>
          ) : (
            <div className={styles.commentFrame}>
              {comment}
            </div>
          )
        }
        {
          commentFiles.map((file, index) => (
            <div className="fileIconGroup">
              {
                !isInArchive && canUserUpdate && (
                  <span
                    className="material-icons remove"
                    title="Удалить"
                    onClick={() => removeFile(id ? {url: file.url} : {index})}
                  >
                    cancel
                  </span>
                )
              }
              <span
                className="material-icons file"
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_SERVER_URL + file.url,
                    "_blank"
                  )
                }
              >
                description
              </span>
              <span
                className="name"
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_SERVER_URL + file.url,
                    "_blank"
                  )
                }
              >
                {file.name}
              </span>
            </div>
          ))
        }
      </Modal>

      <DocumentsCheckValuesModal
        data={checkValuesModalData}
        isActive={isCheckValuesModalActive}
        setIsActive={setIsCheckValuesModalActive}
      />
    </div>
  );
};

export default PostingsNew;
