import {Fragment} from "react";

import Input from "components/input/InputComponent";
import CustomSearchSelect from 'components/custom-search-select/custom-search-select';
import ChargesTableRow from 'components/charges-table-row/charges-table-row';

import cl from "styles/pages/[fieldId].module.scss";

const FormCharges = ({
  typeNames,
  documentNumber,
  setDocumentNumber,
  documentDate,
  setDocumentDate,
  groups,
  lines,
  filterNames,
  filterArticles,
  onChangeName,
  onChangePos,
  deleteLine,
  addLine,
  scrollPlus,
  provided,
  storage,
  setStorage,
  storages,
  employee,
  setEmployee,
  storageChecker,
  sortFn,
  sortName,
}) => {
  const router = { query: { type: (new URLSearchParams(window.location.search)).get("type") }, };
  

  // Классы таблицы
  const tableClasses = [cl.mainTable, cl.top0, "table", "table-responsive"];

  return (
    <table className={tableClasses.join(" ")}>
      <thead className={cl.top0 + " theadBordered thead-dark"}>
        <tr>
          <th colSpan="2" className={cl.borderNone}>
            {
              documentNumber !== "" && (
                <>
                  {typeNames[router.query.type]} №&nbsp;
                  {lines[0].is_copy === 0 ? (
                    documentNumber
                  ) : provided === 1 ? (
                    documentNumber
                  ) : (
                    <Input
                      type="text"
                      name="documentNumber"
                      autocomplete="off"
                      placeholder="Номер документа"
                      value={documentNumber}
                      setValue={(name, value) => setDocumentNumber(value)}
                    />
                  )}
                </>
              )
            }
          </th>
          <th className={cl.borderNone + " " + cl.dateInput}>
            {documentNumber !== "" && <>от&nbsp;</>}
            {
              provided === 0 ? (
                <Input
                  type="text"
                  name="documentDate"
                  autocomplete="off"
                  mask="99.99.9999 99:99"
                  placeholder="Дата"
                  value={documentDate}
                  setValue={(name, value) => setDocumentDate(value)}
                />
              ) : (
                documentDate
              )
            }
          </th>
          <th>
            {
              lines.length > 0 && lines[0].author && (
                <>
                  Автор:&nbsp;
                  {lines[0].author}
                  <br />
                </>
              )
            }
            Бригадир:&nbsp;
            <Input
              type="text"
              name="employee"
              value={employee}
              setValue={setEmployee}
              placeholder="Бригадир"
              title={employee}
            />
          </th>
          <th colSpan="3">Остатки по складам:</th>
          <th>
            {
              provided === 0 ? (
                <CustomSearchSelect
                  inputName='storage'
                  defaultValue={storage}
                  options={storages}
                  onChange={setStorage}
                  placeholder='Склад'
                />
              ) : (
                storage
              )
            }
          </th>
        </tr>
        <tr>
          <th rowSpan="2">№</th>
          <th
            rowSpan="2"
            className={sortName === "name" ? "sort sorted" : "sort"}
            onClick={() => sortFn("name")}
          >
            <span>Название</span>
          </th>
          <th
            rowSpan="2"
            className={sortName === "article" ? "sort sorted" : "sort"}
            onClick={() => sortFn("article")}
          >
            <span>Артикул</span>
          </th>
          <th
            rowSpan="2"
            className={sortName === "b_group" ? "sort sorted" : "sort"}
            onClick={() => sortFn("b_group")}
          >
            Группа
          </th>
          <th rowSpan="2">Всего:</th>
          <th colSpan="2">В том числе:</th>
          <th rowSpan="2">Расход</th>
          {provided === 0 && <th rowSpan="2" className={cl.borderNone} />}
        </tr>
        <tr>
          <th>Кол-во</th>
          <th>Адрес</th>
        </tr>
      </thead>
      <tbody>

        {/* Строки таблицы */}
        {lines.map((line, index) => (
          <ChargesTableRow
            lines={lines}
            line={line}
            index={index}
            provided={provided}
            storageChecker={storageChecker}
            filterNames={filterNames}
            onChangeName={onChangeName}
            filterArticles={filterArticles}
            groups={groups}
            onChangePos={onChangePos}
            deleteLine={deleteLine}
          />
        ))}

        {provided === 0 && (
          <tr className="adding">
            <td className="iconed">
              <span
                className="roundIcon material-icons"
                onClick={addLine}
                title="Добавить строку"
              >
                add
              </span>
            </td>
          </tr>
        )}
        <tr style={{ height: "300px" }} />
        <tr ref={scrollPlus} />
      </tbody>
    </table>
  );
};

export default FormCharges;
