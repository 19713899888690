import {Navigate} from "react-router-dom";

import MainContainerComponent from "components/layout/MainContainerComponent";

import {AuthStatus} from "constants/auth-status";
import {AppRoute} from "constants/routes";

const PrivateRoute = ({authorizationStatus, children}) => {
  return (
    authorizationStatus === AuthStatus.AUTH
      ? (
        <MainContainerComponent>
          {children}
        </MainContainerComponent>
      )
      : <Navigate to={AppRoute.Auth.SIGN_IN}/>
  );
};

export default PrivateRoute;
