import Indicator from 'components/Indicator';

import styles from './styles.module.scss';

const ListLegend = ({documentType}) => {
  return (
    <div className={styles.wrapper}>
      <div>
        <Indicator type="danger"/> - {documentType} создано
      </div>
      <div>
        <Indicator type="success"/> - {documentType} проведено в Системе
      </div>
    </div>
  );
};

export default ListLegend;
