import {useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {fetchSupplyDetailsAsyncAction} from 'redux/slices/supplies/supplies-api-actions';
import {getSupply} from 'redux/slices/supplies/selectors';

import Form from 'components/form/Form';

import {AppContext} from 'providers/AppContextProvider';

const Id = () => {
	const {id} = useParams();
	const dispatch = useDispatch();
	const {alert, setCrumbs, setMenuCondition} = useContext(AppContext);

	const form = useSelector(getSupply);
	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: 'Архивы', url: '/archive'},
			{name: 'Поступления', url: '/archive/supplies'},
			{name: '№ ' + form.document_number, url: ''}
		]);
	}, []);

	// Запрос данных с сервера
	useEffect(() => {
		dispatch(fetchSupplyDetailsAsyncAction(id));
	}, []);
	
	return (
		<>
			<Form
				alert={alert}
				setMenuCondition={setMenuCondition}
				type='Поступление'
				form={form}
			/>
		</>
	);
};

export default Id;
