import axios from "axios";
import { useContext, useEffect, useState } from "react";

import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGroups,
  fetchStores,
  fetchSupply,
} from "redux/slices/business/businessSlice";
import { fetchMapAddresses } from "redux/slices/mapSlice";

import { AppContext } from "providers/AppContextProvider";
import { loadingStatus } from "helpers/fetcher";
import { getUser } from "helpers/storage";
import { inventory, archive, faq } from "constants/routes";

import Form from "components/form/Form";
import SpinPlaceholderComponent from "components/preloader/SpinPlaceholderComponent";

const Id = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { id } = useParams();

  const dispatch = useDispatch();
  const { supply, groups, stores } = useSelector((state) => state.business);
  const { mapAddresses } = useSelector((state) => state.map);

  const { setCrumbs, setMenuCondition } = useContext(AppContext);

  const [ff, setFF] = useState({});

  useEffect(() => {
    // Запись хлебных крошек
    setCrumbs([
      { name: "Поступления", url: "/supplies" },
      { name: "№ " + supply.data.document_number, url: "" },
    ]);
    
    // Если доступ просмотровый, запрет редактирования
    if (getUser().accessLevel < 2) {
      const copy = Object.assign({}, supply.data);
      ff.done = 1;
      ff.provided = 1;
      setFF(copy);
    } else {
      setFF(supply.data);
    }

  }, [supply.data]);


  useEffect(() => {
    dispatch(fetchSupply(id));
    dispatch(fetchGroups());
    dispatch(fetchStores());
    dispatch(fetchMapAddresses());
  }, []);

  if (Object.keys(ff).length === 0) return <SpinPlaceholderComponent isActive />;

  return (
    <>
      <title>Редактировать поступление № {supply.data.document_number}</title>

      <Form
        alert={alert}
        setMenuCondition={setMenuCondition}
        groups={groups}
        type="Поступление"
        form={ff}
        storages={stores.data}
        AddressList={mapAddresses.data}
        userLevel={() => getUser().accessLevel}
      />
    </>
  );
};

// export async function getServerSideProps({params}){
// 	const apiToken = "Bearer ~ybqF7}S9G9a5@8}aIex";

// 	// Запрос формы
// 	const formResponse = await axios.get("businessRu/getSupply/" + params.id, {
// 		headers: {
// 			Authorization: apiToken
// 		}
// 	});
// 	const form = formResponse.data.result;

// 	// Запрос названий групп
// 	const groupsResponse = await axios.get("businessRu/getGroups", {
// 		headers: {
// 			Authorization: apiToken
// 		}
// 	});
// 	const groups = groupsResponse.data.result;

// 	// Запрос складов
// 	const storagesResponse = await axios.get("businessRu/getStores", {
// 		headers: {
// 			Authorization: apiToken
// 		}
// 	});
// 	const storages = storagesResponse.data.result;

// 	// Запрос всех адресов
// 	const addressListResponse = await axios.get("map/getAllAddresses", {
// 		headers: {
// 			Authorization: apiToken
// 		}
// 	});
// 	const addressList = addressListResponse.data.result;

// 	return {props: {form, groups, storages, addressList}}
// }

export default Id;
