import clsx from 'clsx';

import ArrowSVG from 'images/svg/arrow.svg';

import style from './style.module.scss';

const ArrowButton = ({ direction, handleClick }) => {
  return (
    <li className={clsx(style.wrapper)}>
      <button onClick={handleClick} className={style.button} type="button">
        <svg
          className={clsx(style.arrow, {
            [style.arrow_rotated]: direction === 'left',
          })}
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9 0.984375L17.0156 9L9 17.0156L7.59375 15.6094L13.1719 9.98438H0.984375V8.01562H13.1719L7.59375 2.39062L9 0.984375Z" fill="white"/>
        </svg>
      </button>
    </li>
  );
};

export default ArrowButton;
