import {useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import axios from 'axios';
import {toast} from 'react-toastify';

import {
  setInventoryDataAction,
  postInventoryItemsAsyncAction,
  editFile,
  cancelInventoryAsyncAction,
  editFilesBru,
  removeInventory,
  fetchInventories,
  provideInventoryAsyncAction,
  copyInventoryAsyncAction
} from 'redux/slices/inventorySlice';
import {redirectToRoute} from 'redux/actions';

import {getUser} from 'helpers/storage';
import {AppContext} from 'providers/AppContextProvider';

import cl from 'styles/pages/[fieldId].module.scss';

const FloatPanel = ({
  setIsPreloaderActive,
  setModalIsActive,
  setCheckValuesModalActive,
  setCheckValuesModalData,
  setIsFilterOpened,
  createAndPostScreenShot
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {pathname} = useLocation();
  const isInArchive = (/archive/).test(pathname);

  const {alert} = useContext(AppContext);

  const inventory = useSelector((state) => state.inventory).inventory;
  const id = inventory.id;
  const inventoryItems = inventory.data;
  const employee = inventory.employee;
  const comment = inventory.comment;
  const filename = inventory.filename;
  const allAddresses = useSelector((state) => state.inventory).addresses.data;
  const storage = inventory.storage;

  const addresses = allAddresses.filter((address) => address.storage === storage).sort((a, b) => {
    const ADDRESS_REG_EXP = /^\d+/;
    const prevValue = a.value;
    const nextValue = b.value;

    if (ADDRESS_REG_EXP.test(prevValue) && ADDRESS_REG_EXP.test(nextValue)) {
      return prevValue.match(ADDRESS_REG_EXP)[0] - nextValue.match(ADDRESS_REG_EXP)[0];
    }
    if (ADDRESS_REG_EXP.test(prevValue) && !ADDRESS_REG_EXP.test(nextValue)) {
      return -1;
    }
    if (!ADDRESS_REG_EXP.test(prevValue) && ADDRESS_REG_EXP.test(nextValue)) {
      return 1;
    }
    
    return 0;
  });

  // Проверка ввода имени бригадира
  const checkEmployeeNameInserted = () => {
    const isEmployeeNameInserted = !!employee && employee.trim() !== '';

    if (!isEmployeeNameInserted) {
      toast.error('Укажите бригадира!');
    }

    return isEmployeeNameInserted;
  };

  // Сохранение инвентаризации в БД
  const saveInventory = async () => {
    // Создание превью инвентаризации
    await createAndPostScreenShot();

    // Редактирование файла
    await print();

    // Отправка на сервер
    await dispatch(postInventoryItemsAsyncAction({
      id,
      comment,
      inventory: inventoryItems
    }));
  };

  // Обработчик кнопки сохранения инвентаризации
  const handleSaveButtonClick = () => {
    const handler = async () => {
      const isEmployeeNameInserted = checkEmployeeNameInserted();

      if (isEmployeeNameInserted) {
        setIsPreloaderActive(true);
        await saveInventory().then(() => dispatch(fetchInventories()));
        setIsPreloaderActive(false);
      }
    };

    alert("Сохранить инвентаризацию?", "default", 0, [
      {
        text: "Да",
        handler,
        needToCloseImmediately: true
      },
      {
        text: "Нет",
        handler: () => alert("", "default", 1)
      }
    ]);
  };

  // Редактирование файла инвентаризации при сохранении изменений
  const print = async () => {
    await dispatch(editFile({
      inventory: inventoryItems,
      filename
    }));
  };

  // Создание файлов для Бизнес.ру
  const printBru = async (type) => {
    const response = await dispatch(editFilesBru({
      inventory: inventoryItems
    }));

    window.open(process.env.REACT_APP_SERVER_URL + "/FILES/" + response.data.files[type]);
    // axios
    //   .post("inventory/editFilesBru", {
    //     inventory: inventoryItems,
    //   })
    //   .then((response) => {
    //     navigate(
    //       process.env.REACT_APP_SERVER_URL +
    //         "/FILES/" +
    //         response.data.files[type]
    //     ).then(() => navigate("/inventory/edit/" + inventory.id));
    //   });
  };

  // Обработчик кнопки загрузки файла инвентаризации
  const download = () => {
    const handler = async () => {
      alert("", "default", 1);
      setIsPreloaderActive(true);
      await print();
      setIsPreloaderActive(false);
      window.open(process.env.REACT_APP_SERVER_URL + "/FILES/" + inventory.filename);
      // navigate("/inventory/edit/" + id);
    };

    let btns = [
      {
        text: "Системный",
        handler
      }
    ];
    if (inventory.isCopy !== 0) {
      btns = [
        ...btns,
        {
          text: "Факт",
          handler: () => {
            alert("", "default", 1);
            setIsPreloaderActive(true);
            printBru("fact");
            setIsPreloaderActive(false);
          },
        },
        {
          text: "Списание",
          handler: () => {
            alert("", "default", 1);
            setIsPreloaderActive(true);
            printBru("charge");
            setIsPreloaderActive(false);
          },
        },
        {
          text: "Оприходование",
          handler: () => {
            alert("", "default", 1);
            setIsPreloaderActive(true);
            printBru("posting");
            setIsPreloaderActive(false);
          },
        },
      ];
    }

    alert("Вариант скачивания:", "default", 10000, btns);
  };

  // Проверка ввода данных во всех строках (адрес и кол-во)
  const validateInventoryItems = () => {
    const notFilledRows = {};

    // const itemsNames = structuredClone(inventoryItems).map((item) => item.name);
    // const filteredPlants = plants.filter((plant) => !itemsNames.includes(plant.name));
    // const plantsNames = filteredPlants.map((plant) => plant.name);
    // const plantsArticles = filteredPlants.map((plant) => plant.article);
    // const groupsNames = groups.map((group) => group.name);

    inventoryItems.forEach((invItem, index) => {
      const invalidRow = {};
      // const isItemNameInNamesList = plantsNames.includes(invItem.name);
      // const isItemArticleInArticlesList = plantsArticles.includes(invItem.article);
      // const isGroupInGroupsList = groupsNames.includes(invItem.b_group);
      const goodSelected = !!invItem.name;
      const everyRowFilledCorrectly = invItem.datas.every((dtItem) => {
        const addressSelected = dtItem.address_fact.trim() !== "";
        const isAddressFromExistingAddressesList = addressSelected ? allAddresses
          .filter((address) => address.storage === storage)
          .map((address) => address.name)
          .includes(dtItem.address_fact.trim()) : true;
        const amountInserted = String(dtItem.amount_fact).trim() !== "";
        const isAmountNumeric = amountInserted ? (/^\d+$/).test(String(dtItem.amount_fact).trim()) : true;
        const amountGreaterThanOrEqualsToZero = amountInserted && isAmountNumeric ? Number(String(dtItem.amount_fact).trim()) >= 0 : true;

        if (!goodSelected) {
          invalidRow.goodNotSelected = true;
        }
        if (!addressSelected) {
          invalidRow.addressNotSelected = true;
        }
        if (!isAddressFromExistingAddressesList) {
          invalidRow.addressNotInList = true;
        }
        if (!amountInserted) {
          invalidRow.amountNotInserted = true;
        }
        if (!isAmountNumeric) {
          invalidRow.amountNotNumeric = true;
        }
        if (!amountGreaterThanOrEqualsToZero) {
          invalidRow.negativeAmountValue = true;
        }

        const isRowFilledCorrectly = goodSelected
          && addressSelected
          && isAddressFromExistingAddressesList
          && amountInserted
          && isAmountNumeric
          && amountGreaterThanOrEqualsToZero;

          return isRowFilledCorrectly;
      });

      if (!everyRowFilledCorrectly || Object.keys(invalidRow).length) {
        const itemTableNumber = String(index + 1);
        invalidRow.item = structuredClone(invItem);
        notFilledRows[itemTableNumber] = invalidRow;
      }
    });

    return notFilledRows;
  };

  // Проведение инвентаризации
  const provideInventory = async (showSuccessMsg) => {
    await dispatch(provideInventoryAsyncAction({
      id,
      showSuccessMsg
    }));
  };

  // Обработчик кнопки проведения инвентаризации
  const handleProvideInventoryButtonClick = () => {
    const showSuccessMsg = () => {
      const SUCCESS_PROVIDE_INVENTORY_MSG = "Успешно проведено";
      const ALERT_TYPE = "success";
      const ALERT_DURATION = 2000;

      alert(SUCCESS_PROVIDE_INVENTORY_MSG, ALERT_TYPE, ALERT_DURATION);
    };

    const handler = async () => {
      const employeeNameInserted = checkEmployeeNameInserted();
      const invalidItems = validateInventoryItems();
      const allTableRowsFilledCorrectly = Object.keys(invalidItems).length === 0;

      if (employeeNameInserted && allTableRowsFilledCorrectly) {
        setIsPreloaderActive(true);

        await saveInventory();
        await provideInventory(showSuccessMsg);

        setIsPreloaderActive(false);
      }
      if (!allTableRowsFilledCorrectly) {
        setCheckValuesModalData(invalidItems);
        setCheckValuesModalActive(true)
      }
    }

    alert("Провести в Системе?", "default", 0, [
      {
        text: "Да",
        handler,
        needToCloseImmediately: true
      },
      {
        text: "Нет",
        handler: () => {
          alert("", "default", 1);
        }
      },
    ]);
  };

  // Отмена инвентаризации
  const cancelInventory = async (showSuccessMsg) => {
    setIsPreloaderActive(true);

    await dispatch(cancelInventoryAsyncAction({
      id,
      showSuccessMsg
    }));
  };

  // Обработчик кнопки отмены инвентаризации
  const handleInventoryCancelButtonClick = () => {
    const showSuccessMsg = () => {
      const SUCCESS_CANCEL_INVENTORY_MSG = "Успешно отменено";
      const ALERT_TYPE = "success";
      const ALERT_DURATION = 2000;

      alert(SUCCESS_CANCEL_INVENTORY_MSG, ALERT_TYPE, ALERT_DURATION);
    };

    const handler = async () => {
      await cancelInventory(showSuccessMsg);
      setIsPreloaderActive(false);
    };

    alert("Отменить инвентаризацию?", "danger", 0, [
      {
        text: "Да",
        handler,
        needToCloseImmediately: true
      },
      {
        text: "Нет",
        handler: () => {
          alert("", "default", 1);
        },
      },
    ]);
  };

  // Обработчик кнопки проведения инвентаризации в Бизнес.ру на плавающей панели
  const setDone = () => {
    alert("Провести в базе?", "default", 10000, [
      {
        text: "Да",
        handler: function () {
          alert("", "default", 1);
          setIsPreloaderActive(true);

          axios
            .get("inventory/provide/" + id + "?done=1")
            .then((response) => {
              alert(
                "Успешно проведено." +
                  (response.data.message.length > 0
                    ? response.data.message
                    : ""),
                "success"
              );
              setIsPreloaderActive(false);
              navigate("/inventory");
            })
            .catch(() => {
              alert("Ошибка проведения", "danger");
              setIsPreloaderActive(false);
            });
        },
      },
      {
        text: "Нет",
        handler: function () {
          alert("", "default", 1);
        },
      },
    ]);
  };

  // Обработчик кнопки перемещения в архив после ручного проведения инв в Бизнес.ру на плавающей панели
  const setDoneManual = () => {
    alert("Переместить в архив?", "default", 10000, [
      {
        text: "Да",
        handler: function () {
          alert("", "default", 1);
          setIsPreloaderActive(true);

          axios
            .get(
              "inventory/provide/" + id + "?done=1&manual=1"
            )
            .then((response) => {
              alert(
                "Успешно архивировано." +
                  (response.data.message.length > 0
                    ? response.data.message
                    : ""),
                "success"
              );
              setIsPreloaderActive(false);
              navigate("/inventory");
            })
            .catch(() => {
              alert("Ошибка архивации", "danger");
              setIsPreloaderActive(false);
            });
        },
      },
      {
        text: "Нет",
        handler: function () {
          alert("", "default", 1);
        },
      },
    ]);
  };

  const handleProvideСorrection = (writeInHistory = true) => {
    setIsPreloaderActive(true);
    axios
      .get(
        "inventory/provideCorrection/" +
          id +
          "?writeInHistory=" +
          +writeInHistory
      )
      .then((response) => {
        alert(
          "Успешно архивировано." +
            (response.data.message.length > 0 ? response.data.message : ""),
          "success"
        );
        setIsPreloaderActive(false);
        navigate("/inventory");
      })
      .catch(() => {
        alert("Ошибка архивации", "danger");
        setIsPreloaderActive(false);
      });
  };

  // Обработчик кнопки удаления инвентаризации на плавающей панели
  const remove = () => {
    const handler = async () => {
      setIsPreloaderActive(true);
      await dispatch(removeInventory(id));
      await dispatch(fetchInventories());
      dispatch(redirectToRoute('/inventory'));
    };

    alert("Удалить инвентаризацию?", "danger", 0, [
      {
        text: "Да",
        handler,
        needToCloseImmediately: true
      },
      {
        text: "Нет",
        handler: () => {
          alert("", "default", 1);
        }
      }
    ]);
  };

  // Удаление лишних строк (в которых нет адреса и кол-ва)
  const removeEmptyLines = () => {
    const copiedItems = structuredClone(inventoryItems);

    const filteredItems = copiedItems.map((item) => {
      const inventoryItemDatas = item.datas;
      const filteredItemInventoryDatas = inventoryItemDatas
        .filter((datasRow) => !!datasRow.address_fact || !!datasRow.amount_fact);

      const modifiedItem = {
        ...item,
        datas: filteredItemInventoryDatas
      };

      return modifiedItem;
    }).filter((item) => item.datas.length > 0);

    dispatch(setInventoryDataAction(filteredItems));
  };

  // Обработчик кнопки удаления лишних строк (в которых нет адреса и кол-ва)
  const handleRemoveEmptyLinesButtonClick = () => {
    const handler = () => {
      removeEmptyLines();
    };

    alert("Удалить незаполненные строки?", "danger", 0, [
      {
        text: "Да",
        handler,
        needToCloseImmediately: true
      },
      {
        text: "Нет",
        handler: () => {
          alert("", "default", 1);
        }
      }
    ]);
  };

  // Заполнение пустых строк (в которых нет адреса и кол-ва)
  const fillEmptyLines = () => {
    const copiedItems = structuredClone(inventoryItems);

    copiedItems.forEach((item, itemIndex) => {
      item.datas.forEach((row, rowIndex) => {
        if (!row.address_fact) {
          copiedItems[itemIndex].datas[rowIndex].address_fact = addresses[0].name;

          if (row.amount_fact === '') {
            copiedItems[itemIndex].datas[rowIndex].amount_fact = 0;
          }
        }
      });
    });

    dispatch(setInventoryDataAction(copiedItems));
  };

  // Обработчик кнопки заполнения пустых строк
  const handleFillEmptyLinesButtonClick = () => {
    const handler = () => {
      fillEmptyLines();
    };

    alert("Заполнить пустые строки?", "danger", 0, [
      {
        text: "Да",
        handler,
        needToCloseImmediately: true
      },
      {
        text: "Нет",
        handler: () => {
          alert("", "default", 1);
        }
      }
    ]);
  };

  // Обработчик кнопки "Назад"
  const handleBackButtonClick = () => {
    const prevLocation = isInArchive ? '/archive/inventory' : '/inventory';
    navigate(prevLocation);
  };

  // Обработчик кнопки копирования инвентаризации
  const handleCopyInventoryButtonClick = () => {
    const showSuccessMsg = () => {
      const SUCCESS_CANCEL_INVENTORY_MSG = "Инвентаризация успешно скопирована";
      const ALERT_TYPE = "success";
      const ALERT_DURATION = 2000;

      alert(SUCCESS_CANCEL_INVENTORY_MSG, ALERT_TYPE, ALERT_DURATION);
    };

    const handler = async () => {
      const isEmployeeNameInserted = checkEmployeeNameInserted();

      if (isEmployeeNameInserted) {
        setIsPreloaderActive(true);
        await saveInventory();
        await dispatch(copyInventoryAsyncAction({
          id,
          showSuccessMsg
        }));
        setIsPreloaderActive(false);
      }
    };

    alert("Скопировать инвентаризацию?", "default", 0, [
      {
        text: "Да",
        handler,
        needToCloseImmediately: true
      },
      {
        text: "Нет",
        handler: () => {
          alert("", "default", 1);
        },
      },
    ]);
  };

  return (
    <div className={cl.floatPanel + " " + cl.controlsPanel}>

      {/* Кнопка отмены инвентаризации */}
      {inventory.data.length > 0 &&
        inventory.provided === 1 &&
        inventory.isCopy > 0 &&
        getUser().accessLevel > 1 &&
        !isInArchive && (
          <span
            className="roundIcon dangerIcon material-icons"
            onClick={handleInventoryCancelButtonClick}
            title="Отменить инвентаризацию"
          >
            undo
          </span>
        )
      }

      {/* Кнопка удаления инвентаризации */}
      {inventory.data.length > 0 &&
        inventory.provided === 0 &&
        getUser().accessLevel > 1 &&
        !isInArchive && (
          <span
            className="roundIcon dangerIcon material-icons"
            onClick={remove}
            title="Удалить инвентаризацию"
          >
            delete_sweep
          </span>
        )
      }

      {/* Кнопка удаления лишних строк, где не указаны адрес и кол-во */}
      {inventory.data.length > 0 &&
        inventory.provided === 0 &&
        getUser().accessLevel > 1 &&
        !isInArchive && (
          <span
            className="roundIcon dangerIcon material-icons"
            onClick={handleRemoveEmptyLinesButtonClick}
            title="Удалить лишние строки"
          >
            rule
          </span>
        )
      }

      {/* Кнопка заполнения пустых строк */}
      {inventory.data.length > 0 &&
        inventory.provided === 0 &&
        getUser().accessLevel > 1 &&
        !isInArchive && (
          <span
            className="roundIcon dangerIcon material-icons"
            onClick={handleFillEmptyLinesButtonClick}
            title="Заполнить пустые строки"
          >
            table_rows
          </span>
        )
      }

      {/* Кнопка "Провести инвентаризацию" */}
      {inventory.data.length > 0 &&
        inventory.provided === 0 &&
        getUser().accessLevel > 1 &&
        !isInArchive && (
          <span
            className="roundIcon material-icons"
            title="Провести Инвентаризацию в Системе"
            onClick={handleProvideInventoryButtonClick}
          >
            checklist
          </span>
        )
      }

      {/* Кнопка копирования инвентаризации */}
      {inventory.data.length > 0 && 
        inventory.provided === 0 &&
        getUser().accessLevel > 1 &&
        !isInArchive && (
          <span
            className="roundIcon material-icons"
            onClick={handleCopyInventoryButtonClick}
            title="Скопировать инвентаризацию"
          >
            file_copy
          </span>
        )
      }

      {/* Кнопка сохранения инвентаризации */}
      {inventory.data.length > 0 &&
        inventory.provided === 0 &&
        getUser().accessLevel > 1 &&
        !isInArchive && (
          <span
            className="roundIcon material-icons"
            onClick={handleSaveButtonClick}
            title="Сохранить"
          >
            save
          </span>
        )
      }

      {/* Кнопка перевода инвентаризации в архив, если она уже проведена */}
      {inventory.data.length > 0 &&
        inventory.provided === 1 &&
        inventory.isCopy > 0 &&
        getUser().accessLevel > 1 &&
        !isInArchive && (
          <>
            <span
              className="roundIcon material-icons"
              title="Переместить в архив"
              onClick={setDoneManual}
            >
              archive
            </span>
            {/* <span
              className="roundIcon material-icons"
              title="Записать коррекцию"
              onClick={() =>
                alert("Записать коррекцию в историю?", "default", 10000, [
                  {
                    text: "Да",
                    handler: function () {
                      handleProvideСorrection();
                    },
                  },
                  {
                    text: "Нет",
                    handler: function () {
                      handleProvideСorrection(false);
                    },
                  },
                ])
              }
            >
              inventory
            </span> */}
            {inventory.data.length && (
              <span
                className="roundIcon material-icons"
                title="Провести Инвентаризацию в БизнесРу"
                onClick={setDone}
              >
                done
              </span>
            )}
          </>
        )
      }

      {/* Кнопка "Скачать документ" */}
      <span
        className="roundIcon material-icons"
        title="Скачать"
        onClick={download}
      >
        download
      </span>

      {/* Кнопка "Примечание" */}
      <span
        className="roundIcon material-icons"
        onClick={() => setModalIsActive(true)}
        title="Примечание"
      >
        maps_ugc
      </span>

      {/* Кнопка "Инструкция" */}
      <span
        className="roundIcon material-icons"
        onClick={() => window.open("/faq/inventory", "_blank")}
        title="Инструкция"
      >
        quiz
      </span>

      {/* Кнопка "Фильтр" */}
      {/* {
        !isInArchive && (
          <span
            className="roundIcon material-icons"
            onClick={() => setIsFilterOpened((prevState) => !prevState)}
            title="Фильтр"
          >
            filter_alt
          </span>
        )
      } */}

      {/* Кнопка "Назад" */}
      <span
        className="roundIcon material-icons"
        title="Назад"
        onClick={handleBackButtonClick}
      >
        arrow_back
      </span>
    </div>
  );
};

export default FloatPanel;
