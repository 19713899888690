import axios from "axios";

import cl from "styles/pages/signup.module.scss";
import tcl from "styles/pages/[fieldId].module.scss";
import Input from "components/input/InputComponent";
import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import Button from "components/ButtonComponent";
import html2canvas from "html2canvas";
import CustomSelect from "components/CustomSelect";
import { useNavigate } from "react-router-dom";
import { AppContext } from "providers/AppContextProvider";
import { getUser } from "helpers/storage";
import { useDispatch, useSelector } from "react-redux";
import { restrictedGroupsIds } from "constants/document-data";
import { fetchGroups } from "redux/slices/business/businessSlice";
import { fetchAddresses } from "redux/slices/inventorySlice";

const New = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const router = {
		pathname: window.location.pathname,
		push: (p) => navigate(p),
		query: { type: (new URLSearchParams(window.location.search)).get("type") },
	};

	const {alert, setCrumbs} = useContext(AppContext);

	const userLevel = getUser().accessLevel;

	const addressList = useSelector((state) => state.inventory).addresses.data;
	const groups = useSelector((state) => state.business).groups.data
    .filter((group) => !restrictedGroupsIds.includes(Number(group.id)));
	
	// Параметры документа
	const [documentNumber, setDocumentNumber] = useState('');
	const [documentDate, setDocumentDate] = useState("");
	const date = new Date();
	
	// Контейнер для сообщения внизу формы
	const [formMessage, setFormMessage] = useState("");
	
	// Состояние формы
	const [form, setForm] = useState({
		file: ""
	});
	
	// Флаг отправки файла
	const [isSended, setIsSended] = useState(false);
	
	// Массивы данных с сервера
	const [head, setHead] = useState([]);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	
	// Классы таблицы
	const tableClasses = [tcl.mainTable, tcl.top0, "table", "table-responsive"];
	
	const toScreenShot = useRef();
	
	// Асинхронные данные из Бизнес.ру
	const [plantsNames, setPlantsNames] = useState([]);
	const [plantsArticles, setPlantsArticles] = useState([]);
	
	// Поле сортировки и направление
	const [sorted, setSorted] = useState("name");
	const [sortDir, setSortDir] = useState(true);
	
	// Перенаправление на главную, если нет доступа
	useEffect(() => {
		if(userLevel < 2) router.push("/");
		
		setDocumentDate(String(date.getDate()).padStart(2, "0") + "." + String(date.getMonth() + 1).padStart(2, "0") + "." + date.getFullYear() + " " + String(date.getHours()).padStart(2, "0") + ":" + String(date.getMinutes()).padStart(2, "0"));
		
		// Запрос наименований растений
		axios.get("businessRu/getGoods").then(response => {
			setPlantsNames(response.data.result.goods.sort((a, b) => {
				if(a.name === b.name) return 0;
				else return a.name > b.name ? 1 : -1;
			}));
			setPlantsArticles(response.data.result.articles);
		});
	}, []);
	
	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: "Отгрузки", url: "/realizations"},
			{name: "Новый документ", url: ""}
		]);
	}, []);
	
	// Подсчет общего кол-ва
	useEffect(() => {
		let totalAll = 0;
		data.forEach(dt => {
			dt.userPositions.forEach(up => {
				if(!isNaN(parseInt(up.amount))) totalAll += parseInt(up.amount);
			});
		});
		setTotal(totalAll);
	}, [data]);
	
	// Метод изменения данных в форме
	const changeHandler = (name, data) => {
		const f = structuredClone(form);
		f[name] = data;
		setForm(f);
	}
	
	// Отправка файла на сервер
	const sendFile = () => {
		if(form.file === ""){
			alert("Выберите файл", "danger");
			return false;
		}
		
		if(isSended) return false;
		setIsSended(true);
		
		// Сборка FormData
		const formData = new FormData();
		formData.append("file", form.file);
		
		axios.post("realizations/getRealizationAmounts", formData, {
			headers: {
				"Content-type": "multipart/form-data",
			}
		}).then(response => {
			setHead(response.data.head);
			if(response.data.result.length > 0) setData(response.data.result);
		}).catch(error => {
			if(error.data.message) setFormMessage(error.data.message);
			else alert("Ошибка", "danger");
			setIsSended(false);
		});
	}
	
	// Метод для отправки скриншота
	const screenShot = async(id) => {
		return true;
		const canvas = await html2canvas(toScreenShot.current, {useCORS: true}).then(canv => {
			const image = canv.toDataURL("image/png", 1.0);
			axios.post("realizations/editRealizationThumb/" + id, {thumb: image});
		});
	}
	
	// Сохранение данных
	const saveRealization = () => {
		// Валидация
		let formError = false;
		let errorNotified = false;
		const copy = structuredClone(data);
		copy.forEach((c, i) => {
			let error = false;
			
			// Проверка номера документа
			if(documentNumber === ""){
				formError = true;
				errorNotified = true;
				alert("Введите номер документа", "danger");
			}
			
			// Проверка бригадира
			if(!data[0].employee || data[0].employee === ""){
				formError = true;
				errorNotified = true;
				alert("Введите имя бригадира", "danger");
			}
			
			if(error){
				data[i].isError = true;
				setTimeout(() => {
					data[i].isError = false;
				}, 5000);
			}
		});
		
		if(formError) return false;
		
		// Сохранение документа на отгрузку
		axios.post("realizations/saveRealizationToFile", {
			realization: copy,
			head: head,
			documentNumber: documentNumber,
			documentDate: documentDate,
			partner: form.partner
		}).then(response => {
			// Создание превью отгрузки, затем скачивание и переход к общему списку
			screenShot(response.data.id).then(() => {
				alert("Успешно сохранено", "success");
				setData([]);
				router.push("/realizations/edit/" + response.data.id);
			});
		}).catch(error => {
			if(error.response.status === 500) alert("Ошибка сохранения", "danger");
			else alert(error.response.data.message, "danger");
		});
	}
	
	// Метод изменения наименования
	const changeName = (index, name, value) => {
		if(name === "amounts" && data[index].name === "") return;
		
		const copy = structuredClone(data);
		
		// Проверка дублирующей позиции
		let error = false;
		copy.forEach(cp => {
			if(cp[name] === value) error = true;
		});
		
		// Вывод ошибки, если это не группа
		if(error && name !== "b_group"){
			alert("Такой товар уже есть в списке!", "danger");
			copy.splice(index, 1);
		} else copy[index][name] = value;
		
		// Особый флаг для услуг
		if(name === "b_group" && value === "Услуги"){
			copy[index].isUsl = true;
			copy[index].userPositions[0].amount = 1;
		}
		
		setData(copy);
	}
	
	// Изменение позиций
	const onChangePos = (index, name, value) => {
		const copy = structuredClone(data);
		
		// Изменение остатков на полях в таблице
		if(!isNaN(parseInt(copy[index.index].amounts[index.ind]["amount"])) && name === "amount"){
			if(isNaN(parseInt(copy[index.index].amounts[index.ind]["amount"]))) copy[index.index].amounts[index.ind]["amount"] = 0;
			if(!isNaN(parseInt(copy[index.index].userPositions[index.ind]["amount"])))
				copy[index.index].amounts[index.ind]["amount"] = parseInt(copy[index.index].amounts[index.ind]["amount"]) + parseInt(copy[index.index].userPositions[index.ind]["amount"]);
			if(!isNaN(parseInt(value)))
				copy[index.index].amounts[index.ind]["amount"] = parseInt(copy[index.index].amounts[index.ind]["amount"]) - parseInt(value);
		}
		
		// Изменение стоимости услуги или просто позиции
		if((copy[index.index].isUsl === true && name === "address") || name === "address_amount")
			copy[index.index].amounts[index.ind].address = value;
		else copy[index.index].userPositions[index.ind][name] = value;
		
		setData(copy);
	}
	
	// Запись бригадира
	const setEmployee = (name, value) => {
		const copy = structuredClone(data);
		copy[0].employee = value;
		setData(copy);
	}
	
	// Скролл вниз при раскрытии списка с растениями внизу страницы
	const scrollPlus = useRef(null);
	const scrollCustomSelect = (index) => {
		if(data.length - 1 - index < 4)
			scrollPlus.current.scrollIntoView({behavior: "smooth"});
	}
	
	// Новая строка
	const addLine = () => {
		const copy = structuredClone(data);
		copy.push({
			name: "",
			totalAmount: 0,
			amounts: [{
				amount: "",
				address: ""
			}],
			userPositions: [{
				amount: ""
			}]
		});
		setData(copy);
		setTimeout(() => {
			scrollCustomSelect(copy.length - 1);
		}, 200);
	}
	
	// Удаление строки
	const deleteLine = (index) => {
		alert("Удалить строку?", "danger", 10000, [
			{
				text: "Да",
				handler: function(){
					const copy = structuredClone(data);
					copy[index.index].amounts.splice(index.i, 1);
					copy[index.index].userPositions.splice(index.i, 1);
					if(!copy[index.index].amounts.length) copy.splice(index.index, 1);
					setData(copy);
					alert("Строка удалена", "success");
				}
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "default", 1);
				}
			}
		]);
	}
	
	// Функция сортировки растений
	const sortPlants = (name) => {
		setSortDir(!sortDir);
		
		const pl = structuredClone(data);
		
		pl.sort((a, b) => {
			if(a[name] === b[name]) return 0;
			else if(!sortDir === true) return a[name] > b[name] ? 1 : -1;
			else return a[name] < b[name] ? 1 : -1;
		});
		
		setData(pl);
		setSorted(name);
	}
	
	// Фильтрация наименований в зависимости от группы
	const filterNames = (groupId = 0) => {
		if(groupId === 0) return plantsNames;
		
		const filtered = plantsNames.filter(name => {
			return name.b_group_id === groupId;
		});
		return filtered.sort((a, b) => {
			if(a.name === b.name) return 0;
			else return a.name > b.name ? 1 : -1;
		});
	}
	
	// Фильтрация артикулов в зависимости от группы
	const filterArticles = (groupId = 0) => {
		if(groupId === 0) return plantsArticles;
		
		return plantsArticles.filter(article => {
			return article.b_group_id === groupId;
		});
	}

	useEffect(() => {
		if (!groups.length) {
      dispatch(fetchGroups());
    }
		if (!addressList.length) {
			dispatch(fetchAddresses());
		}
	}, []);
	
	return (
		<>
			<div className={tcl.floatPanel + " " + tcl.controlsPanel} style={data.length === 0 ? {display: "none"} : {}}>
				<Button type="accent" onClick={() => alert("Сохранить?", "default", 10000, [
					{
						text: "Да",
						handler: function(){
							saveRealization();
						}
					},
					{
						text: "Нет",
						handler: function(){
							alert("", "default", 1);
						}
					}
				])}>Сохранить</Button>
				<span className="roundIcon material-icons" onClick={() => window.open("/faq/realization", "_blank")}
						title="Инструкция">
					quiz
				</span>
			</div>
			
			<div className={cl.main + " container"} style={data.length > 0 ? {display: "none"} : {}}>
				<form className={cl.form + " " + cl.withoutLabel}>
					<Input type="file" name="file" value={form.file} setValue={changeHandler}/>
					
					{
						formMessage.length > 1 &&
						<div><p>{formMessage}</p></div>
					}
					
					<div className="row">
						<div className="col-sm-12">
							<Button type="accent" onClick={sendFile} inProcess={isSended}>Отправить файл</Button>
						</div>
					</div>
				</form>
			</div>
			
			<div className="stickyContainer" ref={toScreenShot}>
				<p style={{margin: ".3rem 0 0 .5rem"}}>{head.number}</p>
				<p style={{margin: ".3rem 0 .3rem .5rem"}}>{head.customer}</p>
				
				<table className={tableClasses.join(" ")} style={data.length === 0 ? {display: "none"} : {}}>
					<thead className={tcl.top0 + " theadBordered thead-dark"}>
						<tr>
							<th colSpan="2" className={tcl.borderNone}>
								Отгрузка (реализация) №&nbsp;
								<Input type="text" name="documentNumber" autocomplete="off" placeholder="№"
										 value={documentNumber} setValue={(name, value) => setDocumentNumber(value)}/>
							</th>
							<th className={tcl.borderNone}>
								от {documentDate}
							</th>
							<th>
								Бригадир:&nbsp;
								<Input type="text" name="employee" value={data.length > 0 ? data[0].employee : ""}
										 setValue={setEmployee} placeholder="Бригадир"
										 title={data.length > 0 ? data[0].employee : ""}/>
							</th>
							<th colSpan="3">Текущие остатки в Системе:</th>
							<th rowSpan="3">Кол-во<br/>по заказу</th>
							<th colSpan="2">Переместить на Основной склад</th>
							<th rowSpan="3" className={tcl.borderNone}/>
						</tr>
						<tr>
							<th rowSpan="2">№</th>
							<th rowSpan="2" className={sorted === "name" ? "sort sorted" : "sort"}
								 onClick={() => sortPlants("name")}>
								<span>Название</span>
							</th>
							<th rowSpan="2" className={sorted === "article" ? "sort sorted" : "sort"}
								 onClick={() => sortPlants("article")}>
								<span>Артикул</span>
							</th>
							<th rowSpan="2">Группа</th>
							<th rowSpan="2">Всего:</th>
							<th colSpan="2">В том числе:</th>
							<th rowSpan="2">Кол-во</th>
							<th rowSpan="2">Со склада</th>
						</tr>
						<tr>
							<th>Кол-во</th>
							<th>Адрес</th>
						</tr>
					</thead>
					<tbody>
						{data.length > 0 && data.map((line, index) =>
							<Fragment key={index.toString()}>
								{line.amounts.map((ln, i) =>
									<tr key={i.toString()} className={line.isError ? tcl.error : ""}>
										{i === 0 &&
											<>
												<td rowSpan={line.amounts.length}>{index + 1}</td>
												<td rowSpan={line.amounts.length} onClick={() => scrollCustomSelect(index)}>
													{line.name === ""
														?
														<CustomSelect options={filterNames(line.b_group_id)} currentValue={line.name}
																		  currentIndex={index} name="name" data={data} groups={groups}
																		  changeHandler={changeName} placeholder="Наименование"/>
														: line.name
													}
												</td>
												<td rowSpan={line.amounts.length} onClick={() => scrollCustomSelect(index)}>
													{line.name === ""
														? <CustomSelect options={filterArticles(line.b_group_id)}
																			 currentValue={line.article} currentIndex={index}
																			 name="article" data={data} groups={groups}
																			 changeHandler={changeName} placeholder="Артикул"/>
														: line.article
													}
												</td>
												<td rowSpan={line.amounts.length}>
													{line.name === ""
														? <CustomSelect options={groups} currentValue={line.b_group}
																			 currentIndex={index} name="b_group" data={data}
																			 groups={groups} changeHandler={changeName}
																			 placeholder="Группа"/>
														: line.b_group
													}
												</td>
												<td rowSpan={line.amounts.length} className={tcl.right}>
													{!line.isUsl && new Intl.NumberFormat("ru-RU").format(line.totalAmount)}
												</td>
											</>
										}
										
										<td className={tcl.right}>
											{!line.isUsl && new Intl.NumberFormat("ru-RU").format(ln.amount)}
										</td>
										<td>{!line.isUsl && ln.address}</td>
										
										{i === 0 &&
											<td rowSpan={line.amounts.length} className={tcl.right}>
												{line.orderAmount ? new Intl.NumberFormat("ru-RU").format(line.orderAmount) : 0}
											</td>
										}
										
										<td>
											{line.isUsl !== true
												? <Input type="number" name="amount" placeholder="Кол-во"
															value={line.userPositions[i].amount}
															setValue={onChangePos} currentIndex={{index: index, ind: i}}/>
												: line.userPositions[i].amount
											}
										</td>
										
										<td>
											{line.isUsl !== true &&
												<CustomSelect options={addressList} currentValue={ln.address}
																  currentIndex={{index: index, ind: i}} name="address_amount"
																  changeHandler={onChangePos} placeholder="Адрес"/>
											}
										</td>
										
										<td className="iconed">
											<span className="roundIcon material-icons"
													onClick={() => deleteLine({index: index, i: i})}
													title="Удалить строку">delete_outline</span>
										</td>
									</tr>
								)}
							</Fragment>
						)}
						<tr className="adding" ref={scrollPlus}>
							<td className="iconed">
								<span className="roundIcon material-icons" onClick={addLine}
										title="Добавить наименование">add</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</>
	);
};

export default New;
